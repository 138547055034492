import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, removeStorageData } from "framework/src/Utilities";
import { parseErrors } from "../../couponcodegenerator/src/FlatDiscountController.web";

type HttpBody = {
  voucher_code?: string;
  redeem_code?: string;
  discount_code?: string;
  coupon_code_id?: number;
};
interface ResponseDataAttributes {
  id: string;
  currency: string;
  amount: number;
}
interface RazorpayResponse {
  razorpay_payment_id: string;
  razorpay_order_id: string;
  razorpay_signature: string;
}
interface ResponseJson {
  response_data?: {
    attributes: ResponseDataAttributes;
  };
  description: string;
  full_name: string;
  error: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  order_id: string;
  voucher?: string;
  code?: string;
  coupon_code_id?: number;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  isPaymentSuccess: boolean;
  isPaymentFailed: boolean; 
  voucher: string;
  voucher_code: string;
  orderError: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfRazorpayIntegration64Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  placeOrderData: string = "";
  paymentCaptureApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: '',
      isPaymentSuccess: false,
      isPaymentFailed: false,
      voucher: "",
      voucher_code: "",
      orderError: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors) {
        switch (apiRequestCallId) {
          case this.placeOrderData:
            this.handlePaymentResponse(responseJson)
            break;
          case this.paymentCaptureApiCallId:
            this.handleCapturePaymentDetailsResponse(responseJson)
            break;
        }
      } else {
        parseErrors(
          responseJson,
          this.parseApiErrorResponse,
          this.send,
          this.props
        );
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.integrity = "sha384-oqVbBz8twQOvDo3BGhIrjN8soJ6dM3kN6VVDf8P6W6f5Nq6U4M7qfXU5Jow7K0G4";
    script.crossOrigin = "anonymous";
    script.async = true;
    document.body.appendChild(script);
    const token = await getStorageData('loginToken');
    this.setState({ 
      token,
      voucher: this.props.voucher || "", 
      voucher_code: this.props.code || ""
    });
  }
   componentDidUpdate(prevProps: Readonly<Props>): void {
    if(this.props.voucher !== prevProps.voucher) {
      this.setState({voucher: this.props.voucher || "", voucher_code: this.props.code || ""})
    }
   }
  
  getOrderDatials = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    let httpBody :HttpBody = {};
    if(this.state.voucher == 'Cash'){
      httpBody.redeem_code=this.state.voucher_code
    }else if(this.state.voucher == 'Coupon') {
      httpBody.coupon_code_id = this.props.coupon_code_id
    }
    
    const cartList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.placeOrderData = cartList.messageId;
    cartList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.URLforRazorPay
    )
    cartList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    cartList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.placeOrderEndPointMethod
    )

    cartList.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(cartList.id, cartList);
  }

  handlePaymentResponse = (responseJson: ResponseJson) => {
    if (responseJson.response_data) {
      const { id, currency, amount } = responseJson.response_data.attributes;
      const { description, full_name } = responseJson; 
      const redeemcode = this.state.voucher_code
   
      this.rozarpayPayment(id, currency, description, amount, full_name ,redeemcode);
    } else if (responseJson.error) {
      this.setState({ isPaymentFailed: true, orderError: responseJson.error })
    }
  }

  handlePaymentFailure = () => {
    this.setState({ isPaymentFailed: true })
  };
  rozarpayPayment = (order_id: string, currency: string, desc: string, amount: any, name: string , redeemcode: string) => {
    let options = {
      description: desc,
      currency: currency,
      key: 'rzp_test_JOC0wRKpLH1cVW',
      amount: amount,
      name: name,
      order_id: order_id,
      redeemcode:redeemcode,
      handler: (response: RazorpayResponse) => {
        if (response.razorpay_payment_id) {
          this.capturePaymentDetails(
            amount,
            response.razorpay_payment_id,
            response.razorpay_order_id,
            response.razorpay_signature,
            this.props.order_id,
            redeemcode
          )
        } else {
          this.handlePaymentFailure();
        }
      },
    }
    const rzp1 = new (window as any).Razorpay(options);
    rzp1.open()
  };

  capturePaymentDetails = (
    amount: string,
    razorpay_payment_id: string,
    razorpay_order_id: string,
    razorpay_signature: string,
    cart_id: string,
    redeemcode: string
  ) => {
    const {voucher}=this.state
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    const redeem_code = voucher == 'Cash' && redeemcode
    const httpBody = {
      amount,
      razorpay_payment_id,
      razorpay_order_id,
      razorpay_signature,
      cart_id,
      redeem_code
    }
    const cartList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.paymentCaptureApiCallId = cartList.messageId;
    cartList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.capturePaymentApiEndpoint
    )
    cartList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    cartList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.placeOrderEndPointMethod
    )

    cartList.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(cartList.id, cartList);
  }

  handleCapturePaymentDetailsResponse = async(responseJson: {
    message: string;
  }) => {
    if (responseJson.message === "Payment successed") {
      await removeStorageData("cartCount");
      await removeStorageData("coupon_code");
      await removeStorageData("discount_type");
      this.setState({ isPaymentSuccess: true })
    } else {
      this.setState({ isPaymentFailed: true })
    }
  }
  handleNavigateToCourses = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "MyCourses");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleNavigateToHome = (route: string) => {
    this.setState({ isPaymentFailed: false, orderError: "" })
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  // Customizable Area End
}
