import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  styled,
  Button,
  Modal,
  Snackbar
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { IoIosArrowDown } from "react-icons/io";
import { Pagination } from "@material-ui/lab";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { PiPen } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";
import MainLayout from "../../../components/src/MainLayout.web";
import {
  TableBody,
  TableStyle
} from "../../educational-user-profile/src/EducationalUserProfile.web";

const drawerWidth = 250;
const closeDrawer = 0;
// Customizable Area End

import AdminChildCatgoryController, {
  ChildCategoryDataType,
  Props
} from "./AdminChildCategoryController.web";

export default class AdminChildCatgory extends AdminChildCatgoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTableBody = (item: ChildCategoryDataType, index: number) => {
    return (
      <>
        <tr>
          <td style={{ borderRadius: "10px 0px 0px 10px" }}>
            <Box className="desc_checkbox">
              <input
                data-test-id={"rowCheckboxTestId"}
                type="checkbox"
                checked={this.state.isChecked[index]}
                onChange={() => this.handleChildCatRowCheckboxChange(index)}
                style={drawerStyle.input}
              />
            </Box>
          </td>
          <td className="tr_desc">
            {item.id}
          </td>
          <td style={{ textAlign: "center" }}>
            <img
              src={item.attributes.mobile_icon}
              alt="img"
              className="desc_img"
            />
          </td>
          <td style={{ textAlign: "center" }}>
            <img
              src={item.attributes.web_icon}
              alt="img2"
              className="desc_img"
            />
          </td>
          <td style={{ textAlign: "left" }}>{item.attributes.name}</td>
          <td style={{ textAlign: "left" }}>
            {item.attributes.course_sub_category.name}
          </td>
          <td style={{ textAlign: "left" }}>
            {item.attributes.course_category.name}
          </td>
          <td style={{ textAlign: "center" }}>
            {item.attributes.total_courses}
          </td>
          <td style={{ textAlign: "center" }}>
            {item.attributes.student_count || 0}
          </td>
          <td style={{ textAlign: "center" }}>
            <StatusCheckBtn className="switch">
              <input
                type="checkbox"
                data-test-id="statusTestId"
                onClick={() =>
                  this.handleToggleStatus(
                    item.id,
                    item.attributes.status,
                    index
                  )
                }
                checked={item.attributes.status}
              />
              <span className="slider round" />
            </StatusCheckBtn>
          </td>
          <td
            style={{ borderRadius: "0px 10px 10px 0px", textAlign: "center" }}
          >
            <Box className="desc_edit_icon_box">
              <PiPen
                data-test-id={"editIconTestId"}
                className="desc_edit_icon"
                onClick={() => this.handleEditPropsChildCategory(item.id)}
                size={20}
                color="black"
              />
              <FaRegTrashAlt
                data-test-id={"deleteIconeTestId"}
                className="desc_edit_icon"
                onClick={() => this.handleOpenDeleteChildCategoryModal(item.id)}
                size={20}
                color="black"
              />
            </Box>
          </td>
        </tr>
        <Box className="table_divider" style={drawerStyle.drawerTableDivider} />
      </>
    );
  };

  renderTable = () => {
    const isCurrentSort = (name: string) =>
      this.state.currentSortState.field === name &&
      this.state.currentSortState.order === "asc";
    return (
      <Box>
        <TableStyle>
          <thead>
            <tr>
              <th>
                <Box
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center"
                  }}
                >
                  <input
                    type="checkbox"
                    data-test-id="headerCheckboxTestId"
                    style={drawerStyle.input}
                    checked={this.state.isHeaderChecked}
                    onChange={this.handleChildCatHeaderCheckboxChange}
                  />
                </Box>
              </th>
              {[
                "S.No",
                "App Icon",
                "Web Icon",
                "Child Category",
                "Sub Category",
                "Main Category",
                "Total Courses",
                "Total Students",
                "Status",
                "Action"
              ].map((header, index) => {
                let renderIcon = false;
                if (["S.No", "Child Category", "Status"].includes(header)) {
                  renderIcon = true;
                }
                return (
                  <th key={index.toString()}>
                    <Box
                      className="th_box"
                      style={
                        ["Child Category", "Sub Category", "Main Category"].includes(header)
                        ? {}
                        : { justifyContent: "center" }
                      }
                    >
                      <Typography className="title_th">{header}</Typography>
                      {renderIcon && (
                        <IoIosArrowDown
                          className={`filter_icon ${isCurrentSort(header) ? "active" : ""}`}
                          data-test-id="headerSortIconeTestId"
                          onClick={() => this.handleChildCatSorting(header)}
                          style={{
                            transform: isCurrentSort(header)
                              ? "none"
                              : "rotate(180deg)"
                          }}
                        />
                      )}
                    </Box>
                  </th>
                );
              })}
            </tr>
          </thead>
          <span style={drawerStyle.drawerDividerLine} />
          <TableBody>
            {this.state.adminChildCategoryData &&
              this.state.adminChildCategoryData.data &&
              this.state.adminChildCategoryData.data.map((item, index) =>
                this.renderTableBody(item, index)
              )}
          </TableBody>
        </TableStyle>
        <ChildCatPaginationBox
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "20px"
          }}
        >
          <Pagination
            data-test-id="pageChangebutton"
            count={this.state.pageCount}
            shape="rounded"
            boundaryCount={1}
            page={this.state.currentPageCount}
            siblingCount={0}
            onChange={this.onPageChangeChildCategory}
          />
        </ChildCatPaginationBox>
      </Box>
    );
  };

  renderDeleteChildCategoryModal = () => {
    return (
      <>
        <ChildCatDeleteModal
          style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
          data-test-id="ChildCatDeleteModalId"
          open={this.state.openDeleteChildCategoryModal}
          onClose={this.handleCloseDeleteChildCategoryModal}
        >
          <ModalView>
            <DeleteText component={"p"}>
              Do you want to delete this child category?
            </DeleteText>
            <ModalButton>
              <DeleteButton
                data-test-id="deleteBtnTestId"
                onClick={() =>
                  this.handleDeleteChildCategory(this.state.deleteChildCatId)
                }
              >
                Delete
              </DeleteButton>
              <CancelButton
                onClick={() => this.handleCloseDeleteChildCategoryModal()}
              >
                Cancel
              </CancelButton>
            </ModalButton>
          </ModalView>
        </ChildCatDeleteModal>
      </>
    );
  };

  notificationAlertMessage = () => {
    return (
      <Snackbar
        autoHideDuration={3000}
        open={this.state.isAlert}
        onClose={this.oncloseAlert}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
      </Snackbar>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainLayout
        isLoading={this.state.loader}
        handleSearch={(e: any) => this.handleChildCategorySearch(e)}
        title="Child Category"
        data-test-id="mainLayoutEvent"
        actionButtons={[
          {
            icon: <AddCircleOutlineIcon />,
            iconType: "jsxElement",
            onClick: () => this.handleAddChildCategoryNavigation(),
            text: "Add Child Category",
            testId: "addChildCatButtonId"
          }
        ]}
      >
        {this.notificationAlertMessage()}
        {this.renderDeleteChildCategoryModal()}
        <main className="table_content">{this.renderTable()}</main>
      </MainLayout>

      // Customizable Area End
    );
  }
}

// Customizable Area Start

const drawerStyle = {
  hideChildCatDrawer: { width: `calc(100% - ${closeDrawer}px)` },
  drawerTableDivider: { height: "8px" },
  showChildCatDrawer: { width: `calc(100% - ${drawerWidth}px)` },
  input: {
    height: "20px",
    width: "20px",
    border: "3px solid black",
    display: "flex",
    background: "none",
    justifyContent: "center",
    alignItems: "center"
  },
  drawerDividerLine: {
    display: "inline-block",
    height: "20px"
  }
};

const ChildCatPaginationBox = styled(Box)({
  "& .MuiPaginationItem-page.Mui-selected": {
    color: "#fff",
    backgroundColor: "#206575"
  },
  "& .viewall_txt": {
    fontSize: "14px",
    color: "#206575",
    cursor: "pointer"
  }
});

const StatusCheckBtn = styled("label")({
  "& .slider": {
    cursor: "pointer",
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: "-1px",
    WebkitTransition: ".4s",
    backgroundColor: "#ccc",
    borderRadius: "20px",
    transition: ".4s"
  },
  "&.switch": {
    display: "inline-block",
    position: "relative",
    width: "36px",
    marginTop: "5px",
    height: "19px",
    "& input": {
      width: 0,
      opacity: 0,
      height: 0
    }
  },
  "& input:checked + .slider": { backgroundColor: "#708328" },
  "& .slider:before": {
    content: '""',
    position: "absolute",
    height: "18px",
    left: "1px",
    width: "18px",
    bottom: "1px",
    WebkitTransition: ".4s",
    backgroundColor: "white",
    borderRadius: "50%",
    transition: ".4s"
  },
  "& input:checked + .slider:before": { left: "-10px" }
});

const ChildCatDeleteModal = styled(Modal)({
  height: "100%",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

const ModalView = styled(Box)({
  height: "120px",
  width: "340px",
  borderRadius: "5px",
  padding: "10px 50px",
  outline: "none",
  backgroundColor: "#ffffff",
  display: "flex",
  margin: "20px",
  boxShadow: "0px 0px 3px px transparent",
  flexDirection: "column"
});

const ModalButton = styled(Box)({
  justifyContent: "space-between",
  display: "flex"
});

const CancelButton = styled("button")({
  height: "40px",
  width: "145px",
  color: "#206575",
  border: "1px solid #206575",
  fontSize: "16px",
  backgroundColor: "white"
});

const DeleteButton = styled("button")({
  height: "40px",
  width: "145px",
  border: "0",
  backgroundColor: "#FD7101",
  fontSize: "16px",
  color: "white",
  fontWeight: 500
});

const DeleteText = styled(Box)({
  height: "37px",
  width: "100%",
  fontSize: "16px",
  fontWeight: 600
});
// Customizable Area End
