import React, { ChangeEvent } from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    styled,
    Button,
    Modal,
    Snackbar,
    TextField,
    Select,
    MenuItem,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { IoIosArrowDown } from "react-icons/io";
import { Pagination } from "@material-ui/lab";
import { RxCross2 } from "react-icons/rx";
import { FaChevronDown } from "react-icons/fa";
import { PiPen } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
const drawerWidth = 250;
const closeDrawer = 0;
import {
  TableBody,
  TableStyle,
} from "../../educational-user-profile/src/EducationalUserProfile.web";
import MainLayout from "../../../components/src/MainLayout.web";
// Customizable Area End

import AdminReviewsAndRatingsController, {
    Props, ReviewsAndRatingsDataType,
} from "./AdminReviewsAndRatingsController.web";
import { renderTableRecord } from "../../user-profile-basic/src/AdminDistrictList.web";
import { CourseChapterheckBodButton } from "./ContentManagement.web";

export default class AdminReviewsAndRatings extends AdminReviewsAndRatingsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderStars = (count: number) => {
        const stars = [];
        for (let i = 0; i < count; i++) {
            stars.push(<span key={i}>&#9733;</span>);
        }
        return stars;
    }

    renderTableBodyReviewsAndRatings = (item: ReviewsAndRatingsDataType, index: number) => {
        return (
            <>
                <tr>
                    <td>
                        <Box className="desc_checkbox">
                            <input
                                data-test-id={"rowCheckboxTestId"}
                                type="checkbox"
                                style={ReviewsAndRatingsDrawerStyle.input}
                                checked={this.state.isChecked[index]}
                                onChange={() => this.handleReviewRowCheckboxChange(index)}
                            />
                        </Box>
                    </td>
                    <td className="tr_desc">{item.id}</td>
                    <td>{item.attributes.user_name.full_name?.length > 40 ? `${item.attributes.user_name.full_name.slice(0, 40)}...` : item.attributes.user_name.full_name || "----"}</td>
                    <td>{item.attributes.rating ? this.renderStars(item.attributes.rating) : '----'}</td>
                    <td>{item.attributes.content?.length > 50 ? `${item.attributes.content.slice(0, 50)}...` : item.attributes.content || "----"}</td>
                    <td>
                        <ReviewsAndRatingsCheckStatusBtn className="switch">
                            <input data-test-id={'statusCheckTestId'} type="checkbox" onChange={() => this.handleReviewStatus(item.id,item.attributes.user_name.id)} checked={item.attributes.status} />
                            <span className="slider round"></span>
                        </ReviewsAndRatingsCheckStatusBtn>
                    </td>
                    <td>
                        <ReviewsAndRatingsCheckStatusBtn className="switch">
                            <input data-test-id={'visibilityCheckTestId'} type="checkbox" onChange={() => this.handleReviewVisibility(item.id,item.attributes.user_name.id)} checked={item.attributes.visible_on_landing} />
                            <span className="slider round"></span>
                        </ReviewsAndRatingsCheckStatusBtn>
                    </td>
                    <td>
                        <Box className="desc_edit_icon_box">
                            <PiPen
                                data-test-id={'editIconTestId'}
                                className="desc_edit_icon"
                                color={"black"}
                                size={20}
                                onClick={() => this.handleShowReview(item.id)}
                            />
                            <FaRegTrashAlt
                                data-test-id={'deleteIconeTestId'}
                                className="desc_edit_icon"
                                color={"black"}
                                size={17}
                                onClick={() => this.handleOpenDeleteReviewsAndRatingsModal(item.id)}
                            />
                        </Box>
                    </td>
                </tr>
                <Box className="table_divider" style={ReviewsAndRatingsDrawerStyle.drawerTableDivider} />
            </>
        )
    }

    getSortTestId = (header: string) => {
        const headerMap: { [key: string]: string } = {
        Name: "sort_name",
        "S.No": "sort_id",
        Status: "sort_status",
        };

        return headerMap[header] || "sort";
    };

    renderTableReviewsAndRatings = () => {
        const isCurrentSort = (name: string) =>
            this.state.currentSortState.field === name &&
            this.state.currentSortState.order === "asc";
        return (
            <Box>
                <TableStyle>
                    <thead>
                        <tr>
                            <th>
                                <Box
                                    style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                    }}
                                >
                                    <input
                                        type="checkbox"
                                        data-test-id="headerCheckboxTestId"
                                        style={ReviewsAndRatingsDrawerStyle.input}
                                        onChange={this.handleReviewHeaderCheckboxChange}
                                        checked={this.state.isHeaderChecked}
                                    />
                                </Box>
                            </th>
                            {["S.No","Name", "Ratings", "Reviews", "Status", "Visibility", "Action"].map((header, index) => {
                                let renderIcon = false;
                                if (["S.No", "Name", "Status"].includes(header)) {
                                    renderIcon = true;
                                }
                                return renderTableRecord(
                                    () => isCurrentSort(header),
                                    header,
                                    renderIcon,
                                    index,
                                    () => this.handleSort(header),
                                    this.getSortTestId(header),
                                    "center"
                                );
                            })
                            }
                        </tr>
                    </thead>
                    <span style={ReviewsAndRatingsDrawerStyle.drawerDividerLine}></span>
                    <TableBody>
                        {this.state.adminReviewsAndRatingsData?.data?.map((item: ReviewsAndRatingsDataType, index: number) => this.renderTableBodyReviewsAndRatings(item, index))}
                    </TableBody>
                </TableStyle>
                <ReviewsAndRatingsPaginationBox
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginTop: "20px",
                    }}
                >
                    <Pagination
                        data-test-id="pageChangebutton"
                        count={this.state.pageCount}
                        shape="rounded"
                        boundaryCount={1}
                        page={this.state.currentPageCount}
                        onChange={this.onReviewsAndRatingsPageChange}
                        siblingCount={0}
                    />
                </ReviewsAndRatingsPaginationBox>
            </Box>
        )
    }

    renderDeleteReviewsAndRatingsModal = () => {
        return (
            <>
                <ReviewsAndRatingsDeleteModal
                    style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
                    data-test-id="ReviewDeleteModalId"
                    open={this.state.openDeleteReviewsAndRatingsModal}
                    onClose={this.handleCloseDeleteReviewsAndRatingsModal}
                >
                    <ReviewsAndRatingsModalView>
                        <ReviewsAndRatingsDeleteText component={"p"}>
                            Do you want to delete this review and rating?
                        </ReviewsAndRatingsDeleteText>
                        <ReviewsAndRatingsModalButton>
                            <ReviewsAndRatingsDeleteButton data-test-id="deleteBtnTestId"
                                onClick={() => this.handleDeleteReviewsAndRatings(this.state.deleteReviewsAndRatingsId)}
                            >Delete</ReviewsAndRatingsDeleteButton>
                            <ReviewsAndRatingsCancelButton data-test-id="cancelBtnTestId"
                                onClick={() => this.handleCloseDeleteReviewsAndRatingsModal()}
                            >Cancel</ReviewsAndRatingsCancelButton>
                        </ReviewsAndRatingsModalButton>
                    </ReviewsAndRatingsModalView>
                </ReviewsAndRatingsDeleteModal>
            </>
        )
    }

    alertMessageReviewsAndRatings = () => {
        return (
            <Snackbar
                autoHideDuration={3000}
                open={this.state.isAlert}
                onClose={this.onCloseAlert}
                anchorOrigin={{ horizontal: 'center', vertical: "top" }}
            >
                <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
            </Snackbar>
        )
    }

    renderEmailSelect = () => {
        return (
            <ReviewFieldControlBox>
                <Typography className="input_txt">Student Email*</Typography>
                <SelectBox
                    id="demo-customized-select-native"
                    disableUnderline
                    IconComponent={FaChevronDown}
                    data-test-id="studentSelectTestId"
                    onChange={(event) => this.handleStudentSelectChange(event.target.value)}
                    value={this.state.studentId}
                >
                    <MenuItem aria-label="None" value="" disabled />
                    {this.state.studentListData.map((student: { id: string, attributes: { email: string } }) => {
                        return (
                            <MenuItem key={student.id} value={student.id} style={{ width: "490px", display: "flex", flexFlow: "wrap" }}>
                                {student.attributes.email}
                            </MenuItem>
                        );
                    })}
                </SelectBox>
            </ReviewFieldControlBox>
        )
    }

    renderReviewableSelect = () => {
        return (
            <ReviewFieldControlBox>
                <Typography className="input_txt">Reviewable*</Typography>
                <SelectBox
                    id="demo-customized-select-native"
                    disableUnderline
                    IconComponent={FaChevronDown}
                    data-test-id="reviewableSelectTestId"
                    onChange={(event) => this.handleCourseSelectChange(event.target.value)}
                    value={this.state.courseId}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                        style: {
                            height: "350px",
                            width: "450px",
                            top: "8px",
                        },
                        getContentAnchorEl: null,
                        keepMounted: true,
                    }}
                >
                    <MenuItem aria-label="None" value="" disabled />
                    {this.state.courseListData.map((course: { id: string, attributes: { course_name: string } }) => {
                        return (
                            <MenuItem key={course.id} value={course.id} style={{ width: "490px", display: "flex", flexFlow: "wrap" }}
                            >
                                <Typography style={{ whiteSpace: "pre-line" }}>
                                    {course.attributes.course_name}
                                </Typography>
                            </MenuItem>
                        );
                    })}
                </SelectBox>
            </ReviewFieldControlBox>
        )
    }

    renderRatingSelect = () => {
        return (
            <ReviewFieldControlBox>
                <Typography className="input_txt">Rating*</Typography>
                <SelectBox
                    id="demo-customized-select-native"
                    disableUnderline
                    IconComponent={FaChevronDown}
                    data-test-id="ratingSelectTestId"
                    onChange={(event) => this.handleRatingSelectChange(event.target.value)}
                    value={this.state.ratingId}
                >
                    <MenuItem aria-label="None" value="" disabled />
                    {this.state.ratingListData.map((rating: { id: string }) => {
                        return (
                            <MenuItem key={rating.id} value={rating.id}>
                                {rating.id}
                            </MenuItem>
                        );
                    })}
                </SelectBox>
            </ReviewFieldControlBox>
        )
    }

    renderAddReviewModal = () => {

        return (
            <>
                <AddReviewModal
                    style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
                    data-test-id="AddReviewModalId"
                    open={this.state.openAddReviewModal}
                    onClose={this.handleCloseAddReviewModal}
                >
                    <AddReviewModalView>
                        {this.alertMessageReviewsAndRatings()}
                        <AddReviewModalBox>
                            <ModalHeadingText >Add Ratings & Review</ModalHeadingText>
                            <RxCross2 onClick={() => this.handleCloseAddReviewModal()} style={{ height: "25px", width: "25px" }} />
                        </AddReviewModalBox>
                        <ReviewFieldBox>
                            {this.renderEmailSelect()}
                        </ReviewFieldBox>
                        <ReviewFieldBox>
                            {this.renderReviewableSelect()}
                        </ReviewFieldBox>
                        <ReviewFieldBox>
                            <ReviewFieldControlBox>
                                <Typography className="input_txt">Content*</Typography>
                                <ContentInputBox
                                    variant="outlined" fullWidth
                                    minRows={3}
                                    maxRows={3}
                                    multiline
                                    data-test-id="contentInputTestId"
                                    onChange={this.handleContent}
                                    value={this.state.content}
                                />
                            </ReviewFieldControlBox>
                        </ReviewFieldBox>
                        <ReviewFieldBox>
                            {this.renderRatingSelect()}
                        </ReviewFieldBox>
                        <ReviewFieldBox>
                            <ReviewFieldControlBox>
                                <Typography className="input_txt">Visibility*</Typography>
                                <CourseChapterheckBodButton className="switch">
                                    <input
                                        type="checkbox"
                                        data-test-id="visibilityTestId"
                                        onChange={this.handleVisibilityCheck}
                                        checked={this.state.visibility}
                                    />
                                    <span className="slider round"></span>
                                </CourseChapterheckBodButton>
                            </ReviewFieldControlBox>
                        </ReviewFieldBox>
                        <ButtonBox>
                            {this.state.isUpdate ?
                                <Button variant="contained" className="reset_btn"
                                    onClick={() => this.handleCloseAddReviewModal()}
                                >
                                    Cancel
                                </Button> :
                                <Button variant="contained" className="reset_btn"
                                    onClick={this.handleFeildReset}
                                >
                                    Reset
                                </Button>}
                            {this.state.isUpdate ?
                                <Button
                                    data-test-id="updateButtonTestId"
                                    onClick={this.handleEditReview}
                                    disabled={this.state.loader}
                                    variant="contained" className="create_btn">
                                    Update
                                </Button> :
                                <Button
                                    data-test-id="addButtonTestId"
                                    onClick={this.handlePostReview}
                                    disabled={this.state.loader}
                                    variant="contained" className="create_btn">
                                    Add Ratings & Review
                                </Button>}
                        </ButtonBox>
                    </AddReviewModalView>
                </AddReviewModal>
            </>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <MainLayout
                data-test-id="mainLayoutEvent"
                handleSearch={(e: any) => this.handleReviewsAndRatingsSearch(e)}
                isLoading={this.state.loader}
                title={"Reviews & Ratings"}
                actionButtons={[
                {
                    icon: <AddCircleOutlineIcon />,
                    iconType: "jsxElement",
                    onClick: () => this.handleOpenAddReviewModal(),
                    text: "Create Review",
                    testId: "createReviewButtonId"
                }
                ]}
                isStudent={false}
            >
                {this.alertMessageReviewsAndRatings()}
                {this.renderDeleteReviewsAndRatingsModal()}
                {this.renderAddReviewModal()}
                <main className="table_content">
                    {this.renderTableReviewsAndRatings()}
                </main>
            </MainLayout>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const ReviewsAndRatingsDrawerStyle = {
    drawerTableDivider: { height: "8px" },
    showChildCatDrawer: { width: `calc(100% - ${drawerWidth}px)` },
    hideChildCatDrawer: { width: `calc(100% - ${closeDrawer}px)` },
    input: {
        width: "20px",
        display: "flex",
        height: "20px",
        border: "3px solid black",
        background: "none",
        justifyContent: "center",
        alignItems: "center",
    },
    drawerDividerLine: {
        display: "inline-block",
        height: "20px",
    },
};

const ReviewsAndRatingsPaginationBox = styled(Box)({
    "& .MuiPaginationItem-page.Mui-selected": {
        color: "#fff",
        backgroundColor: "#206575",
    },
    "& .viewall_txt": {
        fontSize: "14px",
        color: "#206575",
        cursor: "pointer",
    }
});

const ReviewsAndRatingsCheckStatusBtn = styled("label")({
    "& .slider": {
        right: 0,
        cursor: "pointer",
        left: 0,
        transition: ".4s",
        position: "absolute",
        top: 0,
        bottom: "-1px",
        WebkitTransition: ".4s",
        backgroundColor: "#ccc",
        borderRadius: "20px"
    },
    "&.switch": {
        position: "relative",
        width: "36px",
        marginTop: "5px",
        display: "inline-block",
        height: "19px",
        "& input": {
            height: 0,
            opacity: 0,
            width: 0,
        },
    },
    "& input:checked + .slider": { backgroundColor: "#708328" },
    "& .slider:before": {
        content: '""',
        height: "18px",
        left: "1px",
        borderRadius: "50%",
        width: "18px",
        position: "absolute",
        bottom: "1px",
        WebkitTransition: ".4s",
        backgroundColor: "white",
        transition: ".4s"
    },
    "& input:checked + .slider:before": { left: "-10px" }
});

export const ReviewsAndRatingsDeleteModal = styled(Modal)({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
});

export const ReviewsAndRatingsModalView = styled(Box)({
    height: "120px",
    width: "340px",
    borderRadius: '5px',
    padding: "10px 50px",
    outline: "none",
    backgroundColor: '#ffffff',
    display: "flex",
    margin: "20px",
    boxShadow: "0px 0px 3px px transparent",
    flexDirection: "column"
});

export const ReviewsAndRatingsDeleteText = styled(Box)({
    width: "100%",
    height: "37px",
    fontWeight: 600,
    fontSize: "16px",
});

export const ReviewsAndRatingsCancelButton = styled("button")({
    width: "145px",
    height: "40px",
    color: "#206575",
    fontSize: "16px",
    border: "1px solid #206575",
    backgroundColor: "white"
});

export const ReviewsAndRatingsModalButton = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
});

export const ReviewsAndRatingsDeleteButton = styled("button")({
    width: "145px",
    border: "0",
    height: "40px",
    color: "white",
    backgroundColor: "#FD7101",
    fontSize: "16px",
    fontWeight: 500
});

const AddReviewModal = styled(Modal)({
    width: "100%",
    height: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center"
});

const AddReviewModalView = styled(Box)({
    width: "651px",
    borderRadius: '18px',
    padding: "10px 30px",
    overflowY: "auto",
    height: "500px",
    backgroundColor: '#ffffff',
    display: "flex",
    margin: "20px",
    outline: "none",
    boxShadow: "0px 0px 3px px transparent",
    flexDirection: "column"
});

const AddReviewModalBox = styled(Box)({
    justifyContent: "space-between",
    width: "100%",
    paddingTop: 10,
    display: "flex",
    paddingBottom: 22
});

const ModalHeadingText = styled(Typography)({
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "18px",
    fontWeight: 700,
    color: "#000",
    lineHeight: "26px",
    letterSpacing: "-0.12px"
});

export const ReviewFieldBox = styled(Box)({
    alignItems: "center",
    display: "flex",
    "@media (max-width:1330px)": {
        flexDirection: "column",
        alignItems: "start",
    },
    justifyContent: "flex-start"
});

const ReviewFieldControlBox = styled(Box)({
    justifyContent: "flex-start",
    display: "flex",
    alignItems: "center",
    marginRight: '93px',
    margin: "10px 0",
    "& .input_txt": {
        width: "160px",
        fontFamily: "Inter",
        fontStyle: "normal",
        textTransform: "capitalize",
        color: "#192252",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px"
    },
    "& .file_upload_box": {
        height: "44px",
        color: "#fff",
        border: "1px solid black",
        width: "490px",
        justifyContent: "space-between",
        display: "flex",
        borderRadius: "4px",
        alignItems: "center",
        borderColor: "#D4D4D4",
        "& .lable_txt": {
            alignItems: "center",
            backgroundColor: "#3F6474",
            display: "flex",
            height: "90%",
            marginRight: "2px",
            borderRadius: "4px",
            justifyContent: "center",
            padding: "0px 30px",
            fontSize: "12px",
        },
        "& .app_icon_txt": {
            wordSpacing: "-2px",
            fontSize: "13px",
            color: "#A9A9A9",
            marginLeft: "10px",
        },
    },
    "@media (max-width:875px)": {
        flexDirection: "column",
        alignItems: "start",
        "& .input_txt": {
            marginBottom: "8px",
            width: "100%",
        }
    },
});

const SelectBox = styled(Select)({
    "&.MuiInputBase-root": {
        width: "490px",
        border: "1px solid lightgray",
        borderRadius: "4px"
    },
    "& .MuiNativeSelect-icon": {
        top: "calc(50% - 7px);",
        right: "6px",
    },
    "& .MuiInputBase-input": { padding: "10px 0px 10px 8px" }
});

export const ContentInputBox = styled(TextField)({
    "& .MuiOutlinedInput-input": { padding: "0px 14px" },
    width: "490px",
    "& .MuiInputBase-input": { height: "86px" },
    "& .MuiOutlinedInput-root": { "&.Mui-focused fieldset": { borderColor: "#3F6474" } },
});

export const ButtonBox = styled(Box)({
    justifyContent: "center",
    display: "flex",
    width: "100%",
    margin: "25px 0",
    alignItems: "center",
    "& .create_btn": {
        borderRadius: "0",
        color: "#fff",
        textTransform: "capitalize",
        fontSize: "14px",
        backgroundColor: "#3f6474",
        boxShadow: "none",
        width: "220px",
        margin: "0px 10px",
        fontFamily: "Inter",
        fontWeight: 700,
        height: "40px",
        lineHeight: "22px",
        fontStyle: "normal",
    },
    "& .reset_btn": {
        textTransform: "capitalize",
        borderRadius: "0",
        border: "1px solid #3F6474",
        fontSize: "14px",
        backgroundColor: "#fff",
        width: "108px",
        padding: "10px 60px",
        color: "#3f6474",
        margin: "0px 10px",
        fontFamily: "Inter",
        height: "40px",
        fontStyle: "normal",
        lineHeight: "22px",
        boxShadow: "none",
        fontWeight: 700,
    },
    "@media (max-width:460px)": {
        "& .create_btn": {
            padding: '5px 48px',
            height: '40px',
        },
        "& .reset_btn": {
            padding: '5px 50px',
            height: '40px',
        },
    },
    "@media (max-width:400px)": {
        "& .create_btn": { width: '100%' },
        flexDirection: 'column',
        "& .reset_btn": { marginBottom: '10px', width: '100%' }
    },
});
// Customizable Area End