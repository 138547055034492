Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";
exports.SignupWithMobileEndpoint = "account_block/sent_otp_on_mobile";
exports.SignupWithMobileResendEndpoint = "account_block/resend_otp_on_mobile";
exports.SignupWithMobileVerifyEndpoint = "account_block/verify_mobile_otp";


// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch =
  "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.editText = "Edit";
exports.deleteText = "Delete";
exports.addStateText = "Add State";
exports.stateText = "State";
exports.idText = "ID";
exports.stateNameText = "State Name";
exports.nameTextDistrict = "Name";
exports.districtNameText = "District";
exports.districtNameTextWithName = "District Name";
exports.createdAtText = "Created At";
exports.updatedAtText = "Updated At";
exports.sNoText = "S.No";
exports.profileCountryText = "Profile Country";
exports.actionText = "Action";
exports.deleteSuccessMsg = "State Deleted Successfully";
exports.successMsg = "success";
exports.errorMsg = "error";
exports.endPointApiGetState = "bx_block_profile/states/";
exports.statesApiContentType = "application/json";
exports.httpDeleteType = "DELETE";
exports.deleteStateAPIEndPoint = "bx_block_profile/states";
exports.getBannersListApiEndpoint = "bx_block_profile/banners";
exports.formDataType = "multipart/form-data";
exports.httpGetType = "GET";
exports.jsonContentType = "application/json";
exports.deleteStateAPIEndPoint = "bx_block_profile/states";
exports.endPointApiGetDistrict = "bx_block_profile/districts/";
exports.deleteDistrictAPIEndPoint = "bx_block_profile/districts/";
exports.districtDeleteSuccessMsg = "District Deleted Successfully";
exports.districtSuccessMsg = "success";
exports.districtErrorMsg = "error";
exports.cancelDistrictText = "Cancel";
exports.deleteDistrictModalText = "Do you want to delete this district?";
exports.endPointApiGetStateList = "bx_block_profile/states/list_state";
exports.errorMsgDistrictBlank = " cannot be blank";
exports.endPointApiPostDistrict = "bx_block_profile/districts/";
exports.districtUpdateSuccessMsg = "District Edited Successfully";
exports.testimonialsListApiEndpoint =
  "bx_block_profile/testimonials/list_testimonials";
exports.tokenExpiredMessage = "Token has Expired";
exports.tokenInvalidMessage = "Invalid token";
exports.counterNameText = "Country Name";
exports.adminText = "Admin";
exports.statesText = "States";
exports.selectCountryText = "Select Country";
exports.nameText = "Name";
exports.cancelText = "Cancel";
exports.updateStateText = "Update";
exports.resetText = "Reset";
exports.createStateText = "Create";
exports.updateSuccessMsg = "State Edited Successfully";
exports.errorMsgBlank = " cannot be blank";
exports.postStateErrorMsg = "State name already exists. Please choose a different name.";
exports.endPointApiGetCountry = "bx_block_profile/profile_countries/";
exports.endPointApiPostState = "bx_block_profile/states";
exports.deleteStateModalText = "Do you want to delete this state?";
exports.validationErrorMsg = "Input cannot be only spaces, only special characters, only numbers or characters limit should be 30max .";
exports.getAchievementsApiEndpoint =
  "bx_block_profile/achievements/show_achievement";
exports.updateAchievementsApiEndpoint =
  "bx_block_profile/achievements/update_achievement";
exports.getFooterApiEndpoint = "bx_block_profile/footers/show_footer";
exports.updateFooterApiEndpoint = "bx_block_profile/footers/update_footer";
exports.maxFileSize = 2 * 1024 * 1024;
exports.getUserProfileApiEndpoint = "bx_block_profile/profiles/user_profile";
exports.updateUserProfileApiEndpoint =
  "bx_block_profile/profiles/update_profile";
exports.stateApiEndPoint = `bx_block_profile/states/show_states/1`;
exports.districtAPiEndPoint = `bx_block_profile/districts/show_districts/`;
exports.profileUpdatedSuccessfullyMessage = "Profile Updated Successfully";
exports.footerBannerUpdatedSuccessfully = "Footer banner updated successfully";
exports.footerBannerCreatedSuccessfully = "Footer banner created successfully";
exports.tooltipInfoMessageText = "Image should be in PNG, JPG, or JPEG format"
exports.deleteConfirmationMsg = "Do you want to delete this Banner?";
exports.deleteText = "Delete";
exports.cancelText = "Cancel";
exports.browseText = "Browse";
exports.resetText = "Reset";
exports.updateText = "Update";
exports.createText = "Create";
exports.sessionExpireMsg = "Session is expired. Please login again.";
exports.fullNameErrorMsg = "Full name must contain only letters and spaces, and must be at least 3 characters long.";
exports.mobileNumberErrMsg = "Mobile number is required";
// Customizable Area End

