import React, { CSSProperties } from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  FormControl,
  Button,
  styled,
  Select,
  Grid,
  TextField,
  NativeSelect
} from "@material-ui/core";
import { IoIosArrowDown } from "react-icons/io";
const drawerWidth = 250;
import { DuplicatedModal } from "./Vouchercodegenerator.web";
import Loader from "../../../components/src/Loader.web";
import IconButton from "@material-ui/core/IconButton";
import RteEditor from "../../../components/src/RteEditor.web";
import DateRangeIcon from '@material-ui/icons/DateRange';
// Customizable Area End

import CouponController, { Props } from "./CouponcodegeneratorController.web";
import MainLayout from "../../../components/src/MainLayout.web";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";

export default class AddCouponcodeGenerator extends CouponController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.closemodal = this.closemodal.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start

  renderCouponTypeSelect = () => {
    return (
      <SelectBox
        fullWidth
        disableUnderline
        id="demo-customized-select-native"
        data-test-id="couponTypeSelectTestId"
        name="selectedCouponeType"
        onChange={(event) => this.handleCouponInputChange(event, "number")}
        IconComponent={IoIosArrowDown}
        value={this.state.couponFormData.selectedCouponeType}
      >
        {this.state.couponeType.map((category: { id: number, title: string }) => {
          return (
            <option key={category.id} value={category.id}>
              {category.title}
            </option>
          );
        })}
      </SelectBox>
    );
  };

  renderAllCourseComponent = () => {
    return (
      <>
        <Box
          key={1}
          style={{
            display: "flex",
            flexFlow: "wrap",
            whiteSpace: "break-spaces"
          }}
        >
          <Box style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', margin: '5px 10px 5px 30px' }}>
            <Typography data-test-id={'btnHandleAllCourseChange'} style={{ flex: 1, display: "flex", alignItems: "center" }} onClick={() => this.handleAllCourseChange()}>
              <img src={this.getExpandCollapseStatus(this.state.allCourseExpand)} style={{ height: '6px', width: '12px', marginRight: '5px' }} />
              <span style={{ fontFamily: 'Inter', fontWeight: 500, fontSize: '16px', color: '#206574', marginRight: '5px' }}>All Course</span>
              <span style={{ fontFamily: 'Inter', fontWeight: 500, fontSize: '16px', color: '#B1C8CE' }}>(All)</span>
            </Typography>
            <img data-test-id={'btnHandleAllCourseCheck'} src={this.getCheckUncheckStatus(this.getIsAllCourseSelected())} style={{ height: '25px', width: '25px' }} onClick={() => this.handleAllCourseCheck()} />
          </Box>
          <Box style={{ backgroundColor: '#979797', height: 1, width: '100%', marginTop: '5px', marginBottom: '5px' }} />
        </Box>
        {this.state.allCourseExpand &&
          this.state.associatedCourses &&
          this.state.associatedCourses.courses_data &&
          this.state.associatedCourses.courses_data.map(course => (
            <Box
              key={course.id}
              style={{
                display: "flex",
                flexFlow: "wrap",
                whiteSpace: "break-spaces", marginLeft: '30px'
              }}
            >
              <Box style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }} data-test-id={'btnHandleClassCheck'} onClick={() => this.handleCourseCheck(course.id)}>
                <img src={this.getCheckUncheckStatus(course.isSelected)} style={{ height: '25px', width: '25px' }} />
                <Typography style={{ flex: 1, fontFamily: 'Inter', fontWeight: 500, fontSize: '16px', color: '#000000', marginLeft: '10px' }}>
                  {course.name}
                </Typography>
              </Box>
              <Box style={{ backgroundColor: '#979797', height: 1, width: '100%', marginTop: '5px', marginBottom: '5px' }} />
            </Box>
          ))
        }
      </>
    )
  }

  renderMainCourseComponent = () => {
    return (
      <>
        {this.state.associatedCourses &&
          this.state.associatedCourses.category_data &&
          this.state.associatedCourses.category_data.data &&
          this.state.associatedCourses.category_data.data.map(mainData => (
            <Box
              key={mainData.id}
              style={{
                display: "flex",
                flexFlow: "wrap",
                whiteSpace: "break-spaces"
              }}
            >
              <Box style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', margin: '5px 10px 5px 30px' }}>
                <Typography data-test-id={'btnHandleMainCourseChange'} style={{ flex: 1, display: "flex", alignItems: "center" }} onClick={() => this.handleMainCourseChange(mainData.id)}>
                  <img src={this.getExpandCollapseStatus(mainData.attributes.isExpanded)} style={{ height: '6px', width: '12px', marginRight: '5px' }} />
                  <span style={{ fontFamily: 'Inter', fontWeight: 500, fontSize: '16px', color: '#206574', marginRight: '5px' }}>{mainData.attributes.name}</span>
                  <span style={{ fontFamily: 'Inter', fontWeight: 500, fontSize: '16px', color: '#B1C8CE' }}>(All)</span>
                </Typography>
                <img data-test-id={'btnHandleMainCourseCheck'} src={this.getCheckUncheckStatus(this.getIsMainCourseSelected(mainData.id))} style={{ height: '25px', width: '25px' }} onClick={() => this.handleMainCourseCheck(mainData.id)} />
              </Box>
              <Box style={{ backgroundColor: '#979797', height: 1, width: '100%', marginTop: '5px', marginBottom: '5px' }} />
              {mainData.attributes.isExpanded && 
                !!mainData.attributes.class_content.length &&
                mainData.attributes.class_content.map(classData => (
                  <Box
                    key={classData.id}
                    style={{
                      width: '100%',
                      display: "flex",
                      flexFlow: "wrap",
                      whiteSpace: "break-spaces",
                      marginLeft: '20px'
                    }}
                  >
                    <Box style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', margin: '5px 10px 5px 30px' }}>
                      <Typography data-test-id={'btnHandleClassChange'} style={{ flex: 1, display: "flex", alignItems: "center" }} onClick={() => this.handleClassCourseChange(mainData.id, classData.id)}>
                        <img src={this.getExpandCollapseStatus(classData.isExpanded)} style={{ height: '6px', width: '12px', marginRight: '5px' }} />
                        <span style={{ fontFamily: 'Inter', fontWeight: 500, fontSize: '16px', color: '#000000', marginRight: '5px' }}>{classData.sub_category_name}</span>
                        <span style={{ fontFamily: 'Inter', fontWeight: 500, fontSize: '16px', color: '#B1C8CE' }}>{`(${classData.child_categories.length})`}</span>
                      </Typography>
                      <img data-test-id={'btnHandleSubCategoryCheck'} src={this.getCheckUncheckStatus(this.getIsClassSubCategorySelected(mainData.id, classData.id))} style={{ height: '25px', width: '25px' }} onClick={() => this.handleClassSubCategoryCheck(mainData.id, classData.id)} />
                    </Box>
                    <Box style={{ backgroundColor: '#979797', height: 1, width: '100%', marginTop: '5px', marginBottom: '5px' }} />
                    {classData.isExpanded &&
                      !!classData.child_categories.length &&
                      classData.child_categories.map(childData => (
                        <Box
                          key={childData.id}
                          style={{
                            width: '100%',
                            display: "flex",
                            flexFlow: "wrap",
                            whiteSpace: "break-spaces",
                            marginLeft: '20px'
                          }}
                        >
                          <Box style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', margin: '5px 10px 5px 30px' }}>
                            <Typography data-test-id={'btnHandleChildChange'} style={{ flex: 1, display: "flex", alignItems: "center" }} onClick={() => this.handleChildCourseChange(mainData.id, classData.id, childData.id)}>
                              <img src={this.getExpandCollapseStatus(childData.isExpanded)} style={{ height: '6px', width: '12px', marginRight: '5px' }} />
                              <span style={{ fontFamily: 'Inter', fontWeight: 500, fontSize: '16px', color: '#000000', marginRight: '5px' }}>{childData.child_category_name}</span>
                              <span style={{ fontFamily: 'Inter', fontWeight: 500, fontSize: '16px', color: '#B1C8CE' }}>{`(${childData.courses.length})`}</span>
                            </Typography>
                            <img data-test-id={'btnHandleChildCategoryCheck'} src={this.getCheckUncheckStatus(this.getIsChildCategorySelected(mainData.id, classData.id, childData.id))} style={{ height: '25px', width: '25px' }} onClick={() => this.handleChildCategoryCheck(mainData.id, classData.id, childData.id)} />
                          </Box>
                          <Box style={{ backgroundColor: '#979797', height: 1, width: '100%', marginTop: '5px', marginBottom: '5px' }} />
                          {childData.isExpanded &&
                            !!childData.courses.length &&
                            childData.courses.map(courseData => (
                              <Box
                              key={courseData.id}
                              style={{
                                display: "flex",
                                flexFlow: "wrap",
                                whiteSpace: "break-spaces", marginLeft: '30px',
                                width: "100%"
                              }}
                            >
                              <Box style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }} data-test-id={'btnHandleCourseCategoryCheck'} onClick={() => this.handleCourseCategoryCheck(mainData.id, classData.id, childData.id, courseData.id)}>
                                <img src={this.getCheckUncheckStatus(courseData.isSelected)} style={{ height: '25px', width: '25px' }} />
                                <Typography data-test-id={'btnHandleCourseCategoryChange'} style={{ flex: 1, fontFamily: 'Inter', fontWeight: 500, fontSize: '16px', color: '#000000', marginLeft: '10px' }}>
                                  {courseData.course_name}
                                </Typography>
                              </Box>
                              <Box style={{ backgroundColor: '#979797', height: 1, width: '100%', marginTop: '5px', marginBottom: '5px' }} />
                            </Box>
                            ))
                          }
                        </Box>
                      ))
                    }
                  </Box>
                ))
              }
            </Box>
          ))}
      </>
    )
  }

  renderBundleCourseComponent = () => {
    return (
      <>
        <Box
          key={1}
          style={{
            display: "flex",
            flexFlow: "wrap",
            whiteSpace: "break-spaces"
          }}
        >
          <Box style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', margin: '5px 10px 5px 30px' }}>
            <Typography data-test-id={'btnHandleBundleCourseChange'} style={{ flex: 1, display: "flex", alignItems: "center" }} onClick={() => this.handleBundleCourseChange()}>
              <img src={this.getExpandCollapseStatus(this.state.bundleCourseExpand)} style={{ height: '6px', width: '12px', marginRight: '5px' }} />
              <span style={{ fontFamily: 'Inter', fontWeight: 500, fontSize: '16px', color: '#206574', marginRight: '5px' }}>Bundle Course</span>
              <span style={{ fontFamily: 'Inter', fontWeight: 500, fontSize: '16px', color: '#B1C8CE' }}>(All)</span>
            </Typography>
            <img data-test-id={'btnHandleBundleCourseCheck'} src={this.getCheckUncheckStatus(this.getIsBundleCourseSelected())} style={{ height: '25px', width: '25px' }} onClick={() => this.handleBundleCourseCheck()} />
          </Box>
          <Box style={{ backgroundColor: '#979797', height: 1, width: '100%', marginTop: '5px', marginBottom: '5px' }} />
        </Box>
        {this.state.bundleCourseExpand &&
          this.state.associatedCourses &&
          this.state.associatedCourses.bundle_courses_data &&
          this.state.associatedCourses.bundle_courses_data.map(bundleCourse => (
            <Box
              key={bundleCourse.id}
              style={{
                display: "flex",
                flexFlow: "wrap",
                whiteSpace: "break-spaces", marginLeft: '30px'
              }}
            >
              <Box style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }} data-test-id={'btnHandleBundleClassCheck'} onClick={() => this.handleBCourseCheck(bundleCourse.id)}>
                <img src={this.getCheckUncheckStatus(bundleCourse.isSelected)} style={{ height: '25px', width: '25px' }} />
                <Typography data-test-id={'btnHandleBundleClassChange'} style={{ flex: 1, fontFamily: 'Inter', fontWeight: 500, fontSize: '16px', color: '#000000', marginLeft: '10px' }}>
                  {bundleCourse.name}
                </Typography>
              </Box>
              <Box style={{ backgroundColor: '#979797', height: 1, width: '100%', marginTop: '5px', marginBottom: '5px' }} />
            </Box>
          ))
        }
      </>
    )
  }

  renderAddCouponForm() {
    const { couponFormData } = this.state;
    return (
      <div style={webStyles.bundleCourseMainLayout}>
        <Box sx={webStyles.adminLayout}>
          <CouponTypeMainBox>
            {this.renderCouponTypeSelect()}
          </CouponTypeMainBox>
          <TwoColBox>
            <Box className="child__box">
              <Box className="box__label">Coupon Name<span className="asterisk">*</span></Box>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  data-test-id="couponNameTestId"
                  id="outlined-basic"
                  variant="outlined"
                  name="coupon_name"
                  fullWidth
                  value={couponFormData.coupon_name}
                  onChange={e => {
                    this.handleCouponInputChange(e);
                  }}
                />
              </FormControl>
            </Box>
            <Box className="child__box">
              <Box className="box__label">Coupon Code<span className="asterisk">*</span></Box>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  data-test-id="couponCodeTestId"
                  id="outlined-basic"
                  variant="outlined"
                  name="coupon_code"
                  fullWidth
                  value={couponFormData.coupon_code}
                  onChange={e => {
                    this.handleCouponInputChange(e);
                  }}
                />
              </FormControl>
            </Box>
          </TwoColBox>
          <OneColBox>
            <Box>
              <Box className="box__label">Discount Type<span className="asterisk">*</span></Box>
              <Grid container style={{ marginTop: '5px' }}>
                <Grid item style={{ marginLeft: '-10px' }}>
                  <Button data-test-id="flatDiscountTestId" onClick={() => this.handleCouponInputChange({target: { value: "amount", name: "discount_type" }})}>
                    <Box style={{ display: 'flex', flexDirection: 'row', marginRight: '5px', alignItems: 'center', justifyContent: 'center' }}>
                      <img src={this.getSelectUnSelectStatus(couponFormData.discount_type === "amount")} style={{ height: 20, width: 20 }} />
                      <Typography style={{ color: ' #000000', fontFamily: 'Inter', fontSize: '16px', marginLeft: '5px', textTransform: 'none' }}>Flat Discount</Typography>
                    </Box>
                  </Button>
                </Grid>
                <Grid item>
                  <Button data-test-id="preDiscountTestId" onClick={() => this.handleCouponInputChange({target: { value: "percentage", name: "discount_type" }})}>
                    <Box style={{ display: 'flex', flexDirection: 'row', marginLeft: '5px', alignItems: 'center', justifyContent: 'center' }}>
                      <img src={this.getSelectUnSelectStatus(couponFormData.discount_type === "percentage")} style={{ height: 20, width: 20 }} />
                      <Typography style={{ color: ' #000000', fontFamily: 'Inter', fontSize: '16px', marginLeft: '5px', textTransform: 'none' }}>Percentage Discount</Typography>
                    </Box>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </OneColBox>
          <TwoColBox>
            <Box className="child__box">
              <Box className="box__label" style={{ display: "block" }}>{`
                Discount ${couponFormData.discount_type === "amount"
                  ? "Amount (₹)"
                  : "Percentage (%)"}
                `}<span className="asterisk">*</span>
              </Box>
              <FormControl variant="outlined" fullWidth className="box__input">
              <div style={{ position: 'relative' }}>
                <InputBox
                  data-test-id="discountTestId"
                  id="outlined-basic"
                  variant="outlined"
                  name="discount"
                  fullWidth
                  inputProps={{
                    inputProps: {
                      min: 0
                    }
                  }}
                  value={couponFormData.discount}
                  onChange={e => {
                    this.handleCouponInputChange(e, "number");
                  }}
                  onFocus={()=>this.handleDiscountFocus(true)}
                  onBlur={()=>this.handleDiscountFocus(false)}
                />
                {!this.state.isDiscountFocus && !couponFormData.discount &&
                  <span
                    style={{
                      position: 'absolute',
                      left: couponFormData.discount_type === "amount" ? '12px' : 'auto',
                      right: couponFormData.discount_type === "percentage" ? '12px' : 'auto',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      pointerEvents: 'none',
                      color: '#94A3B8',
                      fontSize: '16px',
                      fontWeight: 500,
                      fontFamily: 'Inter'
                    }}
                  >
                      {this.discountTypePlaceholder()}
                  </span>
                }
                </div>
              </FormControl>
            </Box>
            <Box className="child__box">
              <Box className="box__label">Minimum Order Value</Box>
              <FormControl variant="outlined" fullWidth className="box__input">
                <div style={{position:'relative'}}>
                <InputBox
                  data-test-id="miniOrderValueTestId"
                  id="outlined-basic"
                  variant="outlined"
                  name="applicable_on"
                  fullWidth
                  inputProps={{
                    inputProps: {
                      min: 0
                    }
                  }}
                  value={couponFormData.applicable_on}
                  onChange={e => this.handleCouponInputChange(e, "number")}
                  onFocus={()=>this.handleMiniOrderFocus(true)}
                  onBlur={()=>this.handleMiniOrderFocus(false)}
                />
                {!this.state.isMiniOrderFocus && !couponFormData.applicable_on &&
                  <span
                    style={{
                      position: 'absolute',
                      left: '12px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      pointerEvents: 'none',
                      color: '#94A3B8',
                      fontSize: '16px',
                      fontWeight: 500,
                      fontFamily: 'Inter'
                    }}
                  >
                  (₹)
                  </span>
                }
                </div>
              </FormControl>
            </Box>
          </TwoColBox>
          {couponFormData.discount_type === "percentage" &&
            <TwoColBox>
              <Box className="child__box">
                <Box className="box__label">Max Discount Upto</Box>
                <FormControl variant="outlined" fullWidth className="box__input">
                  <div style={{ position: 'relative' }}>
                    <InputBox
                      data-test-id="maxDiscountTestId"
                      id="outlined-basic"
                      variant="outlined"
                      name="max_discount_upto"
                      fullWidth
                      inputProps={{
                        inputProps: {
                          min: 0
                        }
                      }}
                      value={couponFormData.max_discount_upto}
                      onChange={e => this.handleCouponInputChange(e, "number")}
                      onFocus={() => this.handleMaxDiscountFocus(true)}
                      onBlur={() => this.handleMaxDiscountFocus(false)}
                    />
                    {!this.state.isMaxDiscountUpFocus && !couponFormData.max_discount_upto &&
                      <span
                        style={{
                          position: 'absolute',
                          left: '12px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          pointerEvents: 'none',
                          color: '#94A3B8',
                          fontSize: '16px',
                          fontWeight: 500,
                          fontFamily: 'Inter'
                        }}
                      >
                        (₹)
                      </span>
                    }
                  </div>
                </FormControl>
              </Box>
            </TwoColBox>
          }
          <TwoColBox>
            <Box className="child__box">
              <Box className="box__label">
                Start Date<span className="asterisk">*</span>
              </Box>
              <FormControl variant="outlined" fullWidth className="box__input">
                <DateInput
                  id="datetime-local"
                  name="start_date"
                  value={couponFormData.start_date}
                  data-test-id="startDateTestId"
                  onChange={e => {
                    this.handleCouponInputChange(e, "date");
                  }}
                  inputProps={{
                    inputProps: {
                      min: this.state.isUpdate ? "" : new Date().toJSON().slice(0, 10)
                    }
                  }}
                />
              </FormControl>
            </Box>
            <Box className="child__box">
              <Box className="box__label">
                Expiry Date<span className="asterisk">*</span>
              </Box>
              <FormControl variant="outlined" fullWidth className="box__input">
                <DateInput
                  id="datetime-local"
                  name="expiry_date"
                  value={couponFormData.expiry_date}
                  data-test-id="expiryDateTestId"
                  onChange={e => {
                    this.handleCouponInputChange(e, "date");
                  }}
                  inputProps={{
                    inputProps: {
                      min: new Date().toJSON().slice(0, 10)
                    }
                  }}
                />
              </FormControl>
            </Box>
          </TwoColBox>
          <TwoColBox>
            <Box className="child__box">
              <FormControl variant="outlined" fullWidth className="box__input">
                <Grid container>
                  <Grid item>
                    <Button data-test-id={'btnUnlimitedTimeUser'} onClick={() => this.handleUnlimitedTimeUseChange()}>
                      <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={this.getCheckUncheckStatus(this.state.isUnlimitedTimeUse)} style={{ height: 20, width: 20 }} />
                        <Typography style={{ color: ' #000000', fontFamily: 'Inter', fontSize: '16px', marginLeft: '5px', textTransform: 'none' }}>Check, for Unlimited time use</Typography>
                      </Box>
                    </Button>
                  </Grid>
                </Grid>
              </FormControl>
            </Box>
            <Box className="child__box">
              <Typography className="box__label" style={{ display: "block" }}>
                <span style={{
                  minWidth: 150,
                  maxWidth: '30%',
                  color: '#192252',
                  fontFamily: 'Inter',
                  fontSize: '0.875rem',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '24px',
                }}>Total no. of time code can be used</span>
                <span style={{ color: 'red' }}>*</span>
              </Typography>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  data-test-id="applicablePurchaseTestId"
                  id="outlined-basic"
                  variant="outlined"
                  name="no_of_times_coupon_applicable"
                  inputProps={{
                    inputProps: {
                      min: 0
                    }
                  }}
                  fullWidth
                  value={couponFormData.no_of_times_coupon_applicable}
                  onChange={e => {
                    this.handleCouponInputChange(e, "number");
                  }}
                  disabled={this.state.isUnlimitedTimeUse}
                />
              </FormControl>
            </Box>
          </TwoColBox>
          <TwoColBox>
            <Box className="child__box multiselect">
              <Box className="box__label" style={{ alignItems: "flex-start" }}>
                Associated Course<span style={{ color: 'red' }}>*</span>
                <IconButton
                  color="primary"
                  data-test-id="infoButtonTestId"
                  size="small"
                  onClick={() => this.onCourseInfoClick()}
                  style={{ fontSize: "0.75rem" }}
                  aria-label="add to shopping cart"
                >
                </IconButton>
              </Box>
              <FormControl variant="outlined" fullWidth className="box__input">
                <MultiSelect
                  fullWidth={true}
                  multiple
                  name="course_id"
                  value={[]}
                  className="multiple__input"
                  data-test-id="associateCourseTestId"
                  IconComponent={IoIosArrowDown}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    keepMounted: true,
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: {
                        width: 480,
                        maxHeight: 400,
                        border:'1px solid #979797'
                      }
                    }
                  }}
                >
                  {this.renderAllCourseComponent()}
                  {this.renderMainCourseComponent()}
                  {this.renderBundleCourseComponent()}
                </MultiSelect>
              </FormControl>
            </Box>
            <Box className="child__box">
              <Box className="box__label">
                Usages Per Student<span className="asterisk">*</span>
              </Box>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  data-test-id="UsagesPerStudentTestId"
                  id="outlined-basic"
                  variant="outlined"
                  name="usage_per_user"
                  inputProps={{
                    inputProps: {
                      min: 0
                    }
                  }}
                  fullWidth
                  value={couponFormData.usage_per_user}
                  onChange={e => this.handleCouponInputChange(e, "number")}
                />
              </FormControl>
            </Box>
          </TwoColBox>
          <OneColBox1>
            <Box className="box__label">Terms &<br /> Conditions</Box>
            <FormControl
              variant="outlined"
              className="box__input"
              fullWidth
              style={{ color: "#192252" }}
            >
              <RteEditor
                data-test-id="rteEditorTestId"
                placeholder="Enter the text you want to field to display"
                onChange={(value) => this.handleCouponInputChange({ target: { value, name: "terms_n_condition" } })}
                markup={this.state.isUpdate ? couponFormData.terms_n_condition : ""}
                testId="rte"
                toolbarConfig={{
                  display: ["INLINE_STYLE_BUTTONS", "LINK_BUTTONS", "HISTORY_BUTTONS"],
                  INLINE_STYLE_BUTTONS: [
                    { label: "Bold", style: "BOLD", className: "custom-css-class" },
                    { label: "Italic", style: "ITALIC" },
                    { label: "Underline", style: "UNDERLINE" },
                    { label: "Strikethrough", style: "STRIKETHROUGH" },
                    { label: "Monospace", style: "CODE" }
                  ],
                  BLOCK_TYPE_BUTTONS: [],
                  BLOCK_TYPE_DROPDOWN: [],
                  BLOCK_ALIGNMENT_BUTTONS: []
                }}
              />
            </FormControl>
          </OneColBox1>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              columnGap: "10px",
              marginBottom: "20px"
            }}
          >
            <Button
              style={webStyles.resetBtn}
              variant="outlined"
              data-test-id="cancelButtonTestId"
              onClick={() => this.handleGoBack()}
            >
              Cancel
            </Button>
            <Button
              data-test-id="createButtonTestId"
              style={webStyles.createBtn}
              disabled={this.state.isLoading}
              onClick={() => this.handleAddCoupon()}
            >
              {this.state.isUpdate ? "Update" : "Create"}
            </Button>
          </div>
        </Box>
      </div>
    );
  }

  closemodal() {
    this.oncloseAlert();
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <MainLayout
          data-test-id="mainLayout"
          isAdd={true}
          backData={() => this.handleGoBack()}
          title={this.state.isUpdate ? "Edit Coupon Code" : "Add Coupon Code"}
        >
          <NotificationAlertMessage
            alertType={this.state.alertType}
            msg={this.state.alertMsg}
            onClose={this.closemodal}
            isOpen={this.state.isAlert}
          />
          <Loader loading={this.state.isLoading} />
          <DuplicatedModal
            data-test-id="duplicatedModal"
            handleCloseModal={() =>
              this.setState({ isModalOpen: false, alertMsg: "" })
            }
            isModalOpen={this.state.isModalOpen}
            message={this.state.alertMsg}
          />
          <main className="table_content" style={{ height: "auto" }} data-test-id="addCouponMainDiv">{this.renderAddCouponForm()}</main>
        </MainLayout>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const CouponTypeMainBox = styled(Box)({
  alignItems: "center",
  display: "flex",
  "@media (max-width:1330px)": {
      flexDirection: "column",
      alignItems: "start",
  },
  justifyContent: "flex-start"
});

const SelectBox = styled(NativeSelect)({
    "&.MuiInputBase-root": {
        width: "330px",
        borderRadius: "9px",
        backgroundColor:'#F5F5F7',
        padding: "0px",
        fontFamily: "'Inter'",
        fontSize:'16px',
        fontWeight:400,
        color:'#192252'
    },
    "& .MuiNativeSelect-icon": {
        right: "20px",
        top: "calc(50% - 7px);",
    },
    "& .MuiInputBase-input": { padding: "20px 0px 20px 15px", textTransform: "none" },
    "&  .MuiSelect-icon": {
    right: "10px",
    color:'#0F172A'
  },
});

const OneColBox1 = styled(Box)({
  borderBottom: '1px solid #F6F6F6',
  columnGap: '1rem',
  display: 'inline-flex',
  width: '100%',
  paddingBottom: 20,
  justifyContent: 'space-between',
  '&.align-column': {
    flexDirection: 'column',
    justifyContent: 'start'
  },
  '& .box__label': {
    minWidth: 150,
    maxWidth: '30%',
    color: '#192252',
    fontFamily: 'Inter',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center'
  },
  '& .box__input': {
    '& > div': {
      borderRadius: '5px',
      background: 'white',
      border: '1px solid #CBD5E1',
    },
  },
  '& .box__input.rs__inputs': {
    width: '20%'
  },
  '@media (max-width:1024px)': {
    display: 'flex',
    flexDirection: 'column',
    '& .box__input': {
      width: '100% !important'
    },
  },
});


const TwoColBox = styled(Box)({
  display: 'inline-flex',
  columnGap: '1rem',
  borderBottom: '1px solid rgba(189, 188, 188, 0.314)',
  flexWrap: 'wrap',
  width: '100%',
  justifyContent: 'space-between',
  '& .asterisk':{
    color: 'red'
  },
  '& .input_txt': {
    textTransform: 'capitalize',
    width: '160px',
    color: '#192252',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px'
  },
  '& .file_upload_box': {
    height: '40px',
    color: '#fff',
    border: '1px solid black',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderColor: '#D4D4D4',
    '& .app_icon_txt': {
      fontSize: '13px',
      color: '#A9A9A9',
      marginLeft: '10px',
      wordSpacing: '-2px'
    },
    '& .lable_txt': {
      backgroundColor: '#3F6474',
      height: '90%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '2px',
      fontSize: '12px',
      borderRadius: '4px',
      padding: '0px 30px'
    },
  },
  '& .child__box': {
    display: 'flex',
    paddingBottom: 20,
    flex: '0 0 47%',
    columnGap: '1rem',
    justifyContent: 'space-between',
    '&.align-column': {
      flexDirection: 'column',
      justifyContent: 'start'
    },
    '& .box__label': {
      minWidth: 150,
      maxWidth: '30%',
      color: '#192252',
      fontFamily: 'Inter',
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      display: 'flex',
      alignItems: 'center'
    },
    '& .MuiInputBase-root': {
      background: 'white'
    },
  },
  '@media (max-width:803px)': {
      '& .child__box': {
        paddingBottom:'unset'
      },
    },
  // MEDIA QUERYS FOR INPUT MULTISELECT
  '& .child__box.multiselect': {
    '& .box__input': {
      width: '70%',
      maxWidth: "312px",
      '& > div': {
        borderRadius: '4px',
        background: 'white'
      },
    },
    '@media (max-width:1310px)': {
      '& .box__input': {
        maxWidth: '298px !important'
      },
    },
    '@media (max-width:1279px)': {
      '& .box__input': {
        maxWidth: '288px !important'
      },
    },
    '@media (max-width:1255px)': {
      '& .box__input': {
        maxWidth: '277px !important'
      },
    },
    '@media (max-width:1210px)': {
      '& .box__input': {
        maxWidth: '252px !important'
      },
    },
    '@media (max-width:1159px)': {
      '& .box__input': {
        maxWidth: '230px !important'
      },
    },
    '@media (max-width:1117px)': {
      '& .box__input': {
        maxWidth: '213px !important'
      },
    },
    '@media (max-width:1024px)': {
      '& .box__input': {
        maxWidth: '72vw !important'
      },
    },
  },
  '@media (max-width:1024px)': {
    display: 'flex',
    flexDirection: 'column',
    '& .child__box': {
      flexDirection: 'column',
      '& .box__input': {
        width: '100% !important'
      },
    },
  },
});


const OneColBox = styled(Box)({
  borderBottom: '1px solid rgba(189, 188, 188, 0.314)',
  columnGap: '1rem',
  display: 'inline-flex',
  width: '100%',
  paddingBottom: 20,
  justifyContent: 'space-between',
  '& .box__label .asterisk':{
    color: 'red'
  },
  '&.align-column': {
    flexDirection: 'column',
    justifyContent: 'start'
  },
  '& .box__label': {
    minWidth: 150,
    maxWidth: '30%',
    color: '#192252',
    fontFamily: 'Inter',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center'
  },
  '& .box__input': {
    // width: '70%',
    '& > div': {
      borderRadius: '4px',
      background: 'white'
    },
  },
  '& .box__input.rs__inputs': {
    width: '20%'
  },
  '@media (max-width:1024px)': {
    display: 'flex',
    flexDirection: 'column',
    '& .box__input': {
      width: '100% !important'
    },
  },
});

const MultiSelect = styled(Select)({
  '& .MuiOutlinedInput-root': {
    border: '2px solid red',  // Set your desired border color
    borderRadius: '4px',        // Optional: Add border radius for rounded corners
    '&:hover': {
      borderColor: 'red',      // Optional: Change border color on hover
    },
    '&.Mui-focused': {
      borderColor: 'red',   // Optional: Change border color when focused
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '10px 14px'
  },
});


const DateInput = ({
  dataTestId,
  onChange,
  defaultValue,
  id,
  inputProps,
  style,
  value,
  name,
  disabled,
}: {
  onChange: (e: { target: { value: string; name: string } }) => void;
  dataTestId?: string;
  defaultValue?: unknown;
  id?: string;
  value?: unknown;
  style?: CSSProperties;
  inputProps?: any;
  name?: string;
  disabled?: boolean;
}) => (
  <DateBox className="date_boxs" style={{ position: 'relative' }}>
    <InputBox
      variant="outlined"
      id={id}
      className="date_input"
      data-test-id={dataTestId}
      type="date"
      name={name}
      value={value}
      disabled={disabled}
      style={{ ...style, width: '100%', fontFamily: 'Inter' }}
      defaultValue={defaultValue}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={
        inputProps
          ? inputProps
          : {
              style: {
                borderBottom: 'none',
                height: 48,
              },
            }
      }
      onChange={onChange}
    />
    <DateRangeIcon
      fontSize={'inherit'}
      style={{
        position: 'absolute',
        right: 15,
        top: 'calc(50% - 10px)',
        color: 'black',
        pointerEvents: 'none'
      }}
    />
  </DateBox>
);

const DateBox = styled(Box)({
  '& .date_boxs': {
    display: 'flex',
    justifyContent: 'flex-start',

    '& .MuiSvgIcon-root': {
      height: '1.1em !important',
      width: '1.1em !important'
    },
    '& .date_input': {
      '& .MuiInputBase-root': {
        fontSize: '0.75rem',
        fontFamily: 'Inter'
      },
      '& input': {
        textTransform: 'uppercase',
        minWidth: '144.53px'
      },
      '& input::-moz-calendar-picker-indicator': {
        display: 'none',
        WebkitAppearance: 'none'
      },
    },
  },
});

const InputBox = styled(TextField)({
  '& .MuiOutlinedInput-input': { padding: '0px 14px' },
  '& .MuiInputBase-input': { height: '40px' },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#CBD5E1', // Default root border color
    },
    '&.Mui-focused fieldset': {
      borderColor: '#3F6474', // Border color when focused
    },
  },
});

export const webStyles: Record<string, CSSProperties> = {
  showDrawer: {
    width: `calc(100% - ${drawerWidth}px)`
  },
  resetBtn: {
    width: "150px",
    borderRadius: "3px",
    padding: "15px 0",
    fontSize: "0.875rem",
    fontWeight: 600,
    marginTop: "20px",
    backgroundColor: "#fcfcfc",
    border: "1px solid #81999f",
    color: "#81999f",
    display: "flex",
    justifyContent: "center",
    textTransform: "capitalize",
    alignItems: "centerd"
  },
  createBtn: {
    width: "150px",
    borderRadius: "3px",
    padding: " 15px 0",
    border: "none",
    color: "white",
    fontSize: "0.875rem",
    fontWeight: 600,
    backgroundColor: " #206575",
    marginTop: " 20px",
    display: "flex",
    justifyContent: "center",
    textTransform: "capitalize",
    alignItems: "centerd"
  },
  main: {
    display: "flex",
    columnGap: "15px",
    borderRadius: "5px",
    border: "1px solid #9F9F9F",
    background: "#FFF",
    padding: "10px",
    minWidth: "691px",
    minHeight: "110px"
  },
  left: {},
  rightMain: {
    width: "100%"
  },
  right1: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "0.938rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: " -0.022px",
    marginBottom: "2px"
  },
  right2: {
    color: "#8B8B8B",
    fontFamily: "Inter",
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-0.022px",
    marginBottom: "21px"
  },
  right3: {
    display: "flex",
    justifyContent: "space-between"
  },
  right3Left: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-0.022px"
  },
  right3Right: {
    display: "flex",
    columnGap: "5px"
  },
  deleteAddLearn: {
    width: "44px",
    height: "44px",
    borderRadius: "5px",
    border: "1px solid #FF5C00",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  resetAddMain: {
    display: "flex",
    justifyContent: "center",
    columnGap: "15px",
    marginBottom: "20px"
  },
  categoryImgName: {
    display: "flex",
    justifyContent: "center"
  },
  checkboxMain: {
    display: "flex",
    width: "100%",
    justifyContent: "center"
  },
  modalTitleHeadText: {
    color: "var(--Basic-Black, #000)",
    fontFamily: "Inter",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px"
  },
  modalTitle: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "32px"
  },
  bundleCourseText: {
    display: "flex",
    marginLeft: "40px",
    fontSize: "1.438rem",
    color: "black"
  },
  lineDivide: {
    height: "20px",
    display: "inline-block"
  },
  addMoreText: {
    color: "#1F6575",
    fontFamily: "Inter",
    fontSize: "0.625rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "17.5px"
  },
  tableDivide: {
    height: "10px"
  },
  addMoreDeleteIcon: { display: "flex", columnGap: "10px" },
  addMoreLearn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "130px",
    cursor: "default",
    fontSize: "0.875rem",
    columnGap: "10px",
    borderRadius: "3.977px",
    border: " 0.795px solid #1F6575",
    height: "50px"
  },
  addMoreReset: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "108px",
    border: "1px solid #1F6575",
    background: "var(--Basic-White, #FFF)"
  },
  addCourseTextModalBtn: {
    width: "180px",
    background: "#1F6575"
  },
  addCourseTextModal: {
    color: "var(--Basic-White, #FFF)",
    fontFamily: "Inter",
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px"
  },
  input: {
    width: "20px",
    height: "20px",
    border: "3px solid black",
    background: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  fullNameText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  imgNameMain: { display: "flex", marginLeft: "15px", columnGap: "10px" },
  editDeleteIcon: { background: "none", border: "none", cursor: "pointer" },
  tableActions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  bundleCourseMainLayout: {
    width: "97%",
    margin: "0 auto",
    maxWidth: "1024px"
  },
  adminLayout: { display: "flex", flexDirection: "column", gap: "2rem", marginTop:'20px' },
  adminLabelText: {
    color: "#192252",
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    display: "flex",
    alignItems: "center"
  },
  formErrorText: { fontSize: "0.625rem", color: "red" },
  inputField: {
    display: "grid",
    gridTemplateColumns: "11rem auto",
    paddingBottom: "20px"
  },
  fileLabel: {
    padding: "10px",
    alignItems: "center",
    border: "2px dashed #ccc",
    cursor: "pointer",
    width: "98%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center"
  }
};

// Customizable Area End
