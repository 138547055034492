// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData } from "framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { apiCall } from "../../couponcodegenerator/src/VouchercodegeneratorController";
import { elementsPerPage } from "../../../components/src/MainLayout.web";
import {
  ClassesDatum,
  CourseDetailData,
  CourseDetailResponse,
  CourseDetailsAttributes,
  RelatedCoursesResponse,
  SpecfifcContentDuration,
} from "./CourseDetailController.web";
import { CourseItem, OrderResponse } from "./MyCourseController.web";
import { ResponseErrors } from "../../user-profile-basic/src/AdminStateListController.web";
import React from "react";

export const configJSON = require("./config");
export interface Props {
  // Customizable Area Start
  navigation: {
    getParam: (param: string) => string;
    navigate: (value: string) => void;
  };
  id: string;
  // Customizable Area End
}

interface AssignmentObj{
  id: string,
  title: string, 
  downloadUrl: string
}

interface VideoCipherResponse {
  meta: {
    vdocipher_video_id: string,
    vdocipher_otp: string,
    playback_info: string,
    video_url: string
  }
}

export interface TestimonialResponse {
  "id": string,
  "type": string,
  "attributes": {
    "id": number,
    "rating": number,
    "comment": string,
    "email": string,
    "name": string,
    "contact": string,
    "status": boolean,
    "serial_number": number,
    "photo": string,
    "user_name": {
      "id": number,
      "full_name": string,
      "email": string,
      "full_phone_number": string,
    },
    "course_details": {
      "id": number,
      "course_name": string,
      "tag_type": string,
    }
  }
}

interface TestimonialsListResponse {
  "data": TestimonialResponse[]
}

export interface TestimonialsAverageRatingResponse {
  "total_average_rating": number,
  "rated_user_count": number,
  "one_rating": number,
  "two_rating": number,
  "three_rating": number,
  "four_rating": number,
  "five_rating": number,
}

interface S {
  // Customizable Area Start
  userToken: string;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  isLoading: boolean;
  searchText: string;
  coursesList: CourseItem[];
  courseId: string;
  currentCourse: string;
  page: number;
  perPage: number;
  courseType: string;
  courseDetail: CourseDetailsAttributes | undefined;
  currentTab: number;
  tabs: unknown[];
  relatedCourses: CourseDetailData[];
  isDrawerOpen: boolean;
  playlist: ClassesDatum[];
  chapterId: string;
  classId: string;
  chapterDetails: SpecfifcContentDuration | undefined;
  classDetails: ClassesDatum | undefined;
  openedAccordions: number[];
  assigmentList: AssignmentObj[];
  videoCipharUrlData: VideoCipherResponse;
  courseRating: number;
  courseReviewText: string;
  ReviewAndRatingError: string;
  testimonialsList: TestimonialsListResponse;
  testimonialsAverageRating : TestimonialsAverageRatingResponse | undefined;
  userTestimonial: TestimonialResponse | undefined;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

declare global {
  interface Window {
    VdoPlayer: {
      getInstance: (iframe: HTMLIFrameElement) => {
        video: HTMLVideoElement;
        api: {
          getTotalPlayed: () => Promise<number>;
        };
      };
    };
  }
}

export default class MyCourseController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCoursesApiCallId: string = "";
  getRelatedCoursesApiCallId: string = "";
  getCourseDetailApiCallId: string = "";
  getVideoCipherUrlApiCallId: string = "";
  updateVideoTimeApiCallId: string = "";
  updateChapterTimeApiCallId: string = "";
  updateCourseTimeApiCallId: string = "";
  getTestimonialsListApiCallId: string = "";
  createReviewSubmitApiCallId: string = "";
  getTestimonialsAverageRatingApiCallId: string = "";
  
  iframeRef: React.RefObject<HTMLIFrameElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage),
    ];

    this.iframeRef = React.createRef();

    this.state = {
      page: 1,
      perPage: elementsPerPage,
      alertMsg: "",
      searchText: "",
      alertType: "success",
      isAlert: false,
      isLoading: false,
      userToken: "",
      coursesList: [],
      courseId: "",
      currentCourse: "",
      relatedCourses: [],
      currentTab: 0,
      tabs: [],
      courseType: "course",
      courseDetail: undefined,
      isDrawerOpen: true,
      playlist: [],
      chapterId: "",
      classId: "",
      chapterDetails: undefined,
      classDetails: undefined,
      openedAccordions: [],
      assigmentList:[],
      videoCipharUrlData: {meta: {playback_info: "", vdocipher_otp: "", vdocipher_video_id: "", video_url: ""}},
      courseRating: 0,
      courseReviewText: "",
      ReviewAndRatingError: "",
      testimonialsList: { data: [] },
      userTestimonial: undefined,
      testimonialsAverageRating: undefined
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.handleUserToken();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getCoursesApiCallId:
            this.handleGetCoursesResponse(responseJson);
            break;
          case this.getCourseDetailApiCallId:
            this.handleGetDetailsResponse(responseJson);
            break;
          case this.getRelatedCoursesApiCallId:
            this.handleGetRelatedCoursesResponse(responseJson);
            break;
          case this.getVideoCipherUrlApiCallId: 
            this.handleGetVideoCipherUrlResponse(responseJson);
            break;
          case this.getTestimonialsListApiCallId:
            this.handleGetTestimonialsListResponse(responseJson)
            break;
          case this.createReviewSubmitApiCallId:
            this.handleCreateReviewResponse(responseJson);
            break;
          case this.getTestimonialsAverageRatingApiCallId:
            this.handleGetTestimonialsAverageRatingResponse(responseJson);
            break;
        }
      } else {
        this.parseErrors(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentWillUnmount() {
    await removeStorageData("write_review");
  }
  async handleUserToken() {
    const writeReview = await getStorageData("write_review");
    if(writeReview != null){
      this.setState({
        currentTab: +writeReview
      })
    }
    const isDrawerOpen = await getStorageData("isCourseDrawerOpen");
    const token = await getStorageData("loginToken");
    const id = this.props.navigation.getParam("id");
    const currentCourse = this.props.navigation.getParam("courseId");
    const currentChapter = this.props.navigation.getParam("chapterId");
    const currentClass = this.props.navigation.getParam("classId");
    if (token && id) {
      this.setState({
        userToken: token,
        courseId: id,
        currentCourse: currentCourse,
        chapterId: currentChapter,
        classId: currentClass,
        isDrawerOpen: isDrawerOpen === "true",
      });
      this.getCourseDetail(currentCourse);
      this.getCourses();
    } else {
      this.navigationLoginScreen();
    }
  }

  parseErrors = async (responseJson: ResponseErrors) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } else if (typeof(responseJson.errors) === "string") {
      this.setState({
        isAlert: true,
        alertMsg: responseJson.errors,
        alertType: "error"
      })
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  convertToHoursAndMinutes(decimalMin: number) {
    const hours = Math.floor(decimalMin / 60);
    const minutes = decimalMin % 60;

    return hours ? `${hours} Hours, ${minutes} Minutes` : `${minutes} Minutes`
  }

  convertMinutesToTimeFormat(minutes: string) {
    const hours = Math.floor(parseFloat(minutes) / 60);
    const mins = Math.round(parseFloat(minutes) % 60);

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(mins).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}`;
  }

  handleExpandAllAccordions(indexArray: number[]) {
    this.setState({
      openedAccordions:
        this.state.openedAccordions.length === indexArray.length
          ? []
          : indexArray
    });
  }

 handleAccordionChange(index: number) {
    const array = [...this.state.openedAccordions],
      findAccordion = array.findIndex(v => index === v);
    if (findAccordion > -1) {
      this.setState({
        openedAccordions: [
          ...this.state.openedAccordions.filter(v => v !== index)
        ]
      });
    } else {
      this.setState({
        openedAccordions: [...this.state.openedAccordions, index]
      });
    }
  }

  navigationLoginScreen = () => {
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };

  getCourses() {
    this.setState({ isLoading: true });
    this.getCoursesApiCallId = apiCall({
      endPoint:
        configJSON.getAllCoursesApiEndpoint +
        `?search=${this.state.searchText}`,
      method: configJSON.getMethod,
      token: this.state.userToken,
      contentType: configJSON.apiContentType,
    });
  }

  oncloseAlert() {
    this.setState({
      isAlert: false,
    });
  }

  navigationHomeScreen = () => {
    const messageHome: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    messageHome.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageHome);
  };

  handleGetCoursesResponse(responseJson: OrderResponse) {
    if (responseJson && responseJson.data) {
      this.setState({
        coursesList: [...responseJson.data].sort((a, b) => Number(b.is_active_course) - Number(a.is_active_course)),
        isLoading: false,
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  checkCourseDisable = (responseJson: CourseDetailResponse) => {
    if (responseJson && responseJson.message) {
      this.navigationHomeScreen();
    }
  }

  handleGetDetailsResponse(responseJson: CourseDetailResponse) {
    this.checkCourseDisable(responseJson)
    if (responseJson && responseJson.data) {
      const sortedSpecfifc_content_duration = this.sortArray(responseJson.data.attributes.specfifc_content_duration) as SpecfifcContentDuration[]
      const firstSpecfifc_content_duration = sortedSpecfifc_content_duration.find(data => data.classes_data.some(classes => classes.course_class_progress === 0)) || sortedSpecfifc_content_duration[0]
      const sortedClasses_data = this.sortArray(firstSpecfifc_content_duration.classes_data) as ClassesDatum[]
      const firstClasses_data = sortedClasses_data.find(data => data.course_class_progress === 0) || sortedClasses_data[0]
      let classId = this.state.classId,
        chapterId = this.state.chapterId;
      if (!classId) {
        this.setState({
          classId: `${firstClasses_data.id}`,
        });
        classId = `${firstClasses_data.id}`;
      }
      if (!chapterId) {
        this.setState({
          chapterId: `${firstSpecfifc_content_duration.id}`,
        });
        chapterId = `${firstSpecfifc_content_duration.id}`;
      }
      const playlist = sortedSpecfifc_content_duration.find(
          (v) => v.id === parseInt(chapterId)
        ),
        classDetails = playlist?.classes_data.find(
          (v) => v.id === parseInt(classId)
        );
        this.getVideoCipherUrl(classId)
      this.setState(
        {
          courseType: responseJson.data.type,
          courseDetail: { ...responseJson.data.attributes, specfifc_content_duration: sortedSpecfifc_content_duration },
          isLoading: false,
          playlist: playlist?.classes_data || [],
          chapterDetails: playlist,
          classDetails: classDetails,
          assigmentList: sortedSpecfifc_content_duration.filter(data => !!data.course_learning_material).map(data => ({ id: data.id.toString(), title: data.name, downloadUrl: data.course_learning_material}))
        },
        () => {
          this.getRelatedCourse()
          this.getTestimonialsList()
          if (!this.state.assigmentList.length && this.state.currentTab === 3) {
            this.setState({
              currentTab: 2
            })
          }
        }
      );
    } else {
      this.setState({ isLoading: false });
    }
  }

  getCourseDetail(courseId: string) {
    this.setState({ isLoading: true });
    this.getCourseDetailApiCallId = apiCall({
      endPoint: configJSON.getCourseDetailApiEndpoint + courseId,
      method: configJSON.getMethod,
      token: this.state.userToken,
    });
  }

  getVideoCipherUrl(classId: string) {
    this.getVideoCipherUrlApiCallId = apiCall({
      endPoint: configJSON.getCourseClassApiEndpoint + classId,
      method: configJSON.getMethod,
      token: this.state.userToken,
    });
  }

  handleGetVideoCipherUrlResponse = (responseJson: VideoCipherResponse) => {
    this.setState({ videoCipharUrlData: responseJson }, () => {
      const script = document.createElement('script');
    script.src = 'https://player.vdocipher.com/v2/api.js';
    script.async = true;
    script.onload = () => {
      this.initializePlayer();
    };
    document.body.appendChild(script);
    })
  }

  getTestimonialsList = () => {
    this.getTestimonialsListApiCallId = apiCall({
      endPoint: configJSON.getTestimonialsListApiEndpoint + this.state.currentCourse,
      method: configJSON.getMethod,
      token: this.state.userToken,
    });
  }

  handleGetTestimonialsListResponse = async (responseJson: TestimonialsListResponse) => {
    if (responseJson.data) {
      const userId = Number(await getStorageData("profileUserId"))
      this.setState({ 
        testimonialsList: responseJson, 
        userTestimonial : responseJson.data.find(response => response.attributes.user_name.id === userId),
      })
      this.getTestimonialsAverageRatingApiCallId = apiCall({
        endPoint: configJSON.getTestimonialsAverageRatingApiEndpoint + this.state.currentCourse,
        method: configJSON.getMethod,
        token: this.state.userToken,
      });
    }
  }

  handleGetTestimonialsAverageRatingResponse = (responseJson: TestimonialsAverageRatingResponse) => {
    this.setState({
      testimonialsAverageRating: responseJson
    })
  }

  handleOnReviewSubmit = async () => {
    if (!this.state.courseRating || !this.state.courseReviewText) {
      this.setState({ ReviewAndRatingError: "Please fill the review." })
      return
    }
    this.createReviewSubmitApiCallId = apiCall({
      endPoint: configJSON.createTestimonialApiEndpoint,
      method: configJSON.postMethod,
      contentType: configJSON.apiContentType,
      token: this.state.userToken,
      body: {
        name: await getStorageData("profileUsername"),
        course_id: this.state.currentCourse,
        rating: this.state.courseRating,
        comment: this.state.courseReviewText
      }
    });
  }

  handleCreateReviewResponse = (responseJson: { data: TestimonialResponse }) => {
    if (responseJson && responseJson.data) {
      this.setState({
        isAlert: true,
        alertMsg: "Review submitted successfully",
        alertType: "success"
      })
      this.getTestimonialsList()
    }
  }

  getRelatedCourse() {
    this.setState({ isLoading: true });
    this.getRelatedCoursesApiCallId = apiCall({
      endPoint:
        configJSON.getRelatedCoursesApiEndpoint +
        `?course_id=${this.state.currentCourse}&course_child_category_id=${
          this.state.courseDetail!.course_child_category.id
        }`,
      method: configJSON.getMethod,
      token: this.state.userToken,
    });
  }

  handleGetRelatedCoursesResponse(responseJson: RelatedCoursesResponse) {
    if (responseJson && responseJson.data) {
      this.setState({
        relatedCourses: responseJson.data,
        isLoading: false,
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  initializePlayer() {
    const iframe = this.iframeRef.current;
    if (iframe) {
      const player = window['VdoPlayer'].getInstance(iframe);

      player.video.addEventListener('play', () => {
        this.onVideoTimeChange(player.video.currentTime + 1)
      });

      player.video.addEventListener('pause', () => {
        this.onVideoTimeChange(player.video.currentTime)
      });

      player.video.addEventListener('ended', () => {
        this.onVideoTimeChange(Number(this.state.classDetails?.duration) * 60)
      });
    }
  }

  onVideoTimeChange = (timer: number) => {
    this.setState({
      playlist: this.state.playlist.map(play => play.id === Number(this.state.classId) && !play.course_class_progress ? { ...play, course_class_progress: 1 } : play)
    })
    this.updateVideoTimeApiCallId = apiCall({
      endPoint: configJSON.updateVideoTime,
      method: configJSON.postMethod,
      contentType: configJSON.apiContentType,
      token: this.state.userToken,
      body: {
        chapter_id: Number(this.state.chapterId),
        class_id: Number(this.state.classId),
        duration: timer * 1000
      }
    });

    const isChapterUpdate = this.state.chapterDetails?.classes_data.every((data) => data.course_class_progress !== 0 || data.id === Number(this.state.classId))

    if (isChapterUpdate) {
      this.updateChapterTimeApiCallId = apiCall({
        endPoint: configJSON.updateChapterTime,
        method: configJSON.postMethod,
        contentType: configJSON.apiContentType,
        token: this.state.userToken,
        body: {
          chapter_id: Number(this.state.chapterId),
          course_id: Number(this.state.currentCourse),
        }
      });
    }

    const isCourseUpdate = this.state.courseDetail?.specfifc_content_duration.every(content => content.classes_data.every((data) => data.course_class_progress !== 0 || data.id === Number(this.state.classId)))

    if (isCourseUpdate) {
      this.updateCourseTimeApiCallId = apiCall({
        endPoint: configJSON.updateCourseTime,
        method: configJSON.postMethod,
        contentType: configJSON.apiContentType,
        token: this.state.userToken,
        body: {
          course_id: Number(this.state.currentCourse),
        }
      });
    }
  }

  sortArray = (array: ClassesDatum[] | SpecfifcContentDuration[]) => {
    return [...array].sort((a, b) => a.sequence_number - b.sequence_number)
  }

  conditionalIfElse = (condition: boolean, ifValue: number | string, elseValue: number | string | undefined) => {
    return condition ? ifValue : elseValue
  }

  handleSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: event.target.value },()=>{
      if(this.state.searchText == ""){
        this.getCourses()
      }
    });
  }

  copyTextToClipboard = async () => {
    try {
      const textToCopy = "URL copied to clipboard.";
      await navigator.clipboard.writeText(`${window.location.origin}/course/${this.state.currentCourse}` || "");
      this.setState({
        isAlert: true,
        alertMsg: textToCopy,
        alertType: "success"
      });
    } catch (error) {
      this.setState({
        isAlert: true,
        alertMsg: "Can't copy code to clipboard",
        alertType: "error"
      });
    }
  }

  disableLink = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, isCurrent: boolean, is_active_course: boolean) => {
    if (isCurrent || !is_active_course) {
      event.preventDefault();
    }
  }

  downloadPdf = (pdfUrl: string) => {
    window.open(pdfUrl)
  }

  handleRating = (rating: number | null) => {
    this.setState({ courseRating: rating || 0 });
  };
  
  handleCourseReviewText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length <= 225) {
      this.setState({ courseReviewText: event.target.value })
    }
  }

  getCourseValue(value1:string | number | JSX.Element, value2:string | number | JSX.Element) {
    const isBundleCourse = this.state.courseType === "bundle_course";
    return isBundleCourse ? value1 : value2
  }

  // Customizable Area End
}
// Customizable Area End
