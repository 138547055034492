Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.apiContentType = 'application/json'
exports.getApiMethod = 'GET'
exports.postApiMethod = 'POST'
exports.deleteApiMethod = 'DELETE'
exports.putApiMethod = "PUT"

exports.getOrdersApiEndPoint = 'shopping_cart/orders'
exports.createOrderItemApiEndPoint = 'shopping_cart/order_items'
exports.deleteOrderItemApiEndPoint = 'shopping_cart/orders'

exports.errorTitle = 'Error'
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.'

exports.shoppingCartTitle = "My Shopping Cart";
exports.shoppingCartSubTitle = (num) => `Your current shopping cart contains ${num} items`;
exports.noteInformation = "Kindly check the Course Validation before Checkout";
exports.deleteBtn = "Delete";
exports.continueShoppingBtn = "CONTINUE SHOPPING";
exports.orderDetailsTitle = "Order Summary";
exports.doYouHaveMsg = "Do you have a discount code?";
exports.applyDiscount = "APPLY DISCOUNT";
exports.cancelBtn = "Cancel";
exports.applyBtn = "Apply";
exports.checkoutBtn = "CHECKOUT";
exports.helpText = "NEED HELP? CALL ";

exports.updatePriceBaseOnYear = "bx_block_shopping_cart/update_price";
exports.getShoppingCartApiEndPoint = "/bx_block_shopping_cart/view_cart_items";
exports.applyCouponApiEndPoint = "/bx_block_shopping_cart/apply_coupon_code";
exports.removeCouponApiEndPoint = "/bx_block_shopping_cart/remove_apply_coupon";
exports.deleteCartItemApiEndPoint = "/bx_block_shopping_cart/remove_course_from_cart";
exports.applyVoucherCodeApiEndPoint = "/bx_block_shopping_cart/apply_voucher_code";
exports.applyFlatDiscountApiEndPoint = "/bx_block_shopping_cart/apply_flat_discount";
exports.applyRefferalPointApiEndPoint = "/bx_block_shopping_cart/apply_referral";
exports.removeRefferalPointApiEndPoint = "/bx_block_shopping_cart/remove_referral";
exports.removeVoucherCodeApiEndPoint = "/bx_block_shopping_cart/remove_voucher_code";
exports.removeFlatDiscountApiEndPoint = "/bx_block_shopping_cart/remove_flat_discount";

// Customizable Area End
