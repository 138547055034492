import React from "react";

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";
import { Color } from "@material-ui/lab/Alert";
import { parse } from "json2csv";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import { elementsPerPage } from "../../../components/src/MainLayout.web";
export const configJSON = require("./config");
const countryCode = "91";
const studentRegex =
  /^(?=.*[a-zA-Z])[a-zA-Z0-9~@#$^*()_+=[\]{}|\\,.?: -]{1,30}$/;
const fullNameRegex = /^[a-zA-Z\s]{3,}$/;

export interface DistrictListResponse {
  data: District[];
}

interface ResponseErrors {
  errors: {
    token: string;
  }[];
}
export interface District {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  id: number;
  name: string;
  state: any;
  created_on: string;
  updated_on: string;
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface InstructorData{
  instructorName: string;
  instructorGender: string;
  instructorDOB: any;
  instructorQualifification: string;
  instructorLectures: string;
  instructorDetails: string;
  instructorShortDetails: string;
  instructorEmail: string;
  instructorMobile: string;
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  isVisible: boolean;
  apiToken: any;
  newValue: string;
  educationQualification: string[];
  projectList: string[];
  modalProject: any;
  awardList: any;
  patentList: any;
  loadingEQ: boolean;
  activeTab: number;
  loadingProject: boolean;
  loadingAwards: boolean;
  loadingPub: boolean;
  isModalOpen: boolean;
  modalItem: any;
  studentPerPage: number;
  studentPageNo: number;
  instructorPerPage: number;
  instructorPageNo: number;
  adminPageNo: number;
  adminPerPage: number;
  userToken: string;
  studentData: any;
  studentColHeader: string[];
  instructorColHeader: string[];
  adminColHeader: string[];
  isChecked: boolean[];
  isHeaderChecked: boolean;
  isAddStudent: boolean;
  selectedFile: any;
  previewThumbnail: any;
  fullNameErr: string;
  fullName: string;
  gender: string;
  genderErr: string;
  schoolName: string;
  schoolNameErr: string;
  date: any;
  dobErr: string;
  phone: string;
  phoneErr: string;
  Email: string;
  emailErr: string;
  stateErr: string;
  state: string;
  stateList: any[];
  districtList: District[];
  District: string;
  districtErr: string;
  stateId: string;
  fileErr: string;
  selectedFileName: string;
  hasError: boolean;
  isActivated: boolean;
  stateName: string;
  districtName: string;
  isDeleteModalOpen: boolean;
  studentId: number;
  isStudentEdit: boolean;
  studentDataEdit: any;
  searchText: string;
  fromDate: string;
  toDate: string;
  currentSortState: { order: string; field: string };
  isAddInstructor: boolean;
  isEditInstructor: boolean;
  instructorId: string;
  instructorData: any;
  isInstructorDeleteModalOpen: boolean;
  showDOB: boolean;
  instructorDataEdit: any;
  adminData: any;
  adminTotalPage: number;
  calenderOpen: boolean;
  errorMessage: string;
  studentPageCount: any;
  instructorPageCount: any;
  isAdminAdd: boolean;
  isAdminEdit: boolean;
  instructorFormData: {
    instructorName: string;
    instructorGender: string;
    instructorDOB: any;
    instructorQualifification: string;
    instructorLectures: string;
    instructorDetails: string;
    instructorShortDetails: string;
    instructorEmail: string;
    instructorMobile: string;
  };
  instructorFormDataError: {
    instructorName: string;
    instructorGender: string;
    instructorDOB: any;
    instructorQualifification: string;
    instructorLectures: string;
    instructorDetails: string;
    instructorEmail: string;
    instructorMobile: string;
  };
  errors: Record<string, string>;
  isAdminDeleteModal: boolean;
  adminFormData: {
    adminName: string;
    adminGender: string;
    adminDOB: any;
    adminEmail: string;
    adminMobile: string;
  };
  adminFormErrors: Record<string, string>;
  adminId: string;
  studentFromDate: string;
  studentToDate: string;
  no_of_courses:any;
  columnSort: string;
  selectedLink:string;
  openDeleteStudentModal: boolean,
  isAdminHeaderChecked: boolean,
  openDeleteAdminModal: boolean,
  isAdminDelete: boolean,
  profileEmail: string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EducationalUserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  labelTitle: string = "";
  getEducationCallId: string = "";
  getProjectCallId: string = "";
  getAwardsCallId: string = "";
  getPatentCallId: string = "";
  getStudentDataApiCallId: any;
  exportStudentCSVApiCallId: any;
  apiStateCallId: any;
  apiCityCallId: any;
  addStudentAPICallID: any;
  editStudentAPICallID: any;
  toggleStatusAPICallID: string = "";
  deleteStudentAPICallId: any;
  getInstructorApiCallId: any;
  deleteInstructorApiCallId: any;
  addInstructorApiCallId: any;
  editInstructorApiCallId: any;
  getSuperAdminDataApiCallId: any;
  addAdminAPICallId: any;
  updateAdminApiCallId: any;
  deleteAdminApiCallId: any;
  bulkDeleteAdminAPICallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      isLoading: false,
      isAlert: false,
      alertMsg: "",
      alertType: "success",
      isVisible: false,
      apiToken: null,
      newValue: "1",
      educationQualification: [],
      projectList: [],
      modalProject: [],
      awardList: [],
      patentList: [],
      loadingEQ: true,
      activeTab: 1,
      loadingProject: true,
      loadingAwards: true,
      loadingPub: true,
      isModalOpen: false,
      modalItem: null,
      showDOB: true,
      studentPerPage: elementsPerPage,
      studentPageNo: 1,
      instructorPerPage: elementsPerPage,
      instructorPageNo: 1,
      userToken: "",
      studentData: [],
      no_of_courses:0,
      studentColHeader: [
        "S.no",
        "Student Name",
        "Mobile",
        "Email",
        "Gender / DOB",
        "Signup Time",
        "Nos of Purchased Courses",
        "Device name",
        "Status",
        "Action",
      ],
      isChecked: [],
      isAdminDelete: false,
      profileEmail: '',
      isHeaderChecked: false,
      isAdminHeaderChecked:false,
      openDeleteAdminModal: false,
      isAddStudent: false,
      selectedFile: null,
      fullName: "",
      fullNameErr: "",
      gender: "",
      genderErr: "",
      schoolName: "",
      schoolNameErr: "",
      date: "",
      dobErr: "",
      calenderOpen: false,
      phone: "",
      phoneErr: "",
      emailErr: "",
      Email: "",
      state: "",
      stateErr: "",
      stateList: [],
      districtList: [],
      District: "",
      districtErr: "",
      stateId: "",
      fileErr: "",
      selectedFileName: "",
      previewThumbnail: "",
      hasError: false,
      isActivated: true,
      stateName: "",
      districtName: "",
      isDeleteModalOpen: false,
      studentId: 0,
      isStudentEdit: false,
      studentDataEdit: {},
      searchText: "",
      instructorColHeader: [
        "S.No",
        "Instructor Name",
        "Mobile",
        "Email",
        "Teaching",
        "Status",
        "Action",
      ],
      adminColHeader: [
        "S.No",
        "Admin Name",
        "Mobile",
        "Email",
        "Created On",
        "Last Login",
        "Last Logout",
        "Status",
        "Action",
      ],
      fromDate: "",
      toDate: "",
      currentSortState: { order: "desc", field: "column_id_sort" },
      isAddInstructor: false,
      isEditInstructor: false,
      isInstructorDeleteModalOpen: false,
      instructorId: "",
      instructorData: [],
      studentPageCount: 0,
      instructorPageCount: 0,
      instructorDataEdit: [],
      adminPageNo: 1,
      adminPerPage: elementsPerPage,
      instructorFormData: {
        instructorName: "",
        instructorGender: "",
        instructorDOB: "",
        instructorQualifification: "",
        instructorLectures: "",
        instructorDetails: "",
        instructorShortDetails: "",
        instructorEmail: "",
        instructorMobile: "",
      },
      instructorFormDataError: {
        instructorName: "",
        instructorGender: "",
        instructorDOB: "",
        instructorQualifification: "",
        instructorLectures: "",
        instructorDetails: "",
        instructorEmail: "",
        instructorMobile: "",
      },
      errorMessage: "",
      errors: {},
      isAdminAdd: false,
      isAdminEdit: false,
      adminTotalPage: 0,
      adminData: [],
      isAdminDeleteModal: false,
      adminFormData: {
        adminName: "",
        adminDOB: "",
        adminEmail: "",
        adminMobile: "",
        adminGender: "",
      },
      columnSort: "",
      adminFormErrors: {},
      adminId: "",
      studentFromDate: "",
      studentToDate: "",
      selectedLink:'',
      openDeleteStudentModal: false
    };

    this.labelTitle = configJSON.labelTitle;

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    const profileEmail=await getStorageData('profileEmail')
    const selectedLink=await getStorageData('selectedSubLink')
    this.setState({selectedLink:selectedLink, profileEmail: profileEmail})
    getStorageData("loginToken").then((value) => {
      this.setState({ userToken: value });
      this.getTableData(value);
    });
    this.getAllStates();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      if (token) {
        this.setState({ apiToken: token }, () => {
          this.getEducationQualification();
          this.getProjectList();
          this.getAwardList();
          this.getPatentList();
        });
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getEducationCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (resJson?.errors?.length > 0) {
        this.showAlert("Error", resJson?.errors[0]?.token);
      } else {
        this.setState({
          educationQualification: resJson?.data,
          loadingEQ: false,
        });
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProjectCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (resJson?.errors?.length > 0) {
        this.showAlert("Error", resJson?.errors[0]?.token);
      } else {
        this.setState({ projectList: resJson?.data, loadingProject: false });
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAwardsCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (resJson?.errors?.length > 0) {
        this.showAlert("Error", resJson?.errors[0]?.token);
      } else {
        this.setState({ awardList: resJson?.data, loadingAwards: false });
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getPatentCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (resJson?.errors?.length > 0) {
        this.showAlert("Error", resJson?.errors[0]?.token);
      } else {
        this.setState({ patentList: resJson?.data, loadingPub: false });
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiRequestCallId) {
        case this.getStudentDataApiCallId:
          this.handleGetStudentData(responseJson);
          break;
        case this.exportStudentCSVApiCallId:
          break;
        case this.apiStateCallId:
          this.setState({
            stateList: responseJson,
            isLoading: false,
          });
          break;
        case this.apiCityCallId:
          this.handleGetCityResponse(responseJson);
          break;
        case this.addStudentAPICallID:
          this.handleAddStudentResponse(responseJson);
          break;
        case this.deleteStudentAPICallId:
            this.deleteStudentAPIResponse(responseJson);
          break;
        case this.editStudentAPICallID:
          this.handleEditStudentAPIResponse(responseJson);
          break;
        case this.addInstructorApiCallId:
          this.handleAddInstructorAPIResponse(responseJson);
          break;
        case this.toggleStatusAPICallID:
            this.handleToggleStatusAPIResponse(responseJson);
          break;
        case this.editInstructorApiCallId:
          this.editInstructorAPIResponse(responseJson);
          break;
        case this.getInstructorApiCallId:
          this.handleGetInstructorData(responseJson);
          break;
        case this.deleteInstructorApiCallId:
          this.deleteInstructorAPIResponse(responseJson);
          break;
        case this.getSuperAdminDataApiCallId:
          this.handleAdminDataAPIResponse(responseJson);
          break;
        case this.addAdminAPICallId:
          this.handleAdminCreateApiRespnse(responseJson);
          break;
        case this.updateAdminApiCallId:
          this.updateAdminApiResponse(responseJson);
          break;
        case this.deleteAdminApiCallId:
          this.deleteAdminApiResponse(responseJson);
          break;
        case this.bulkDeleteAdminAPICallId:
          this.bulkDeleteAdminApiResponse(responseJson);
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async getTableData(value?: any) {
    let currentPath = window.location.href;
    if(currentPath.includes("EducationalUserProfile")){
    this.getStudentData(
      this.state.studentPageNo,
      this.state.studentPerPage,
      value || this.state.userToken,
      this.state.searchText,
      this.state.columnSort
    );
  }
  if(currentPath.includes("InstructorUserProfile")){
    this.getInstructorData(
      this.state.instructorPageNo,
      this.state.instructorPerPage,
      value || this.state.userToken,
      this.state.searchText,
      this.state.columnSort
    );
  }
  if(currentPath.includes("AdminProfile")){
    this.getAdminData(
      this.state.adminPageNo,
      this.state.adminPerPage,
      value || this.state.userToken,
      this.state.searchText,
      this.state.columnSort
    );
  }
  }

  async getEducationQualification() {
    this.setState({ isLoading: true });
    const header = {
      token: this.state.apiToken,
      "Content-Type": configJSON.getEducationContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEducationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEducationApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getEducationApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getProjectList() {
    this.setState({ isLoading: true });
    const header = {
      token: this.state.apiToken,
      "Content-Type": configJSON.getProjectContextType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProjectCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProjectApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getProjectApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getAwardList() {
    this.setState({ isLoading: true });
    const header = {
      token: this.state.apiToken,
      "Content-Type": configJSON.getAwardsContextType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAwardsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAwardsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAwardsApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getPatentList() {
    this.setState({ isLoading: true });
    const header = {
      token: this.state.apiToken,
      "Content-Type": configJSON.getPatentsContextType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPatentCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPatentsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPatentsApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  showModal = (modalProject: any) => {
    this.setState({ modalProject: modalProject });
    this.setState({ isVisible: !this.state.isVisible });
  };

  handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    this.setState({
      newValue: newValue,
    });
  };

  handleMobileModalClose = () => {
    this.setState({
      isModalOpen: false,
      modalItem: null,
    });
  };

  isResponseObject(obj: any): obj is { error: string } {
    return typeof obj === "object" && "error" in obj;
  }

  handleGetCityResponse(
    responseJson: { error: string } | DistrictListResponse
  ) {
    if (this.isResponseObject(responseJson)) {
      this.setState({ districtList: [], isLoading: false });
    } else {
      this.setState({
        districtList: responseJson.data,
        isLoading: false,
      });
    }
  }

  getStudentData(
    pageNo: number,
    perPage: number,
    token: any,
    search: string,
    sort: string
  ) {
    this.setState({ isLoading: true });
    const date1 = new Date(this.state.studentFromDate),
      date2 = new Date(this.state.studentToDate);
    const dateString =
      this.state.studentFromDate && this.compareDates(date1, date2)
        ? `&start_date=${this.state.studentFromDate}&end_date=${this.state.studentToDate}`
        : "";
    const sortString = sort ? `&${sort}` : "";
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessageStudent = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStudentDataApiCallId = requestMessageStudent.messageId;
    requestMessageStudent.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.filterDataAPIEndPoint +
        `?page=${pageNo}&per_page=${perPage}&role_name=Student&search_params=${
          search || ""
        }${sortString}${dateString}`
    );

    requestMessageStudent.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageStudent.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessageStudent.id, requestMessageStudent);
  }

  deleteStudentAPIResponse(responseJson: any) {
    this.setState({
      isAlert: this.state.no_of_courses !== 0,
      alertType: "error",
      alertMsg: responseJson.error || '',
      isDeleteModalOpen: !responseJson.error,
      isLoading: !responseJson.error,
    });

    if(responseJson.errors && responseJson.errors.length > 0 && Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen()
    }
    if (responseJson.message == "Account was successfully destroyed.") {
      this.setState({ isDeleteModalOpen: false }, () =>
        this.setState({
          isAlert: true,
          alertMsg: "Account was successfully destroyed",
          alertType: "success",
          isLoading: false,
        })
      );
      this.getStudentData(
        this.state.studentPageNo,
        this.state.studentPerPage,
        this.state.userToken,
        this.state.searchText,
        this.state.columnSort
      );
    }
  }

  handleEditStudentAPIResponse(responseJson: any) {
    if(responseJson.errors && responseJson.errors.length > 0 && Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen()
    }
    if (
      responseJson?.meta?.message ==
      configJSON.studentAccountStatusUpdatedMessage
    ) {
      this.setState({
        isStudentEdit: false,
        isLoading: false,
        alertMsg: configJSON.studentAccountStatusUpdatedMessage,
        alertType: "success",
        isAlert: true,
      });
      this.handleStudentDataReset();
      this.getStudentData(
        this.state.studentPageNo,
        this.state.studentPerPage,
        this.state.userToken,
        this.state.searchText,
        this.state.columnSort
      );
    }
    if (responseJson.data && !responseJson.data.attributes) {
      const baseErrors = responseJson.data.base?.join(", ");
      const profileIconErrors = responseJson.data.profile_icon?.join(", ");
      this.setState({
        isLoading: false,
        isAlert: true,
        alertType: "error",
        alertMsg: baseErrors || profileIconErrors,
      });
    }
  }

  deleteStudentData(id: any, totalCourses:any) {
    this.setState({ isLoading: true , no_of_courses:totalCourses});
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.userToken,
    };
    const requestMessageStudent = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteStudentAPICallId = requestMessageStudent.messageId;
    requestMessageStudent.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteStudentApiEndPoint + `/${id}`
    );

    requestMessageStudent.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageStudent.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );
    runEngine.sendMessage(requestMessageStudent.id, requestMessageStudent);
  }

  handleHeaderCheckboxChange = () => {
    let currentPath = window.location.href;
    if(currentPath.includes("EducationalUserProfile")){
    let studentArray = this.state.studentData;
    for (const element of studentArray) {
        element.isChecked= !this.state.isHeaderChecked
    }
    this.setState((prevState) => ({
      isHeaderChecked: !prevState.isHeaderChecked,
      isChecked: Array(this.state.studentData.length).fill(
        !prevState.isHeaderChecked
      ),
      studentData: studentArray,
    }));
  }
  if(currentPath.includes("AdminProfile")){
    let adminDataArray = this.state.adminData;
    for (const element of adminDataArray) {
      if(this.state.profileEmail != element.attributes.email){
        element.isChecked= !this.state.isAdminHeaderChecked
      }
    }
    this.setState((prevState) => ({
      isAdminHeaderChecked: !prevState.isAdminHeaderChecked,
      isChecked: Array(this.state.adminData.length).fill(
        !prevState.isAdminHeaderChecked
      ),
      studentData: adminDataArray,
      isAdminDelete: !prevState.isAdminHeaderChecked
    }));
  }
  };

  handleSearchText(e: any) {
    const searchVal = e.target.value;
    this.setState({ searchText: searchVal, studentPageNo: 1 }, () => {
      this.getStudentData(
        this.state.studentPageNo,
        this.state.studentPerPage,
        this.state.userToken,
        searchVal,
        this.state.columnSort
      );
    });
  }

  handleRowCheckboxChange = (index: number) => {
    this.setState((prevState) => {
      const isChecked = [...prevState.isChecked];
      isChecked[index] = !isChecked[index];
      return {
        isChecked,
        isHeaderChecked: isChecked.every((value) => value),
      };
    });
  };

  handleAdminRowCheckboxChange = (item:any) => {
    let studentArray = this.state.adminData;
    for (const element of studentArray) {
      if(element.id == item.id){
        element.isChecked= !element.isChecked
      }
    }
    let newFinalArray = studentArray.filter((item: {isChecked: boolean})=>{return item.isChecked && item})
    this.setState({
      adminData: studentArray,
      isAdminDelete: newFinalArray.length > 0 ? true : false
    })
  };

  handleGetStudentData(resJson: any) {
    if (resJson.meta?.message === configJSON.notFoundMessage) {
      this.setState({
        isAlert: true,
        alertMsg: resJson.meta.message,
        alertType: "info",
        isLoading: false,
        studentPageCount: 0,
        studentData: [],
        isChecked: [],
      });
    } else if (
      resJson?.errors &&
      Object.keys(resJson.errors[0])[0] === "token"
    ) {
      this.navigationLoginScreen();
    } else {
      let count = resJson.meta.total_accounts / this.state.studentPerPage;
      count = Math.ceil(count);
      let newArray = resJson.data.map((item: any) => ({
        ...item,
        isChecked: false,
      }));
      this.setState({
        studentData: newArray,
        isChecked: Array(
          this.state.studentData ? this.state.studentData.length : 0
        ).fill(false),
        studentPageCount: count,
        isLoading: false,
      });
    }
  }

  navigationLoginScreen = () => {
    alert(configJSON.sessionExpireMsg);
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };
  parseErrors = async (responseJson: ResponseErrors) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };
  handleDistrictChange(e: any) {
    const value = e.target.value;
    const label = e.currentTarget?.dataset?.label;
    if (value !== 0 && !value) {
      this.setState({ districtErr: "City is required" });
    } else {
      this.setState({ District: value, districtName: label, districtErr: "" });
    }
  }
  handleEditDistrictChange(e: any, fieldName: string) {
    const value = e.target.value;

    const label = e.currentTarget?.dataset?.label;

    if (value !== 0 && !value) {
      this.setState((prevState) => ({
        studentDataEdit: {
          ...prevState.studentDataEdit,
          [fieldName]: value,
          [`${fieldName}Name`]: label,
        },
        District: value,
        districtErr: "City is required",
      }));
    } else {
      this.setState((prevState) => ({
        studentDataEdit: {
          ...prevState.studentDataEdit,
          [fieldName]: value,
          [`${fieldName}Name`]: label,
        },
        District: value,
        districtErr: "",
      }));
    }
  }
  handleDeleteModalClose() {
    this.setState({ isDeleteModalOpen: false });
  }

  handleStateChange(e: any) {
    const value = e.target.value;
    const label = e.currentTarget?.dataset?.label;
    if (value == 0) {
      this.setState({ stateErr: "State is required" });
    } else {
      this.setState({
        state: value,
        stateName: label,
        stateErr: "",
        District: "",
      });
      this.getAllCity(value);
    }
  }

  handleEditStateChange(e: any, fieldName: string) {
    const value = e.target.value;
    const label = e.currentTarget?.dataset?.label;
    if (value === 0) {
      this.setState((prevState) => ({
        studentDataEdit: {
          ...prevState.studentDataEdit,
          [fieldName]: value,
          [`${fieldName}Name`]: label,
        },
        state: value,
        District: "",
        stateErr: "State is required",
      }));
    } else {
      this.setState(
        (prevState) => ({
          studentDataEdit: {
            ...prevState.studentDataEdit,
            [fieldName]: value,
            [`${fieldName}Name`]: label,
          },
          stateErr: "",
          state: value,
          District: "",
        }),
        () => {
          this.getAllCity(value);
        }
      );
    }
  }

  handleAddStudentResponse(responseJson: any) {
    if(responseJson.errors && responseJson.errors.length > 0 && Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen()
    }
    if (responseJson?.meta?.message == configJSON.accountCreatedMessage) {
      this.setState({
        isAlert: true,
        alertMsg: configJSON.accountCreatedMessage,
        alertType: "success",
        isLoading: false,
        isAddStudent: false,
      });
      this.handleStudentDataReset();
      this.getStudentData(
        this.state.studentPageNo,
        this.state.studentPerPage,
        this.state.userToken,
        this.state.searchText,
        this.state.columnSort
      );
    }
    if (responseJson.errors) {
      const baseErrors = responseJson.errors.base?.join(", ");
      const profileIconErrors = responseJson.errors.profile_icon?.join(", ");
      this.setState({
        isLoading: false,
        isAlert: true,
        alertType: "error",
        alertMsg: baseErrors || profileIconErrors,
      });
    }
  }

  getAllCity(stateId: string | number) {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.getEducationContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCityCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.CityAPiEndPoint + `${stateId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAllStates() {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.getEducationContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiStateCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.stateApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleFileRemove = () => {
    this.setState((prevState) => ({
      selectedFile: "",
      selectedFileName: "",
      fileErr: "",
      previewThumbnail: "",
      studentDataEdit: {
        ...prevState.studentDataEdit,
        profile_icon: "",
        profile_iconName: "",
      },
    }));
  };

  handleFileChange = (event: any) => {
    const files = event.target?.files;

    if (files && files.length > 0) {
      const file = files[0];
      const fileName = file.name;
      const fileSize = file.size;

      const allowedFormats = ["image/png", "image/jpeg", "image/jpg"];
      if (!allowedFormats.includes(file.type)) {
        this.setState({
          selectedFile: "",
          selectedFileName: "",
          previewThumbnail: "",
          fileErr:
            "Invalid file format. Please upload a PNG, JPG, or JPEG file.",
        });
      } else if (fileSize > configJSON.maxFileSize) {
        this.setState({
          selectedFile: "",
          selectedFileName: "",
          previewThumbnail: "",
          fileErr: "File size exceeds 2MB. Please choose a smaller file.",
        });
      } else {
        const imageUrl = URL.createObjectURL(file);
        this.setState({
          selectedFile: file,
          selectedFileName: fileName,
          fileErr: "",
          previewThumbnail: imageUrl,
        });
      }
    } else {
      this.setState({
        selectedFile: "",
        selectedFileName: "",
        previewThumbnail: "",
        fileErr: "No file is selected",
      });
    }
  };

  handleEditFileChange = (event: any, fieldName: string) => {
    const files = event.target?.files;

    if (files && files.length > 0) {
      const file = files[0];
      const fileName = file.name;
      const fileSize = file.size;

      const allowedFormats = ["image/png", "image/jpeg", "image/jpg"];
      if (!allowedFormats.includes(file.type)) {
        this.setState((prevState) => ({
          previewThumbnail: "",
          studentDataEdit: {
            ...prevState.studentDataEdit,
            [fieldName]: null,
            [`${fieldName}Name`]: "",
          },
          fileErr:
            "Invalid file format. Please upload a PNG, JPG, or JPEG file.",
        }));
      } else if (fileSize > configJSON.maxFileSize) {
        this.setState((prevState) => ({
          previewThumbnail: "",
          studentDataEdit: {
            ...prevState.studentDataEdit,
            [fieldName]: null,
            [`${fieldName}Name`]: "",
          },
          fileErr: "File size exceeds 2MB. Please choose a smaller file.",
        }));
      } else {
        const imageUrl = URL.createObjectURL(file);
        this.setState((prevState) => ({
          fileErr: "",
          studentDataEdit: {
            ...prevState.studentDataEdit,
            [fieldName]: file,
            [`${fieldName}Name`]: fileName,
          },
          previewThumbnail: imageUrl,
        }));
      }
    } else {
      this.setState({
        studentDataEdit: {
          ...this.state.studentDataEdit,
          [fieldName]: null,
          [`${fieldName}Name`]: "",
        },
        previewThumbnail: "",
        fileErr: "No file is selected",
      });
    }
  };

  handleFullNameChange(e: any) {
    const value: string = e.target.value.trimStart();
    if (value.length > 60) {
      this.setState({
        fullNameErr: "Full Name cannot be more than 60 characters",
      });
      return;
    }
    if (!fullNameRegex.test(value.trim())) {
      this.setState({
        fullName: value,
        fullNameErr: configJSON.fullNameErrMsg,
      });
      return;
    }

    this.setState({
      fullName: value,
      fullNameErr: "",
    });
  }

  handleEditFullNameChange(e: any, fieldName: string) {
    const value: string = e.target.value.trimStart();
  
    if (value.length > 60) {
      this.setState({
        fullNameErr: "Full Name cannot be more than 60 characters",
      });
      return;
    }
  
    if (!fullNameRegex.test(value)) {
      this.setState({
        studentDataEdit: {
          ...this.state.studentDataEdit,
          [fieldName]: value,
        },
        fullNameErr: configJSON.fullNameErrMsg,
      });
      return;
    }
  
    this.setState({
      studentDataEdit: {
        ...this.state.studentDataEdit,
        [fieldName]: value,
      },
      fullNameErr: "",
    });
  }

  handleEmailchange(e: any) {
    const inputEmail = e.target.value.trim();
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (emailRegex.test(inputEmail)) {
      this.setState({ Email: inputEmail, emailErr: "" });
    } else {
      this.setState({
        Email: inputEmail,
        emailErr: "Please Enter a valid email",
      });
    }
  }
  handleEditEmailChange(e: any, fieldName: string) {
    const inputEmail = e.target.value.trim();
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (emailRegex.test(inputEmail)) {
      this.setState({
        studentDataEdit: {
          ...this.state.studentDataEdit,
          [fieldName]: inputEmail,
        },
        emailErr: "",
      });
    } else {
      this.setState({
        studentDataEdit: {
          ...this.state.studentDataEdit,
          [fieldName]: inputEmail,
        },
        emailErr: "Please Enter a valid email",
      });
    }
  }

  handleSchoolNameChange(e: any) {
    let a = e.target.value;
    if (studentRegex.test(a.trim())) {
      this.setState({ schoolName: a, schoolNameErr: "" });
    } else {
      this.setState({
        schoolName: a,
        schoolNameErr: "Please enter a valid School/College",
      });
    }
  }

  handleEditSchoolNameChange(e: any, fieldName: string) {
    const value = e.target.value;
    if (studentRegex.test(value.trim())) {
      this.setState((prevState) => ({
        studentDataEdit: {
          ...prevState.studentDataEdit,
          [fieldName]: value,
        },
        schoolNameErr: "",
      }));
    } else {
      this.setState((prevState) => ({
        studentDataEdit: {
          ...prevState.studentDataEdit,
          [fieldName]: value,
        },
        schoolNameErr: "Please enter a valid School/College",
      }));
    }
  }

  handleDateChange = (value: any) => {
    this.setState({ date: value });

    if (!value) {
      this.setState({ dobErr: "DOB is required" });
    } else {
      this.setState({ dobErr: "" });

      let selectedDate = new Date(value);
      let today = new Date();
      let limitDate = moment().subtract(100, "year").calendar();
      let limit = new Date(limitDate);
      today.setHours(0, 0, 0, 0);

      if (selectedDate > today || selectedDate < limit) {
        this.setState({ dobErr: "Please select a valid date" });
      }
    }
  };

  handleEditDateChange(e: any, fieldName: string) {
    const selectedDate = new Date(e.target.value);
    const today = new Date();
    const limitDate = moment().subtract(100, "year").calendar();
    const limit = new Date(limitDate);
    today.setHours(0, 0, 0, 0);

    if (!e.target.value || selectedDate > today || selectedDate < limit) {
      this.setState({
        studentDataEdit: {
          ...this.state.studentDataEdit,
          [fieldName]: "",
        },
        dobErr: "Please select a valid date",
      });
    } else {
      this.setState({
        studentDataEdit: {
          ...this.state.studentDataEdit,
          [fieldName]: e.target.value,
        },
        dobErr: "",
      });
    }
  }

  handleInputChange = (e: any) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 10) {
      this.setState({ phone: value, phoneErr: "" }, () => {
        if (this.state.phone === "") {
          this.setState({ phoneErr: "Please enter a valid number" });
        }
      });
    }
  };

  handleEditInputChange(e: any, fieldName: string) {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 10) {
      this.setState({
        studentDataEdit: {
          ...this.state.studentDataEdit,
          [fieldName]: value,
        },
        phoneErr: "",
      }, () => {
        if(value === "") {
          this.setState({  phoneErr: "Please enter a valid number" })
        }
      });
    } 
  }

  handleGenderChange(e: any) {
    const value = e.target.value;
    if (value !== 0 && !value) {
      this.setState({ genderErr: "Gender is required" });
    } else {
      this.setState({ gender: value, genderErr: "" });
    }
  }

  handleEditGenderChange(e: any, fieldName: string) {
    const value = e.target.value;
    if (value !== 0 && !value) {
      this.setState({
        studentDataEdit: {
          ...this.state.studentDataEdit,
          [fieldName]: "",
        },
        genderErr: "Gender is required",
      });
    } else {
      this.setState({
        studentDataEdit: {
          ...this.state.studentDataEdit,
          [fieldName]: value,
        },
        genderErr: "",
      });
    }
  }

  handleEditStudent() {
    const editData = {
      fullName: this.state.studentDataEdit.full_name,
      gender: this.state.studentDataEdit.gender,
      dateOfBirth: this.state.studentDataEdit.date_of_birth,
      email: this.state.studentDataEdit.email,
      phoneNumber: this.state.studentDataEdit.phone_number,
      schoolName: this.state.studentDataEdit.school_name,
      district: this.state.District,
      state: this.state.state,
      profileIcon: this.state.studentDataEdit.profile_icon,
    };
    if (!this.checkStudentFormValidations(editData)) {
      this.editStudentAfterValidations();
    }
  }

  handleAddStudent() {
    const updatedValues = {
      fullName: this.state.fullName,
      gender: this.state.gender,
      dateOfBirth: this.state.date,
      email: this.state.Email,
      phoneNumber: this.state.phone,
      schoolName: this.state.schoolName,
      district: this.state.District,
      state: this.state.state,
      profileIcon: this.state.selectedFileName,
    };

    if (!this.checkStudentFormValidations(updatedValues)) {
      this.addStudentAfterValidations();
    }
  }

  checkStudentFormValidations(updatedValues: any): boolean {
    const validationFields = [
      {
        field: "fullName",
        errorKey: "fullNameErr",
        errorMessage: "Full Name is required",
        regex: fullNameRegex,
      },
      {
        field: "profileIcon",
        errorKey: "fileErr",
        errorMessage: "Profile is required",
      },
      {
        field: "gender",
        errorKey: "genderErr",
        errorMessage: "Gender is required",
      },
      {
        field: "dateOfBirth",
        errorKey: "dobErr",
        errorMessage: "Date of Birth is required",
      },
      {
        field: "email",
        errorKey: "emailErr",
        errorMessage: "Email is required",
      },
      {
        field: "state",
        errorKey: "stateErr",
        errorMessage: "State is required",
      },
      {
        field: "schoolName",
        errorKey: "schoolNameErr",
        errorMessage: "School is required",
        regex: studentRegex,
      },
      {
        field: "district",
        errorKey: "districtErr",
        errorMessage: "District is required",
      },
      {
        field: "phoneNumber",
        errorKey: "phoneErr",
        errorMessage: "Phone Number is required",
      }
    ];

    let hasError = false;

    validationFields.forEach(({ field, errorKey, errorMessage, regex }) => {
      if (
        !updatedValues[field] ||
        updatedValues[field]?.length === 0 ||
        (regex && !regex.test(updatedValues[field].trim()))
      ) {
        this.setState((prevState) => ({
          ...prevState,
          [errorKey]: errorMessage,
        }));
        hasError = true;
      } else {
        this.setState((prevState) => ({
          ...prevState,
          [errorKey]: "",
        }));
      }
    });

    return hasError;
  }

  determineTitle = () => {
    if (this.state.isAddStudent) {
      return "Add Student";
    } else if (this.state.isStudentEdit) {
      return "Edit Student";
    } else {
      return "Students";
    }
  };

  addStudentAfterValidations = () => {
    this.setState({ isLoading: true });
    const studentHeader = new Headers();
    studentHeader.append("token", this.state.userToken);

    const formData = new FormData();
    formData.append("students[full_name]", this.state.fullName);
    formData.append("students[gender]", this.setGenderUpdate(this.state.gender));
    formData.append("students[date_of_birth]", this.state.date);
    formData.append("students[email]", this.state.Email);
    formData.append(
      "students[full_phone_number]",
      countryCode + this.state.phone
    );
    formData.append("students[school_name]", this.state.schoolName);
    formData.append("students[district_id]", this.state.District);
    formData.append("students[state_id]", this.state.state);
    formData.append(
      "students[profile_icon]",
      this.state.selectedFile,
      this.state.selectedFileName
    );
    formData.append("students[activated]", "true");
    formData.append("role_name", "Student");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addStudentAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addStudentApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      studentHeader
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndPoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleToggleStatusAPIResponse(responseJson: any) {
    if(responseJson.errors && responseJson.errors.length > 0 && Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen()
    }
    if(responseJson.errors || responseJson.error){
      this.setState({
        isAlert: true,
        alertMsg: responseJson.error,
        alertType: "error",
        isLoading: false,
      });
    }
    if (responseJson?.meta?.message == configJSON.accountStatusChangedMessage) {
      this.setState({
        isAlert: true,
        alertMsg: configJSON.accountStatusChangedMessage,
        alertType: "success",
        isLoading: false,
      });
      this.getTableData();
    }
  }

  handleToggleStatus(
    id: number,
    status: boolean,
    index: number,
    arrayName: string
  ) {
    this.setState({ isLoading: true });
    const Header = new Headers();
    Header.append("token", this.state.userToken);
    Header.append("Content-Type", configJSON.getEducationContentType);

    const body = {
      change_status: status,
    };

    const stateClone: any = this.state;
    let clone = [...stateClone[arrayName]];
    clone[index].attributes.status = status;

    this.setState({ [arrayName]: clone } as any);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.toggleStatusAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateStatusApiEndpoint + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      Header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeUpdate
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  editStudentAfterValidations = () => {
    this.setState({ isLoading: true });
    const Header = new Headers();
    Header.append("token", this.state.userToken);
    const formData = new FormData();
    formData.append(
      "students[full_name]",
      this.state.studentDataEdit.full_name
    );
    formData.append("students[gender]", this.setGenderUpdate(this.state.studentDataEdit.gender));
    formData.append(
      "students[date_of_birth]",
      this.state.studentDataEdit.date_of_birth
    );
    formData.append("students[email]", this.state.studentDataEdit.email);
    formData.append(
      "students[full_phone_number]",
      countryCode + this.state.studentDataEdit.phone_number
    );
    formData.append(
      "students[school_name]",
      this.state.studentDataEdit.school_name
    );
    if (this.state.District) {
      formData.append("students[district_id]", this.state.District);
    }
    if (this.state.state) {
      formData.append("students[state_id]", this.state.state);
    }
    if (this.state.studentDataEdit.profile_iconName) {
      formData.append(
        "students[profile_icon]",
        this.state.studentDataEdit.profile_icon,
        this.state.studentDataEdit.profile_iconName
      );
    }

    formData.append(
      "students[activated]",
      this.state.studentDataEdit.activated
    );
    formData.append("role_name", this.state.studentDataEdit.role_name);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editStudentAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateStudentApiEndPoint + `/${this.state.studentId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      Header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeUpdate
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleEditStudentData(row: any) {
    let getRowData = row.attributes;
    getRowData.gender = this.setGender(getRowData.gender)
    this.setState(
      {
        studentDataEdit: getRowData,
        District: row?.attributes?.district_name?.id,
        state: row?.attributes?.state_name?.id,
        studentId: row.id,
        previewThumbnail: row?.attributes?.profile_icon || "",
        gender: row?.attributes.gender
      },
      () => {
        if (row?.attributes?.state_name?.id)
          this.getAllCity(row.attributes.state_name.id);
        this.setState({ isStudentEdit: true });
      }
    );
  }

  handleStudentDataReset() {
    this.setState({
      fullName: "",
      fullNameErr: "",
      selectedFileName: "",
      fileErr: "",
      selectedFile: "",
      date: "",
      dobErr: "",
      gender: "",
      genderErr: "",
      state: "",
      stateErr: "",
      District: "",
      districtErr: "",
      Email: "",
      emailErr: "",
      phone: "",
      phoneErr: "",
      schoolName: "",
      schoolNameErr: "",
      previewThumbnail: "",
      studentDataEdit: {
        gender: "",
        role_name: this.state.studentDataEdit.role_name,
        activated: this.state.studentDataEdit.activated,
      },
    });
  }

  filterDataOnCol(item: string, type: "student" | "admin" | "instructor") {
    const sortParams: Record<string, string> = {
      "S.no": "column_id_sort",
      "Student Name": "column_name_sort",
      Status: "column_status_sort",
      "Instructor Name": "column_name_sort",
      "Admin Name": "column_name_sort",
      "S.No": "column_id_sort",
    };

    let columnSort = "";
    const sortOrder =
      this.state.currentSortState.order === "asc" ? "desc" : "asc";

    if (sortParams.hasOwnProperty(item)) {
      columnSort = `${sortParams[item]}=${sortOrder}`;
      this.setState({
        currentSortState: {
          field: item,
          order: this.state.currentSortState.order === "asc" ? "desc" : "asc",
        },
        columnSort,
      });
    }
    const getData: Record<string, () => void> = {
      student: () =>
        this.getStudentData(
          this.state.studentPageNo,
          this.state.studentPerPage,
          this.state.userToken,
          this.state.searchText,
          columnSort
        ),
      admin: () =>
        this.getAdminData(
          this.state.adminPageNo,
          this.state.adminPerPage,
          this.state.userToken,
          this.state.searchText,
          columnSort
        ),
      instructor: () =>
        this.getInstructorData(
          this.state.adminPageNo,
          this.state.adminPerPage,
          this.state.userToken,
          this.state.searchText,
          columnSort
        ),
    };
    getData[type]();
  }

  deleteStudent(id: any) {
    this.setState({ studentId: id, isDeleteModalOpen: true });
  }
  handlePageChange = (event: any, value: number) => {
    this.setState({ studentPageNo: value }, () =>
      this.getStudentData(
        this.state.studentPageNo,
        this.state.studentPerPage,
        this.state.userToken,
        this.state.searchText,
        this.state.columnSort
      )
    );
  };

  exportCategory = () => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.userToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.exportStudentCSVApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.exportStudentCSVApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndPoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getInstructorData(
    pageNo: number,
    perPage: number,
    token: any,
    search: string,
    sort: string
  ) {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessageStudent = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getInstructorApiCallId = requestMessageStudent.messageId;
    requestMessageStudent.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.filterDataAPIEndPoint +
        `?page=${pageNo}&per_page=${perPage}&role_name=Instructor&search_params=${
          search || ""
        }&${sort || ""}`
    );

    requestMessageStudent.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageStudent.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessageStudent.id, requestMessageStudent);
  }

  handleGetInstructorData(resJson: any) {
    if (resJson.meta?.message === configJSON.notFoundMessage) {
      this.setState({
        isAlert: true,
        alertMsg: resJson.meta.message,
        alertType: "info",
        isLoading: false,
        instructorPageCount: 0,
        instructorData: [],
        isChecked: [],
      });
    } else if (
      resJson?.errors && Object.keys(resJson.errors[0])[0] === "token"
    ) {
      this.navigationLoginScreen();
    } else {
      let count = resJson.meta.total_accounts / this.state.instructorPerPage;
      count = Math.ceil(count);
      this.setState({
        instructorData: resJson.data,
        isChecked: Array(
          this.state.instructorData ? this.state.instructorData.length : 0
        ).fill(false),
        instructorPageCount: count,
        isLoading: false,
      });
    }
  }

  mapInstructorData(instructorData: any) {
    return {
      instructorName: instructorData?.full_name || "",
      instructorGender: this.setGender(instructorData?.gender) || "",
      instructorDOB: instructorData?.date_of_birth || "",
      instructorQualifification: instructorData?.qualifications || "",
      instructorLectures: instructorData?.leacture_in || "",
      instructorDetails: instructorData?.details || "",
      instructorShortDetails: instructorData.short_details || "",
      instructorEmail: instructorData?.email || "",
      instructorMobile: instructorData?.phone_number || "",
    };
  }

  handleEditInstructorData(row: any) {
    const mappedInstructorData = this.mapInstructorData(row?.attributes);
    this.setState(
      {
        instructorFormData: mappedInstructorData,
        selectedFileName: row?.attributes.profile_icon,
        instructorId: row.id,
        previewThumbnail: row?.attributes?.profile_icon,
      },
      () => {
        this.setState({ isEditInstructor: true });
      }
    );
  }

  deleteInstructorAPIResponse(responseJson: any) {
    if (responseJson.message == configJSON.instructorAccountDeletedMessage) {
      this.setState({
        isAlert: true,
        alertMsg: "Account was successfully destroyed",
        alertType: "success",
        isLoading: false,
        isInstructorDeleteModalOpen: false,
      });
      this.getInstructorData(
        this.state.instructorPageNo,
        this.state.instructorPerPage,
        this.state.userToken,
        this.state.searchText,
        this.state.columnSort
      );
    }

    if(responseJson.errors && responseJson.errors.length > 0 && Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } 

    if (responseJson.errors || responseJson.error) {
      this.setState({
        isAlert: true,
        alertMsg: responseJson.errors || responseJson.error,
        alertType: "error",
        isLoading: false,
        isInstructorDeleteModalOpen:false
      });
    }
  }

  deleteInstructorData(id: any) {
    this.setState({ isLoading: true });
    const deleteInstructorHeader = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.userToken,
    };
    const reqMsgInstructor = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteInstructorApiCallId = reqMsgInstructor.messageId;
    reqMsgInstructor.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteInstructorAPIEndPoint + `/${id}?role_name=Instructor`
    );

    reqMsgInstructor.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(deleteInstructorHeader)
    );

    reqMsgInstructor.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );
    runEngine.sendMessage(reqMsgInstructor.id, reqMsgInstructor);
  }

  deleteInstructorClose() {
    this.setState({ isInstructorDeleteModalOpen: false });
  }
  deleteInstructor(id: any) {
    this.setState({ instructorId: id }, () => {
      this.setState({ isInstructorDeleteModalOpen: true });
    });
  }
  handleInstructorPageChange = (event: any, value: number) => {
    this.setState({ instructorPageNo: value }, () =>
      this.getInstructorData(
        this.state.instructorPageNo,
        this.state.instructorPerPage,
        this.state.userToken,
        this.state.searchText,
        this.state.columnSort
      )
    );
  };

  determineInstructorTitle = () => {
    if (this.state.isAddInstructor) {
      return "Add Instructor";
    } else if (this.state.isEditInstructor) {
      return "Edit Instructor";
    } else {
      return "Instructors";
    }
  };

  handleInstructorSearchText(e: any) {
    const text = e.target.value;
    this.setState({ searchText: text, instructorPageNo: 1 }, () => {
      this.getInstructorData(
        this.state.adminPageNo,
        this.state.adminPerPage,
        this.state.userToken,
        text,
        this.state.columnSort
      );
    });
  }

  handleInstructorChange = (e: any) => {
    const { name, value } = e.target;
    const { instructorFormData, errors } = this.state;

    let errorMessage = "";

    switch (name) {
      case "instructorEmail":
        errorMessage = this.validateEmail(value);
        break;
      case "instructorMobile":
        if (this.validateMobile(value)) {
          errorMessage = this.isConditionalRending(value.length === 0 , "Mobile number is required" , "")
          this.setState({
            instructorFormData: { ...instructorFormData, [name]: value },
            errors: { ...errors, [name]: errorMessage },
          });
        }
        return;
      case "instructorName":
        errorMessage =
        fullNameRegex.test(
            value
          )
            ? ""
            : configJSON.fullNameErrMsg;
           let errorMessages = "Instructor name cannot be more than 60 characters"
          if (value.length > 60 ) {
            this.setState({
              errors: { ...errors, [name]: errorMessages },
            });
            return;
          }
        break;
        case "instructorQualifification":
          errorMessage = this.isConditionalRending(value.length > 100 , configJSON.instructorQualificationErrMsg , "")
          if (value.length > 100 ) {
            this.setState({
              errors: { ...errors, [name]: errorMessage },
            });
            return;
          }
          this.setState({
            instructorFormData: { ...instructorFormData, [name]: value },
            errors: { ...errors, [name]: errorMessage },
          });
          return
          case "instructorLectures":
          errorMessage = this.isConditionalRending(value.length > 100 , configJSON.instructorteachingErrMsg , "")
          if (value.length > 100 ) {
            this.setState({
              errors: { ...errors, [name]: errorMessage },
            });
            return;
          }
          this.setState({
            instructorFormData: { ...instructorFormData, [name]: value },
            errors: { ...errors, [name]: errorMessage },
          });
          return
      case "instructorDetails":
        errorMessage = this.isConditionalRending(value.length > 800 , configJSON.detailsQuoteErrMsg , "")
        if (value.length > 800 ) {
          this.setState({
            errors: { ...errors, [name]: errorMessage },
          });
          return;
        }
        this.setState({
          instructorFormData: { ...instructorFormData, [name]: value },
          errors: { ...errors, [name]: errorMessage },
        });
        return
      case "instructorShortDetails":
        this.setInstructorShortDetails(value,name,instructorFormData, errors)
        return
      default:
        break;
    }

    this.setState({
      instructorFormData: { ...instructorFormData, [name]: value },
      errors: { ...errors, [name]: errorMessage },
      errorMessage: errorMessage ? "" : this.state.errorMessage,
    });
  };


  setInstructorShortDetails(value: string, name: string, instructorFormData: InstructorData, errors: Record<string, string>) {
    let errorMessage = this.isConditionalRending(value.length > 800, configJSON.shortDetailsQuoteErrMsg, "")
    if (value.length > 800) {
      this.setState({
        errors: { ...errors, [name]: errorMessage },
      });
      return;
    }
    this.setState({
      instructorFormData: { ...instructorFormData, [name]: value },
      errors: { ...errors, [name]: errorMessage },
    });
  }
  
  isConditionalRending = (condition: boolean, value: string, value2: string) => {
    return condition ? value : value2
  }

  validateEmail(value: string): string {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? "" : "Invalid email address";
  }

  validateMobile(value: string): boolean {
    return /^\d*$/.test(value) && value.length <= 10;
  }
  handleAddInstructor = () => {
    const { instructorFormData, errors, isEditInstructor } = this.state;
    const fieldNames: Record<string, string> = {
      instructorGender: "Instructor gender",
      instructorName: "Instructor name",
      instructorDOB: "Instructor date of birth",
      instructorQualifification: "Instructor qualifications",
      instructorLectures: "Instructor teaching",
      instructorDetails: "Instructor details",
      instructorShortDetails: "Instructor short details",
      instructorEmail: "Instructor email",
      instructorMobile: "Instructor mobile"
    };
    const emptyFields = Object.entries(instructorFormData)
      .filter(([_, value]) => `${value}`.trim() === "")
      .map(([field]) => field);
    const emptyErrors = Object.values(errors);
    if (
      emptyFields.length > 0 ||
      (emptyErrors.length > 0 && emptyErrors.every((v) => v !== "")) ||
      (this.state.selectedFileName?.length === 0)
    ) {
      const emptyFieldErrors = emptyFields.reduce((acc, field) => {
        const friendlyName = fieldNames[field] || field; 
        acc[field] = `${friendlyName} is required`;
        return acc;
      }, {} as Record<string, string>);

      if (this.state.selectedFileName?.length === 0) {
        this.setState({ fileErr: "Profile is required" });
      } else {
        this.setState({ fileErr: "" });
      }

      this.setState({
        errors: { ...errors, ...emptyFieldErrors },
        errorMessage: "Please fill in all required fields.",
      });
    } else {
      {
        isEditInstructor
          ? this.updateInstructorAfterValidations()
          : this.addInstructorAfterValidations();
      }
    }
  };

  addInstructorAfterValidations = () => {
    this.setState({ isLoading: true });
    const instructorHeader = new Headers();
    instructorHeader.append("token", this.state.userToken);
    const {
      instructorDOB,
      instructorDetails,
      instructorShortDetails,
      instructorEmail,
      instructorGender,
      instructorLectures,
      instructorMobile,
      instructorName,
      instructorQualifification,
    } = this.state.instructorFormData;
    const formData = new FormData();
    formData.append("instructors[full_name]", instructorName);
    formData.append("instructors[gender]", this.setGenderUpdate(instructorGender));
    formData.append("instructors[date_of_birth]", instructorDOB);
    formData.append("instructors[email]", instructorEmail);
    formData.append(
      "instructors[full_phone_number]",
      countryCode + instructorMobile
    );
    formData.append("instructors[qualifications]", instructorQualifification);
    formData.append("instructors[leacture_in]", instructorLectures);
    formData.append("instructors[details]", instructorDetails);
    formData.append("instructors[short_details]", instructorShortDetails);
    formData.append(
      "instructors[profile_icon]",
      this.state.selectedFile,
      this.state.selectedFileName
    );
    formData.append("instructors[activated]", "true");
    formData.append("role_name", "Instructor");

    const reqMsgAddInstructor = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addInstructorApiCallId = reqMsgAddInstructor.messageId;

    reqMsgAddInstructor.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addStudentApiEndPoint
    );
    reqMsgAddInstructor.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      instructorHeader
    );
    reqMsgAddInstructor.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    reqMsgAddInstructor.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndPoint
    );

    runEngine.sendMessage(reqMsgAddInstructor.id, reqMsgAddInstructor);
  };

  handleAddInstructorAPIResponse(responseJson: any) {
    if (responseJson?.meta?.message == "account successfully created.") {
      this.setState({
        isAlert: true,
        alertMsg: "account successfully created.",
        alertType: "success",
        isLoading: false,
        isAddInstructor: false,
      });
      this.handleInstructorDataReset();
      this.getInstructorData(
        this.state.instructorPageNo,
        this.state.instructorPerPage,
        this.state.userToken,
        this.state.searchText,
        this.state.columnSort
      );
    }
    if (responseJson.errors) {
      if(responseJson.errors && responseJson.errors.length > 0 && Object.keys(responseJson.errors[0])[0] === "token") {
        this.navigationLoginScreen()
      }
      const baseErrors = responseJson.errors.base?.join(", ");
      const profileIconErrors = responseJson.errors.profile_icon?.join(", ");
      this.setState({
        isLoading: false,
        isAlert: true,
        alertType: "error",
        alertMsg: baseErrors || profileIconErrors,
      });
    }
  }
  updateInstructorAfterValidations = () => {
    this.setState({ isLoading: true });
    const updateInstructorHeader = new Headers();
    updateInstructorHeader.append("token", this.state.userToken);
    const {
      instructorDOB,
      instructorDetails,
      instructorEmail,
      instructorGender,
      instructorLectures,
      instructorMobile,
      instructorName,
      instructorQualifification,
      instructorShortDetails
    } = this.state.instructorFormData;
    const instructorData = {
      full_name: instructorName,
      gender: this.setGenderUpdate(instructorGender),
      date_of_birth: instructorDOB,
      email: instructorEmail,
      full_phone_number: countryCode + instructorMobile,
      qualifications: instructorQualifification,
      leacture_in: instructorLectures,
      details: instructorDetails,
      short_details: instructorShortDetails,
    };

    const formData = new FormData();
    Object.entries(instructorData).forEach(([key, value]) => {
      formData.append(`instructors[${key}]`, value);
    });

    if (this.state.selectedFile) {
      formData.append(
        "instructors[profile_icon]",
        this.state.selectedFile,
        this.state.selectedFileName
      );
    }

    formData.append("instructors[activated]", "true");
    formData.append("role_name", "Instructor");

    const reqMsgAddInstructor = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editInstructorApiCallId = reqMsgAddInstructor.messageId;

    reqMsgAddInstructor.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateStudentApiEndPoint + `/${this.state.instructorId}`
    );

    reqMsgAddInstructor.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      updateInstructorHeader
    );

    reqMsgAddInstructor.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    reqMsgAddInstructor.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeUpdate
    );

    runEngine.sendMessage(reqMsgAddInstructor.id, reqMsgAddInstructor);
  };

  handleInstructorDataReset() {
    this.setState({
      instructorFormData: {
        instructorName: "",
        instructorGender: "",
        instructorDOB: "",
        instructorQualifification: "",
        instructorLectures: "",
        instructorDetails: "",
        instructorShortDetails:"",
        instructorEmail: "",
        instructorMobile: "",
      },
      instructorFormDataError: {
        instructorName: "",
        instructorGender: "",
        instructorDOB: "",
        instructorQualifification: "",
        instructorLectures: "",
        instructorDetails: "",
        instructorEmail: "",
        instructorMobile: "",
      },
      previewThumbnail: "",
      errorMessage: "",
      errors: {},
      selectedFile: "",
      selectedFileName: "",
    });
  }
  determineAdminTitle = () => {
    if (this.state.isAdminAdd) {
      return "Add Admin";
    } else if (this.state.isAdminEdit) {
      return "Edit Admin";
    } else {
      return "Admins";
    }
  };
  handleAdminPageChange = (event: any, value: number) => {
    this.setState({ adminPageNo: value, isAdminDelete: false, isAdminHeaderChecked:false }, () =>
      this.getAdminData(
        this.state.adminPageNo,
        this.state.adminPerPage,
        this.state.userToken,
        this.state.searchText,
        this.state.columnSort
      )
    );
  };
  editInstructorAPIResponse(responseJson: any) {
    if(responseJson.errors && responseJson.errors.length > 0 && Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } 
    if (
      responseJson?.meta?.message == "instructor account successfully updated."
    ) {
      this.setState({
        isAlert: true,
        alertMsg: "instructor account successfully updated.",
        alertType: "success",
        isLoading: false,
        isEditInstructor: false,
      });
      this.handleInstructorDataReset();
      this.getInstructorData(
        this.state.instructorPageNo,
        this.state.instructorPerPage,
        this.state.userToken,
        this.state.searchText,
        this.state.columnSort
      );
    }

    if (responseJson.data && !responseJson.data.attributes) {
      const baseErrors = responseJson.data.base?.join(", ");
      const profileIconErrors = responseJson.data.profile_icon?.join(", ");
      this.setState({
        isLoading: false,
        isAlert: true,
        alertType: "error",
        alertMsg: baseErrors || profileIconErrors,
      });
    }
  }
  handleAdminDataAPIResponse(resJson: any) {
    if (resJson.meta?.message === configJSON.notFoundMessage) {
      this.setState({
        isAlert: true,
        alertMsg: resJson.meta.message,
        alertType: "info",
        isLoading: false,
        adminTotalPage: 0,
        adminData: [],
        isChecked: [],
      });
    } else if (
      resJson?.errors && Object.keys(resJson.errors[0])[0] === "token"
    ) {
      this.navigationLoginScreen();
    } else {
      let count = resJson.meta.total_accounts / this.state.adminPerPage;
      count = Math.ceil(count);
      let newArray = resJson.data.map((item: any) => ({
        ...item,
        isChecked: false,
      }));
      this.setState({
        adminData: newArray,
        isChecked: Array(
          this.state.adminData ? this.state.adminData.length : 0
        ).fill(false),
        adminTotalPage: count,
        isLoading: false,
      });
    }
  }

  handleAdminInputChange = (e: any) => {
    const { name, value } = e.target;
    const { adminFormData, adminFormErrors } = this.state;
    let errMsg = "";

    switch (name) {
      case "adminEmail":
        errMsg = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
          ? "Invalid email address"
          : "";
        break;
      case "adminMobile":
        if (/^\d*$/.test(value) && value.length <= 10) {
          errMsg = value.length === 0 ? "Mobile number is required" : "";
          this.setState({
            adminFormData: { ...adminFormData, [name]: value },
            adminFormErrors: { ...adminFormErrors, [name]: errMsg },
          });
        }
        return;
      case "adminName":
        errMsg =
          fullNameRegex.test(
            value
          )
            ? ""
            : configJSON.fullNameErrMsg;
            let errorMessages = "Full name cannot be more than 60 characters"
          if (value.length > 60 ) {
            this.setState({
              adminFormErrors: { ...adminFormErrors, [name]: errorMessages },
            });
            return;
          }
        break;
      default:
        break;
    }
    this.setState({
      adminFormData: { ...adminFormData, [name]: value },
      adminFormErrors: { ...adminFormErrors, [name]: errMsg },
    });
  };
  updateAdminAfterValidations = () => {
    this.setState({ isLoading: true });
    const updateAdminHeader = new Headers();
    updateAdminHeader.append("token", this.state.userToken);
    const { adminDOB, adminEmail, adminName, adminGender, adminMobile } =
      this.state.adminFormData;
    const adminData = {
      full_name: adminName,
      gender: this.setGenderUpdate(adminGender),
      date_of_birth: adminDOB,
      email: adminEmail,
      full_phone_number: countryCode + adminMobile,
    };

    const formData = new FormData();
    Object.entries(adminData).forEach(([key, value]) => {
      formData.append(`admins[${key}]`, value);
    });

    if (this.state.selectedFile) {
      formData.append(
        "admins[profile_icon]",
        this.state.selectedFile,
        this.state.selectedFileName
      );
    }
    formData.append("role_name", "Super Admin");

    const reqMsgAdminUpdate = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateAdminApiCallId = reqMsgAdminUpdate.messageId;

    reqMsgAdminUpdate.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateStudentApiEndPoint + `/${this.state.adminId}`
    );

    reqMsgAdminUpdate.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      updateAdminHeader
    );

    reqMsgAdminUpdate.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    reqMsgAdminUpdate.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeUpdate
    );

    runEngine.sendMessage(reqMsgAdminUpdate.id, reqMsgAdminUpdate);
  };
  adminDataObject(adminData: any) {
    return {
      adminName: adminData?.full_name || "",
      adminGender: this.setGender(adminData?.gender) || "",
      adminDOB: adminData?.date_of_birth || "",
      adminEmail: adminData?.email || "",
      adminMobile: adminData?.phone_number || "",
    };
  }
  handleAdminEdit(row: any) {
    const data = this.adminDataObject(row?.attributes);
    this.setState(
      {
        adminFormData: data,
        selectedFileName: row?.attributes.profile_icon,
        adminId: row.id,
        previewThumbnail: row?.attributes?.profile_icon || "",
      },
      () => {
        this.setState({ isAdminEdit: true });
      }
    );
  }
  handleAdminCreateApiRespnse(responseJson: any) {
    if (responseJson?.meta?.message == "account successfully created.") {
      this.setState({
        isAlert: true,
        alertMsg: "account successfully created.",
        alertType: "success",
        isLoading: false,
        isAdminAdd: false,
      });
      this.handleAdminDataReset();
      this.getAdminData(
        this.state.adminPageNo,
        this.state.adminPerPage,
        this.state.userToken,
        this.state.searchText,
        this.state.columnSort
      );
    }
    if (responseJson.errors) {
      const baseErrors = responseJson.errors.base?.join(", ");
      const profileIconErrors = responseJson.errors.profile_icon?.join(", ");
      this.setState({
        isLoading: false,
        isAlert: true,
        alertType: "error",
        alertMsg: baseErrors || profileIconErrors,
      });
      if(responseJson.errors && responseJson.errors.length > 0 && Object.keys(responseJson.errors[0])[0] === "token") {
        this.navigationLoginScreen();
      } 
    }
  }
  deleteAdmin(id: any) {
    this.setState({ isAdminDeleteModal: true, adminId: id });
  }
  handleAdminDataReset() {
    this.setState({
      adminFormData: {
        adminDOB: "",
        adminEmail: "",
        adminMobile: "",
        adminGender: "",
        adminName: "",
      },
      selectedFile: "",
      selectedFileName: "",
      fileErr: "",
      previewThumbnail: "",
      adminFormErrors: {},
    });
  }
  handleAdminSearchText(e: any) {
    const text = e?.target?.value;
    this.setState({ searchText: text, adminPageNo: 1 }, () => {
      this.getAdminData(
        this.state.adminPageNo,
        this.state.adminPerPage,
        this.state.userToken,
        text,
        this.state.columnSort
      );
    });
  }
  handleAddAdmin = () => {
    const { adminFormData, adminFormErrors, isAdminEdit } = this.state;
    const emptyInput = Object.entries(adminFormData)
      .filter(([_, value]) => `${value}`.trim() === "")
      .map(([field]) => field);
    const emptyErrors = Object.values(adminFormErrors);
    const fieldNames: Record<string, string> = {
      adminName: "Admin fullname",
      adminDOB: "Admin date of birth",
      adminGender: "Admin gender",
      adminMobile: "Admin mobile",
      adminEmail: "Admin email",
    };
    if (
      emptyInput.length > 0 ||
      (emptyErrors.length > 0 && emptyErrors.every((v) => v !== "")) ||
      (this.state.selectedFileName?.length === 0)
    ) {
      const emptyInputError = emptyInput.reduce((acc, field) => {
        const friendlyName = fieldNames[field] || field; 
        acc[field] = `${friendlyName} is required`;
        return acc;
      }, {} as Record<string, string>);

      if (this.state.selectedFileName?.length === 0) {
        this.setState({ fileErr: "Profile is required" });
      } else {
        this.setState({ fileErr: "" });
      }

      this.setState({
        adminFormErrors: { ...adminFormErrors, ...emptyInputError },
      });
    } else {
      {
        isAdminEdit
          ? this.updateAdminAfterValidations()
          : this.addAdminAfterValidations();
      }
    }
  };
  oncloseAlert() {
    this.setState({
      isAlert: false,
    });
  }
  getAdminData(
    pageNo: number,
    perPage: number,
    token: any,
    search: string,
    sort: string
  ) {
    this.setState({ isLoading: true });
    const adminHeader = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const reqMsgAdmin = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSuperAdminDataApiCallId = reqMsgAdmin.messageId;
    reqMsgAdmin.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.filterDataAPIEndPoint +
        `?page=${pageNo}&per_page=${perPage}&role_name=Super Admin&search_params=${
          search || ""
        }&${sort || ""}`
    );

    reqMsgAdmin.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(adminHeader)
    );

    reqMsgAdmin.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(reqMsgAdmin.id, reqMsgAdmin);
  }
  updateAdminApiResponse(responseJson: any) {
    if (responseJson?.meta?.message == "admin account successfully updated.") {
      this.setState({
        isAlert: true,
        alertMsg: "admin account successfully updated.",
        alertType: "success",
        isLoading: false,
        isAdminEdit: false,
      });

      this.handleAdminDataReset();
      this.getAdminData(
        this.state.adminPageNo,
        this.state.adminPerPage,
        this.state.userToken,
        this.state.searchText,
        this.state.columnSort
      );
    }
    if(responseJson.errors && responseJson.errors.length > 0 && Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } 
    if (responseJson.data && !responseJson.data.attributes) {
      const baseErrors = responseJson.data.base?.join(", ");
      const profileIconErrors = responseJson.data.profile_icon?.join(", ");
      this.setState({
        isLoading: false,
        isAlert: true,
        alertType: "error",
        alertMsg: baseErrors || profileIconErrors,
      });
    }
  }
  handleFormDate(e: any) {
    let date = e?.target.value;
    this.setState({ studentFromDate: date , studentToDate: ""});
  }

  compareDates(date1: Date, date2: Date) {
    const date1_ms = date1.getTime(),
      date2_ms = date2.getTime();

    return date2_ms >= date1_ms;
  }

  handleToDate(e: any) {
    const date1 = new Date(this.state.studentFromDate);
    const date2 = new Date(e.target.value);
    if (this.state.studentFromDate && this.compareDates(date1, date2)) {
      this.setState({ studentToDate: e?.target.value }, () => {
        this.getStudentData(
          this.state.studentPageNo,
          this.state.studentPerPage,
          this.state.userToken,
          this.state.searchText,
          this.state.columnSort
        );
      });
    } else if(this.state.studentFromDate === "") {
      this.setState({
        isAlert: true,
        alertMsg: "Please select start date",
        alertType: "error",
        studentToDate: "",
      });
    }else {
      this.setState({
        studentToDate: "",
      });
    }
  }
  deleteAdminApi(id: any) {
    this.setState({ isLoading: true });
    const deleteAdminHeader = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.userToken,
    };
    const reqMsgAdminDelete = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteAdminApiCallId = reqMsgAdminDelete.messageId;
    reqMsgAdminDelete.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteInstructorAPIEndPoint + `/${id}?role_name=Super Admin`
    );

    reqMsgAdminDelete.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(deleteAdminHeader)
    );

    reqMsgAdminDelete.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );
    runEngine.sendMessage(reqMsgAdminDelete.id, reqMsgAdminDelete);
  }
  deleteAdminApiResponse(responseJson: any) {
    if(responseJson.errors && responseJson.errors.length > 0 && Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } 
    if(responseJson.error){
      this.setState({
        isLoading: false,
        isAlert: true,
        alertType: "error",
        alertMsg: responseJson.error,
        isAdminDeleteModal: false,
      })
    }
    if (responseJson.message == "Admin account was successfully destroyed.") {
      this.setState({
        isAlert: true,
        alertMsg: "Admin account was successfully destroyed.",
        alertType: "success",
        isLoading: false,
        isAdminDeleteModal: false,
      });
      this.getAdminData(
        this.state.adminPageNo,
        this.state.adminPerPage,
        this.state.userToken,
        this.state.searchText,
        this.state.columnSort
      );
    }
  }
  addAdminAfterValidations = () => {
    this.setState({ isLoading: true });
    const adminHeader = new Headers();
    adminHeader.append("token", this.state.userToken);
    const { adminDOB, adminEmail, adminGender, adminMobile, adminName } =
      this.state.adminFormData;
    const formData = new FormData();
    formData.append("admins[full_name]", adminName);
    formData.append("admins[gender]", this.setGenderUpdate(adminGender));
    formData.append("admins[date_of_birth]", adminDOB);
    formData.append("admins[email]", adminEmail);
    formData.append("admins[full_phone_number]", countryCode + adminMobile);
    formData.append(
      "admins[profile_icon]",
      this.state.selectedFile,
      this.state.selectedFileName
    );
    formData.append("role_name", "Super Admin");

    const reqMsgAdmin = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.addAdminAPICallId = reqMsgAdmin.messageId;

    reqMsgAdmin.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addStudentApiEndPoint
    );
    reqMsgAdmin.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      adminHeader
    );
    reqMsgAdmin.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    reqMsgAdmin.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndPoint
    );

    runEngine.sendMessage(reqMsgAdmin.id, reqMsgAdmin);
  };

  setGender(genderType:string){
   let neGenderType = Number(genderType)
    
    switch (neGenderType) {
      case 0:
        return "Male"
      case 1:
        return "Female"
      case 2:
        return "Other"
      default:
          return  genderType
    }
  }

  setGenderUpdate(genderType:string){
     switch (genderType) {
      case "Male":
        return "0"; 
      case "Female":
        return "1"; 
      case "Other":
        return "2";
      default:
        return "";
     }
   }

  handleCloseDeleteAdminModal=()=>{
    this.setState({ openDeleteAdminModal: !this.state.openDeleteAdminModal});
  }

  adminBulkDelete = () => {
    this.setState({ isLoading: true });
    const adminHeader = new Headers();
    adminHeader.append("token", this.state.userToken);
    const formData = new FormData();
    let adminData = this.state.adminData;
    let idsArray= [];
    for (const element of adminData) {
      if (element.isChecked) {
        idsArray.push(element.id)
      }
    }
    const idsString = idsArray.join(',');
    
    formData.append("ids", `[${idsString}]`);

    const reqMsgAdmin = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.bulkDeleteAdminAPICallId = reqMsgAdmin.messageId;

    reqMsgAdmin.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bulkDeleteAdmin
    );
    reqMsgAdmin.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      adminHeader
    );
    reqMsgAdmin.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    reqMsgAdmin.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );

    runEngine.sendMessage(reqMsgAdmin.id, reqMsgAdmin);
  };

  bulkDeleteAdminApiResponse(responseJson: {errors:string[], error: string, message: string}) {
    if(responseJson.errors && responseJson.errors.length > 0 && Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } 
    if(responseJson.error){
      this.setState({
        isLoading: false,
        isAlert: true,
        alertType: "error",
        alertMsg: responseJson.error,
        openDeleteAdminModal: false,
        isAdminDelete: false
      })
    }else{
      this.setState({
        isAlert: true,
        alertMsg: responseJson.message,
        alertType: "success",
        isLoading: false,
        openDeleteAdminModal: false,
        isAdminDelete: false
      });
      this.getAdminData(
        this.state.adminPageNo,
        this.state.adminPerPage,
        this.state.userToken,
        this.state.searchText,
        this.state.columnSort
      );
    }
  }
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
