export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const deleteIcon = require("../assets/delete.png");
export const deleteIcone = require("../assets/deleteIcone.png");
export const Delete=require("../assets/delete.png")
export const exportIcone = require("../assets/exportIcone.png");
export const editIcone = require("../assets/editIcone.png");
export const searchIcone = require("../assets/searchIcone.png");
export const addIcone = require("../assets/addIcone.png");
export const downIcone = require("../assets/downArrow.png");
export const notificationIcone = require("../assets/notificationIcone.png");
export const addMore = require("../assets/addMore.png");
export const lng  = require("../assets/group.png");
export const backArrow =require("../assets/back.png")
export const cross = require("../assets/cross.png")
export const forwardArrow = require("../assets/130995058nJqfLg04s3ZTS9fiQmtLBZ.svg")
export const OvalBlue = require("../../catalogue/assets/BlueShape.svg");
export const noResultFound=require("../assets/noResultFound.svg");
