// Customizable Area Start
import React, { CSSProperties } from "react";
import {
  Box,
  Button,
  styled,
  CardContent,
  Chip,
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
  DialogTitle, 
  DialogContent,  
  DialogActions,
  Typography,
  InputAdornment,
  TextField
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from "@material-ui/core/CircularProgress";
import { MdOutlineWindow } from "react-icons/md";
import { TbLayoutList } from "react-icons/tb";
import { FaRegTrashAlt, FaStar } from "react-icons/fa";
import { FaCircle, FaCircleCheck, FaCirclePlay, FaHeart } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { Close as CloseIcon ,Check as CheckIcon} from '@material-ui/icons';
import CircleIcon from "@material-ui/icons/FiberManualRecord";
import AddIcon from "@material-ui/icons/Add";
import { MdCurrencyRupee } from "react-icons/md";
import IconButton from "@material-ui/core/IconButton";
import { IoChevronDown, IoFilterOutline, IoLanguageSharp } from "react-icons/io5";
import { PiProjectorScreenChart } from "react-icons/pi";
import WishlistController, {
  Props,
  WishlistData,
  configJSON
} from "./WishlistController.web";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import {
  study,
  playIcon,
  star,
  bg,
  chapter,
  lecture,
  time,
  dashedLine
} from "./assets";
import FooterWeb from "../../../components/src/Footer.web";
import HeaderWeb from "../../../components/src/Header.web";
import { CustomDialog, getStudentCount, HeartIcon } from "../../landingpage/src/LandingPage.web"; 
import Skeleton from "@material-ui/lab/Skeleton";
import { forwardArrow } from "../../categoriessubcategories/src/assets";
import HelpCenterHeaderWeb from "../../../components/src/HelpCenterHeader.web";
import { LiaRupeeSignSolid } from "react-icons/lia";
import {
  BundleCoursesAttributes,
  LaunchedCourses,
  LaunchedCoursesAttributes,
  getNewLaunchCoursesWeb,
  CardType,
  BundleCourses,
  getBundleCoursesWebCard
} from "../../landingpage/src/LandingPage.web";
import { coursesIcon } from "../../landingpage/src/assets";
import { board } from "../../catalogue/src/assets";

const CartButton = styled(Button)(() => ({
  padding: "10px 26px",
  borderRadius: "27px",
  minHeight: "5%",
  boxShadow: "unset",
  height: 40,
  alignSelf:"center",
  '& .MuiButton-label':{
    fontFamily: 'Inter',
    textWrap: 'nowrap'
  },
  "& .MuiButton-startIcon": {
    marginLeft: 0,
    marginRight: 4
  }
}));
const WishlistHeaderBox = styled(Box)(() => ({
  '& .headerContainer': {
    maxWidth: 1440,
    "@media (min-width: 1366px) and (max-width: 1440px)": {
      maxWidth: 1310
    },
    "@media (min-width: 1366px) and (max-width: 1400px)": {
      maxWidth: 1250
    },
    "@media (min-width: 1226px) and (max-width: 1365px)": {
      maxWidth: 1200
    }
  },
  '& .discount-price-list': {
    fontSize: 32,
    fontWeight: 600,
    '@media (min-width: 1080px) and (max-width: 1420px)':{
      fontSize: 26
    }
  },
  '& .firstyear-price-list': {
    fontSize: 24,
    fontWeight: 600,
    '@media (min-width: 1080px) and (max-width: 1420px)':{
      fontSize: 20
    }
  },
  '& .headingTitle': {
    fontFamily: 'Inter',
    fontWeight: 700,
    color: "#FFFFFF",
    margin: "0px",
    '@media (min-width: 1366px) and (max-width: 1920px)': {
      fontSize: '43.63px',
    },
    '@media (min-width: 600px) and (max-width: 1280px)': {
      fontSize: '33.63px',
    },
  },
  '& .headerSubTitle': {
    fontFamily: 'Inter',
    color: "#FFFFFF",
    fontWeight: 400 as const,
    lineHeight: '31.79px',
    letterSpacing: '0.10943496972322464px',
    textAlign: 'left' as const,
    '@media (min-width: 1366px) and (max-width: 1920px)': {
      fontSize: '26.26px',
    },
    '@media (min-width: 600px) and (max-width: 1280px)': {
      fontSize: '16.26px',
    },
    marginTop:'15px'
  },
  '& .wishlistCountItem': {
    width: 119,
    height: 37,
    borderRadius: 100,
    background: "#FCB910",
    color: "#fff",
    fontFamily: 'Inter',
    fontSize: 16
  },
  '& .clear_all_btn': {
    height: 60,
    borderRadius: 33,
    background: "white",
    boxShadow: "0px 2px 6px 0px #00000046",
    margin: "auto",
    color: "red",
    textTransform: 'none',
    width: 179,
    '& .MuiButton-label': {
      fontFamily: 'Inter',
      fontSize: '21.63px',
      fontWeight: 400,
      lineHeight: "26.18px",
      letterSpacing: "-0.026518186554312706px",
      textAlign: 'left'
    }
  },
  '& .grid-course-price': {
    fontSize: 30,
    fontFamily: 'Inter',
    '@media (min-width: 1080px) and (max-width: 1420px)':{
      fontSize: 26
    }
  },
  '& .grid-price-first': {
    fontSize: 24,
    fontFamily: 'Inter',
    '@media (min-width: 1080px) and (max-width: 1420px)':{
      fontSize: 20
    }
  }
}))
const CourseCardHoveDetail = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: 2,
  marginBottom: "10px",
  "& .instructorLangName":{
    color: "#FFF",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.017px",
    '@media (min-width: 1366px) and (max-width: 1920px)': {
      fontSize: '20px',
    },
    '@media (min-width: 128px) and (max-width: 1366px)': {
      fontSize: '15px',
    },
    '@media (min-width: 600px) and (max-width: 1280px)': {
      fontSize: '15px',
    },
  }
})
const CourseHoverDiscountBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "6px",
  alignItems: "end",
  width: "100%",
  "& .courseListHoverDiscountPrice":{
    display: "inline-flex",
    columnGap: "8px",
    alignItems: "center",
    fontWeight: "bold",
    color: "white",
    '@media (min-width: 1366px) and (max-width: 1920px)': {
      fontSize: '31.93px',
    },
    '@media (min-width: 128px) and (max-width: 1366px)': {
      fontSize: '21.93px',
    },
    '@media (min-width: 600px) and (max-width: 1280px)': {
      fontSize: '21.93px',
    },
  }
})
const CourseListBox = styled(Box)({
  display: "inline-flex", width: "100%", height: "100%",
  "& .txtListAddedOn":{
    display: "inline-flex",
    width: "100%",
    justifyContent: "end",
    alignItems: "center",
    boxSizing: "border-box",
    paddingBottom: "10px",
    fontFamily:'Inter', 
    fontWeight: 400,
    gap:"5px",
    '@media (min-width: 1366px) and (max-width: 1920px)': {
      fontSize: '15.73px',
    },
    '@media (min-width: 128px) and (max-width: 1366px)': {
      fontSize: '15.73px',
    },
    '@media (min-width: 600px) and (max-width: 1280px)': {
      fontSize: '15.73px',
    },
  },
  "& .txtCourseListDiscountPrice":{
    display: "inline-flex",
    columnGap: "8px",
    alignItems: "center",
    fontFamily: 'Inter',
    fontWeight: 700,
    color:'#00BE10',
    '@media (min-width: 1366px) and (max-width: 1920px)': {
      fontSize: '31.93px',
    },
    '@media (min-width: 128px) and (max-width: 1366px)': {
      fontSize: '21.93px',
    },
    '@media (min-width: 600px) and (max-width: 1280px)': {
      fontSize: '21.93px',
    },
  }
})
const CourseCardBox = styled(Box)({
   display: "flex", flexDirection: "column", height: "100%",
   "& .course-short-desc": {
        color: "#212121",
        minHeight: "64px",
        maxHeight: "64px",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "-0.3px",
        fontWeight: 600,
        marginTop: "9px",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        textOverflow: "ellipsis",
        WebkitLineClamp: 3,
        overflow: "hidden",
        wordBreak: "break-word"
    },
   "& .txtAddedOn":{display:"flex",flexWrap:"wrap",gap:"5px",
    fontFamily:'Inter', 
    fontWeight: 400,
    '@media (min-width: 1366px) and (max-width: 1920px)': {
      fontSize: '17.73px',
    },
    '@media (min-width: 128px) and (max-width: 1366px)': {
      fontSize: '15.73px',
    },
    '@media (min-width: 600px) and (max-width: 1280px)': {
      fontSize: '15.73px',
    },
   },
  "& .txtDiscountPrice": {
    fontFamily: 'Inter',
    fontWeight: 700,
    display: "inline-flex",
    columnGap: "10px",
    alignItems: "center",
    marginRight:'10px',
    color:'#00BE10',
    '@media (min-width: 1366px) and (max-width: 1920px)': {
      fontSize: '31.93px',
    },
    '@media (min-width: 128px) and (max-width: 1366px)': {
      fontSize: '21.93px',
    },
    '@media (min-width: 600px) and (max-width: 1280px)': {
      fontSize: '21.93px',
    },
  }
})
const SearchBox = styled(Box)(() => ({
  width: '100%',
  height: 44,
  "& .searchIconBtn": {
    width: 40,
    height: '40px',
    background: "linear-gradient(180deg, #FFF54B 0%, #F4C952 100%)",
    boxShadow: '0px 1px 3px 0px #0000001E inset',
    borderRadius: 27,
    color: '#212121',
  },
  "& .searchIcon": {
    fontSize: '1rem'
  },
  '& .searchInput': {
    marginBottom: theme.spacing(2),
    width: '40%',
    color: "#000",
    '& input::placeholder': {
      color: "#000000 !important",
      fontFamily: 'Inter',
      fontWeight: 400,
      opacity: 1,
      marginRight: 8,
      '@media (min-width: 1366px) and (max-width: 1920px)': {
        fontSize: '24.26px',
      },
      '@media (min-width: 600px) and (max-width: 1280px)': {
        fontSize: '18.26px',
      },
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderRadius: 32,
      border: "0.5px solid #FFA235",
      ':hover': {
        borderRadius: 32,
        border: "0.5px solid #FFA235",
      }
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 32,
      border: "0.5px solid #FFA235"
    },
    '& .MuiOutlinedInput-adornedEnd': {
      padding: 0,
      paddingLeft: 8
    }
  },
}))
const webStylesLocal: Record<string, CSSProperties> = {
  leftRightLable: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
    marginBottom: "10px"
  },
  leftRightLables: {
    marginBottom: "10px"
  },
  aboutCourseLeftRight: {
    display: "flex",
    gap: "10px",
    color: "white",
    padding: "0 16px 16px 16px",
    justifyContent: "space-between"
  },
  instructorLang: {
    color: "#FFF",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.011px"
  },
  instructorLangName: {
    color: "#FFF",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.017px"
  },
  chapterLectureTimeHead: {
    display: "flex",
    columnGap: "7.5px"
  },
  chapterLectureTimeText: {
    color: "#FFF",
    fontStyle: "normal",
    lineHeight: "normal",
    marginLeft: 3
  },
  aboutCourseRight: {
    rowGap: "4px",
    display: 'flex',
    flexDirection: 'column'
  },
  fullDesc: {
    margin: "12px 0",
    padding: 0,
    color: " #FFF",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.151px",
    fontSize: 18,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    WebkitLineClamp: 3,
    overflow: "hidden"
  },
  courseShortDescHover: {
    color: "#FFF",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.3px",
    fontWeight: 600,
    marginTop: "9px",
    marginBottom: "9px",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    WebkitLineClamp: 2,
    overflow: "hidden",
    wordBreak: "break-word"
  },
  aboutCourse2: {
    marginTop: "10px"
  },
  courseName: {
    border: "1px solid white",
    background: "white",
    color: "#1220C1",
    width: "max-content",
    borderRadius: "26.85px",
    padding: "5px 7px",
    height: "min-content",
  },
  courseNameTop: {
    display: "flex",
    justifyContent: "space-between"
  },
  bundleCourseMainLayout: {
    width: "100%",
    margin: "0 auto",
  },
  addToCart: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "0px 16px",
    boxSizing: "border-box",
    margin: "auto"
  },
  addToCartList: {
    display: "flex",
    width: "20%",
    padding: "16px",
    boxSizing: "border-box",
    margin: "auto",
    alignItems: "end",
    justifyContent: "end"
  },
  instructorNameMain: {
    width: "100%",
    padding: 7,
    background: "#0E599D",
    marginBottom: "6px",
    display: "flex",
    justifyContent: "flex-start"
  },
  aboutCourse: {
    display: "flex",
    columnGap: "11px",
    marginTop: "14.3px",
    justifyContent: "space-between",
    width: "100%"
  },
  aboutCourseList: {
    display: "inline-flex",
    gap: "25px",
    width: "100%",
    alignItems: "center",
    justifyContent:"space-between"
  },
  adminLayout: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    alignItems: "center",
    padding: "0 12px",
    boxSizing: "border-box"
  },
  listItem: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
    borderBottom: "1px solid lightgrey",
    boxSizing: "border-box",
    padding: "1rem 0",
    alignItems: "center"
  },
  listItemText: {
    display: "flex",
    flexDirection: "column",
    width: "25%"
  },
  headerContainer: {
    display: "flex",
    color: "white",
    position: "relative",
    width: "100%",
  },
  headerImage: {
    maxWidth: 300,
    position: "absolute",
    top: "-15px",
    right: 0
  },
   emptyWishlist : {
    display:"flex",
    fontSize: '26px',
    color: '#555',
    padding: '20px',
    textAlign: 'center',
    justifyContent:"center",
    alignItems:"center",
    fontWeight:"bold"
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: '30px',
    margin: 'auto',
    border: '1px solid #FFA235',
    background: '#f2f2f23b',
    boxSizing: 'border-box' as const,
    height: 44,
  },
  input: {
    flex: 1,
    marginLeft: 4,
    color: 'black',
    width: '90%'
  },
  iconButton: {
    padding: 10,
    background: 'linear-gradient(180deg,#fff54b,#f4c952)',
    fontSize: '0.55rem',
    margin: 3,
  },
  searchButton: {
    background: 'linear-gradient(180deg,#fff54b,#f4c952)',
    color: "#fff",
    marginRight: 12,
    padding: "2px 10px",
    textTransform: "none" as const,
    cursor: 'pointer'
  },
  searchBox: {
   width:"30%"
  },
  grid_card:{
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(23%, 1fr))',
    gap: "16px",
    width: "100%"
  },
  list_card: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  }
};

let theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "'Inter', monospace"
    },
    body1: {
      fontFamily: "'Inter', monospace"
    }
  }
});
theme = responsiveFontSizes(theme);

export default class Wishlist extends WishlistController {
  constructor(props: Props) {
    super(props);
    this.closemodal = this.closemodal.bind(this);
  }

  closemodal() {
    this.oncloseAlert();
  }

  dateToText(date: string) {
    const toDate = new Date(date);

    const day = String(toDate.getDate()).padStart(2, "0");
    const month = toDate
      .toLocaleString("en-US", { month: "short" })
      .toUpperCase();
    const year = toDate.getFullYear();

    return `${day} ${month}, ${year}`;
  }

  stringToBoolean(value: string): boolean {
    return value?.toLowerCase() === "true";
  }

  courseList(item: any, index: number) {
    const { attributes } = item,
      value = attributes.courses? attributes.courses.data.attributes : attributes.bundle_course.data.attributes,
      isPurchasedOrCart = value.is_purchased || value.is_cart, isPurchased = value.is_purchased;
        return (
          <Link to={item?.attributes?.courses?  `/course/${item?.attributes?.courses?.data?.id}` : `/BundleCourseDetails/${item?.attributes?.bundle_course?.data?.id}`} className="card_list wishlist-card">
            <CourseListBox>
              <CardContent style={{ width: "20%" }}>
                <div style={{ position: "relative", display: "flex", width: "100%", height: "100%" }}>
                  <img
                    src={value.course_thumbnail || value.bundle_course_thumbnail}
                    style={{ borderRadius: "10.61px", height: "100%", width: "100%" }}
                    alt="Launched Course"
                    height={180}
                    width={"100%"}
                  />
                  <img
                    style={{
                      position: "absolute",
                      transform: "translate(-50%, -50%)",
                      top: "50%",
                      left: "50%"
                    }}
                    src={playIcon}
                    alt="Launched Course"
                    width={39}
                    height={39}
                  />
                </div>
              </CardContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "60%",
                  padding: "16px"
                }}
              >
                <div style={webStylesLocal.aboutCourseList}>
                  <div
                    className="course-cat-name"
                    style={{
                      borderColor: value?.course_child_category?.color || 'black',
                      padding: 0,
                      maxWidth:"fit-content"
                    }}
                  >
                    <CircleIcon
                      fontSize="inherit"
                      htmlColor={value?.course_child_category?.color || 'black'}
                    />
                    <Typography 
                        variant="body2" 
                        component="strong" 
                        className="course-cat-name-text"
                        style={{overflow:"hidden"}}
                        >
                        {value?.course_child_category?.name || value?.course_category?.name}
                    </Typography>
                  </div>
                  {this.getRatingFlag(value?.average_rating?.Average_rating || value.rating) ? 
                  <div
                    style={{
                      display: "flex",
                      textAlign: "center",
                      columnGap: "8px",
                      alignItems: "center"
                    }}
                  >
                    <img
                      alt="rate"
                      src={star}
                      height={12}
                      width={12}
                    />
                    <Typography className="student-count-rating" variant="body2" component="span">
                      <Typography style={{ color: "black" }} variant="body2" component="strong">
                        {value?.average_rating?.Average_rating || value?.rating}
                      </Typography>{" "}
                      | {getStudentCount(value.student_count)} Students
                    </Typography>
                  </div>
                  : 
                  <div
                    style={{
                      display: "flex",
                      textAlign: "center",
                      columnGap: "8px",
                      alignItems: "center"
                    }}
                  >
                    <Typography className="student-count-rating" variant="body2" component="span">{getStudentCount(value.student_count)} Students</Typography>
                  </div>}
                  <div>
                    <div
                      className="instructor-name"
                      style={{
                        color: "#4C8491",
                        alignItems: "center",
                        gap: 5,
                        padding: 0
                      }}
                    >
                      <PiProjectorScreenChart
                        fontSize={"inherit"}
                        color="inherit"
                      />
                      <Typography component="span">Instructor : {value?.instructor_name || value?.add_course[0]?.instructor_name.name}</Typography>
                    </div>
                  </div>
                </div>
                <Typography variant="h6" component="h3" className="course-short-desc">{value?.course_name||value?.bundle_name}</Typography>
              </div>
              <div style={webStylesLocal.addToCartList}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px",
                    alignItems: "end",
                    width: "100%"
                  }}
                >
                  <Typography
                    className="txtCourseListDiscountPrice"
                  >
                    <MdCurrencyRupee color="black" fontSize="inherit" />
                    {value?.discounted_price}/-
                  </Typography>
                  {value?.first_year_price && (
                    <Typography
                      style={{
                        display: "inline-flex",
                        columnGap: "10px",
                        alignItems: "center"
                      }}
                      className="course-price-discount firstyear-price-list"
                      variant="body1"
                    >
                      <MdCurrencyRupee color="inherit" fontSize="inherit" />
                      {value?.first_year_price}/-
                    </Typography>
                  )}
                  <div
                    style={{
                      display: "inline-flex",
                      width: "100%",
                      alignItems: "center",
                      gap: "10px",
                      justifyContent: "end"
                    }}
                  >
                    <IconButton
                      style={{
                        background: value?.course_child_category?.color || 'black',
                        padding: 5,
                        fontSize: 16,
                        borderRadius: 15,
                        color: "white"
                      }}
                      data-test-id="removeFromWishlistTestId"
                      onClick={() =>
                        this.removeFromWishlist(
                          item?.attributes?.courses?.data?.id ||item?.attributes?.bundle_course?.data?.id,
                          index
                        )
                      }
                    >
                      <FaRegTrashAlt />
                    </IconButton>
                    <CartButton
                      variant="contained"
                      className={
                        isPurchasedOrCart
                          ? "added-cart-btn added-cart-text"
                          : "add-cart-button add-cart-text"
                      }
                      data-test-id="addToCartTestId"
                      onClick={(e) =>
                         {
                          e.stopPropagation();
                          e.preventDefault();
                          !isPurchasedOrCart && this.handleOpenCourseValidityPopup(isPurchasedOrCart, item, "trendingCourses", index);
                        }
                      }
                    >
                      <Typography variant="body2" style={{ display: "inline-flex", alignItems: "center", gap: 3 }} component="strong">
                        {isPurchasedOrCart ?  <FaCircleCheck fontSize="inherit" color="inherit" /> : <AddIcon color="inherit" fontSize="inherit" className="MuiTypography-caption" />}
                        <Typography variant="body2" component="strong">
                          {isPurchased ? "Purchased" : isPurchasedOrCart ?
                            "Added" :
                            "Add to Cart"
                          }
                        </Typography>
                      </Typography>
                    </CartButton>
                  </div>
                  <div>
                    <Typography component="span" className="txtListAddedOn">
                      ADDED ON <strong>{this.dateToText(attributes.added_on)}</strong>
                    </Typography>
                  </div>
                </div>
              </div>
            </CourseListBox>
            {/* CARD HOVER */}
            <div
              style={{ display: "inline-flex", width: "100%", height: "100%" }}
              className="card-content active"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "80%",
                  padding: "16px",
                  justifyContent: "space-between"
                }}
              >
                <div style={{...webStylesLocal.aboutCourseList,justifyContent:"flex-start"}}>
                  <Typography style={webStylesLocal.courseName} variant="body1" component="strong">
                    {value?.course_child_category?.name || value?.add_course[0]?.course_child_category?.name}
                  </Typography>
                  <Typography
                    variant="h6" component="h3"
                    style={{
                      ...webStylesLocal.courseShortDescHover,
                      margin: 0,
                    }}
                  >
                    {value?.course_name || value?.bundle_name}
                  </Typography>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <div style={{ ...webStylesLocal.aboutCourse2, margin: 0 }}>
                    <Typography
                      className="description-wrapper"
                      variant="subtitle1" 
                      component="strong"
                      style={{
                        ...webStylesLocal.fullDesc,
                         width: '100%',
                         overflow: 'hidden',
                         textOverflow: "ellipsis",
                         whiteSpace: 'nowrap',
                        height: 70,
                      }}
                      dangerouslySetInnerHTML={{
                        __html: `${value?.short_description || value?.description}`
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "inline-flex",
                      gap: "0.9em",
                      alignItems: "center",
                    }}
                  >
                    <div style={webStylesLocal.chapterLectureTimeHead}>
                      <img src={chapter} style={{ filter: "invert(100%)", width: 24, height: 24  }} />
                      <Typography component="span" style={webStylesLocal.chapterLectureTimeText}>
                        {value?.total_content} Chapters
                      </Typography>
                    </div>
                    <div style={webStylesLocal.chapterLectureTimeHead}>
                      <img src={lecture} style={{ filter: "invert(100%)", width: 24, height: 24  }} />
                      <Typography component="span" style={webStylesLocal.chapterLectureTimeText}>
                        {value?.course_class_count} Lectures
                      </Typography>
                    </div>
                    <div style={webStylesLocal.chapterLectureTimeHead}>
                      <img src={time} style={{ filter: "invert(100%)", width: 24, height: 24  }} />
                      <Typography component="span" style={webStylesLocal.chapterLectureTimeText}>
                        {this.formatNumber(value?.all_course_class_duration)} Minutes
                      </Typography>
                    </div>
                    {this.getRatingFlag(value?.average_rating?.Average_rating || value.rating) ?
                    <div
                      style={{
                        display: "flex",
                        textAlign: "center",
                        columnGap: "10.21px",
                        alignItems: "center"
                      }}
                    >
                      <img
                        alt="rate"
                        src={star}
                        height={12}
                        width={12}
                      />
                      <Typography
                        className="student-count-rating"
                        variant="body2"
                        component="span"
                        style={{ color: "#c7c7c7" }}
                      >
                        <Typography variant="body2" component="strong" style={{ color: "white" }}>
                          {value?.average_rating?.Average_rating || value?.rating}
                        </Typography>{" "}
                        | {getStudentCount(value?.student_count)} Students
                      </Typography>
                    </div>
                    : <div
                    style={{
                      display: "flex",
                      textAlign: "center",
                      columnGap: "10.21px",
                      alignItems: "center"
                    }}
                  >
                    <Typography
                      className="student-count-rating"
                      variant="body2"
                      component="span"
                      style={{ color: "#c7c7c7" }}
                    >{getStudentCount(value?.student_count)} Students
                    </Typography>
                  </div>}
                    <div>
                      <div
                        className="instructor-name"
                        style={{
                          color: "white",
                          alignItems: "center",
                          gap: 5,
                          padding: 0
                        }}
                      >
                        <PiProjectorScreenChart
                          fontSize={"inherit"}
                          color="inherit"
                        />
                        <Typography variant="subtitle2" component="span" style={{
                        width:'250px',
                        overflow: 'hidden',
                        textOverflow: "ellipsis",
                        whiteSpace: 'nowrap'}}>Instructor : {value?.instructor_name || value?.add_course[0]?.instructor_name?.name}</Typography>
                      </div>
                    </div>
                    <div
                      className="instructor-name"
                      style={{
                        color: "white",
                        alignItems: "center",
                        gap: 5,
                        padding: 0
                      }}
                    >
                      <IoLanguageSharp fontSize={"inherit"} color="inherit" />
                      <Typography component="span" style={webStylesLocal.instructorLang}>
                        Language {value?.language_name || value?.add_course[0]?.language_name}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div style={webStylesLocal.addToCartList}>
                <CourseHoverDiscountBox>
                  <Typography
                    className="courseListHoverDiscountPrice"
                  >
                    <MdCurrencyRupee color="inherit" fontSize="inherit" />
                    {value?.discounted_price}/-
                  </Typography>
                  {value?.first_year_price && (
                    <Typography
                      style={{
                        display: "inline-flex",
                        columnGap: "10px",
                        alignItems: "center",
                        color: "#f2f2f2"
                      }}
                      className="course-price-discount firstyear-price-list"
                      variant="body1"
                    >
                      <MdCurrencyRupee color="inherit" fontSize="inherit" />
                      {value?.first_year_price}/-
                    </Typography>
                  )}
                  <div
                    style={{
                      display: "inline-flex",
                      width: "100%",
                      alignItems: "center",
                      gap: "10px",
                      justifyContent: "end"
                    }}
                  >
                    <IconButton
                      style={{
                        background: value?.course_child_category?.color || 'black',
                        padding: 5,
                        fontSize: 16,
                        borderRadius: 15,
                        color: "white"
                      }}
                      data-test-id="removeFromWishlistTestId"
                      onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          this.removeFromWishlist(
                            item?.attributes?.courses?.data?.id||item?.attributes?.bundle_course?.data?.id,
                            index
                          );
                        }
                      }
                    >
                      <FaRegTrashAlt />
                    </IconButton>
                    <CartButton
                      data-test-id="addToCartTestId"
                      variant="contained"
                      onClick={(e) =>
                        {
                          e.stopPropagation();
                          e.preventDefault();
                          !isPurchasedOrCart && this.handleOpenCourseValidityPopup(isPurchasedOrCart, item, "trendingCourses", index);
                        }
                      }
                      className={
                        "add-cart-btn__wishlist add-cart-btn__wishlist-text"
                      }
                    >
                      <Typography variant="body2" style={{ display: "inline-flex", alignItems: "center", gap: 3 }} component="strong">
                        {isPurchasedOrCart ? <FaCircleCheck color="inherit" fontSize="inherit" /> : <AddIcon color="inherit" fontSize="inherit" className="MuiTypography-caption" />}
                        <Typography variant="body2" component="strong">
                          {isPurchased ? "Purchased" : isPurchasedOrCart ?
                            "Added" :
                            "Add to Cart"
                          }
                        </Typography>
                      </Typography>
                    </CartButton>
                  </div>
                  <div
                    style={{
                      display: "inline-flex",
                      width: "100%",
                      justifyContent: "end",
                      alignItems: "center",
                      boxSizing: "border-box",
                      paddingBottom: "10px",
                      color: "white"
                    }}
                  >
                    <Typography component="span">
                      {configJSON.addedOnText} <strong>{this.dateToText(attributes.added_on)}</strong>
                    </Typography>
                  </div>
                </CourseHoverDiscountBox>
              </div>
            </div>
          </Link>
        );
      
  }

  formatNumber(num: number) {
    return Number.isInteger(num) ? num : parseFloat(num?.toFixed(2));
  };

  courseCard(item: any, index: number) {
    const { attributes } = item;
    if(attributes){
      
      const  value = attributes?.courses ? attributes?.courses?.data?.attributes : attributes?.bundle_course?.data?.attributes ;
      const  isPurchasedOrCart = value?.is_cart , isPurchased = value?.is_purchased;
    return (
      <>
      {item?.attributes?.courses && 
      <Link
        to={`/course/${item?.attributes?.courses?.data?.id}`}
        className="card wishlist-card"
        data-test-id="courseCardTestId"
      >
        <CourseCardBox>
          <CardContent>
            <div>
              <div
                style={{
                  display: "inline-flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  boxSizing: "border-box",
                  paddingBottom: "10px"
                }}
              >
                <Typography component="span" className="txtAddedOn">
                  ADDED ON <strong>{this.dateToText(attributes.added_on)}</strong>
                </Typography>
                <IconButton
                  style={{
                    background: item?.attributes?.courses? value?.course_child_category?.color: item?.attributes?.bundle_course?.course_child_category?.color,
                    padding: 5,
                    fontSize: 16,
                    borderRadius: "5px",
                    color: "white"
                  }}
                >
                  <FaRegTrashAlt />
                </IconButton>
              </div>
              <div style={{ position: "relative" }}>
                <img
                  src={item?.attributes?.courses? value?.course_thumbnail: item?.attributes?.bundle_course?.bundle_course_thumbnail}
                  style={{ borderRadius: "10.61px", height: 160 }}
                  alt="Launched Course"
                  height={160}
                  width={"100%"}
                />
                <img
                  style={{
                    position: "absolute",
                    transform: "translate(-50%, -50%)",
                    top: "50%",
                    left: "50%"
                  }}
                  src={playIcon}
                  alt="Launched Course"
                  width={39}
                  height={39}
                />
              </div>
            </div>
            <div style={webStylesLocal.aboutCourse}>
              <div
                className="course-cat-name"
                style={{ borderColor: value?.course_child_category?.color }}
              >
                <CircleIcon
                  fontSize="inherit"
                  htmlColor={value?.course_child_category?.color}
                />
                <Typography variant="body1"  className="course-cat-name-text">
                    {value?.course_child_category &&
                      value?.course_child_category?.name}
                </Typography>
              </div>
              {this.getRatingFlag(value.average_rating.Average_rating) ? 
              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  columnGap: "10.21px",
                  alignItems: "center",
                  width: "45%",
                  marginTop: '-6px'
                }}
              >
                <img
                  alt="rate"
                  src={star}
                  height={12}
                  width={12}
                />
                <Typography className="student-count-rating" variant="body2" component="span">
                  <Typography style={{ color: "black" }} variant="body2" component="strong">
                    {value?.average_rating?.Average_rating}
                  </Typography>{" "}
                  | {getStudentCount(value?.student_count)} Students
                </Typography>
              </div>
              : <div
              style={{
                display: "flex",
                textAlign: "center",
                columnGap: "10.21px",
                alignItems: "center",
                width: "45%",
                marginTop: '-6px'
              }}
            >
              <Typography className="student-count-rating" variant="body2" component="span">{getStudentCount(value?.student_count)} Students</Typography>
            </div>}
              {this.stringToBoolean(value?.is_wishlist) ? <FaHeart className="heartIconList heartIconList2" color="red" /> : <FaHeart className="heartIconList heartIconList2"
                color={"lightgrey"} />}
            </div>
            <Typography variant="h6" component="h3" className="course-short-desc">{value?.course_name}</Typography>
          </CardContent>
          <div
            style={{
              marginTop: "1px",
              justifyContent: "space-between"
            }}
          >
            <div
              style={{
                ...webStylesLocal.instructorNameMain,
                boxSizing: "border-box",
                background: value?.course_child_category?.color
              }}
            >
              <Typography className="instructor-name" variant="subtitle2" component="span" style={{textAlign:'start'}}>
                Instructor : {value?.instructor_name}
              </Typography>
            </div>
          </div>
          <div style={webStylesLocal.addToCart}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                columnGap: "10px"
              }}
            >
              <Typography
                className="txtDiscountPrice"
              >
                <MdCurrencyRupee color="black" fontSize="inherit" />
                {value?.discounted_price}/-
              </Typography>
            </div>
            <CartButton variant="contained"
            style={{borderRadius:"10px"}}
             className={
              isPurchasedOrCart || isPurchased ?
                "added-cart-btn added-cart-text" :
                "add-cart-button add-cart-text"
            }>
              <PurchasedText isPurchased={isPurchased} isPurchasedOrCart={isPurchasedOrCart} />
            </CartButton>
          </div>
        </CourseCardBox>
         {/* CARD HOVER */}
        <div className="card-content active">
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <CardContent style={{ flexGrow: 1 }}>
              <div style={webStylesLocal.courseNameTop}>
                <Typography style={webStylesLocal.courseName} variant="body1" component="strong">
                  {value?.course_child_category?.name}
                </Typography>
                <IconButton
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "26.81px",
                    width: "25.1px",
                    height: "25.1px",
                    backgroundColor: "#FFF",
                    fontSize: "12px"
                  }}
                  data-test-id="removeFromWishlistTestId"
                  onClick={(e) =>
                    {
                      e.stopPropagation();
                      e.preventDefault();
                      this.removeFromWishlist(
                      item.attributes.courses.data.id,
                      index
                    )
                  }
                  }
                >
                  <IconButton
                    style={{
                      background: value?.course_child_category?.color,
                      padding: 5,
                      fontSize: 16,
                      borderRadius: "5px",
                      color: "white"
                    }}
                  >
                    <FaRegTrashAlt />
                  </IconButton>
                </IconButton>
              </div>
              <div style={webStylesLocal.aboutCourse2}>
                <Typography variant="h6" component="h3" style={{ ...webStylesLocal.courseShortDescHover }}>
                  {value?.course_name}
                </Typography>
                <Typography
                  className="description-wrapper"
                  variant="subtitle1" component="strong" 
                  style={{
                    ...webStylesLocal.fullDesc,
                    height: 90
                  }}
                  dangerouslySetInnerHTML={{
                    __html: `${value?.short_description}`
                  }}
                />
              </div>
            </CardContent>
            <div
              style={{
                justifyContent: "space-between",
                marginBottom: "20px"
              }}
            >
              <div style={webStylesLocal.aboutCourseLeftRight}>
                <div style={{ ...webStylesLocal.aboutCourseLeft }}>
                  {value.average_rating.Average_rating > 0 ?
                  <Typography component="span" style={{ display: "flex", marginBottom: "10.55px" }}>
                    <img src={star} width={12} height={12} />
                    <Typography variant="body2" component="span" style={webStylesLocal.chapterLectureTimeText}>{value?.average_rating?.Average_rating} | {getStudentCount(value?.student_count)} Students</Typography>
                  </Typography>
                  : <Typography component="span" style={{ display: "flex", marginBottom: "10.55px",  color: "#FFF",
                    marginLeft: 3,
                    fontSize: '14px' }}>{getStudentCount(value.student_count)} Students</Typography>}
                 <CourseCardHoveDetail>
                    <Typography variant="subtitle2" component="span" style={webStylesLocal.instructorLang}>INSTRUCTOR</Typography>
                    <Typography variant="h6" component="span" 
                    style={{
                      color: "#FFF", fontStyle: "normal", letterSpacing: "-0.017px",
                      fontSize: '14.11px',
                      lineHeight: '17.07px',
                      width: 112,
                      overflow: 'hidden',
                      textOverflow: "ellipsis",
                      whiteSpace: 'nowrap' 
                    }} 
                    >{value.instructor_name}</Typography>
                  </CourseCardHoveDetail>
                  <CourseCardHoveDetail>
                    <Typography variant="subtitle2" component="span" style={webStylesLocal.instructorLang}>LANGUAGE</Typography>
                    <Typography variant="h6" component="span" className="instructorLangName">{value.language_name}</Typography>
                  </CourseCardHoveDetail>
                </div>
                <div className="divider">
                  <div className="line"></div>
                </div>
                <div style={webStylesLocal.aboutCourseRight}>
                  <div style={webStylesLocal.leftRightLables}>
                    <div style={webStylesLocal.chapterLectureTimeHead}>
                      <img src={chapter} style={{ filter: "invert(100%)", width: 24, height: 24 }} />
                      <Typography component="span" style={webStylesLocal.chapterLectureTimeText}>
                        {value?.total_content} Chapters
                      </Typography>
                    </div>
                  </div>
                  <img style={{ marginBottom: "5px" }} src={dashedLine} />
                  <div style={webStylesLocal.leftRightLables}>
                    <div style={webStylesLocal.chapterLectureTimeHead}>
                      <img src={lecture} style={{ filter: "invert(100%)", width: 24, height: 24 }} />
                      <Typography component="span" style={webStylesLocal.chapterLectureTimeText}>
                        {value?.course_class_count} Lectures
                      </Typography>
                    </div>
                  </div>
                  <img style={{ marginBottom: "6px" }} src={dashedLine} />
                  <div style={webStylesLocal.leftRightLables}>
                    <div style={webStylesLocal.chapterLectureTimeHead}>
                      <img src={time} style={{ filter: "invert(100%)", width: 24, height: 24 }} />
                      <Typography component="span" style={webStylesLocal.chapterLectureTimeText}>
                        {this.formatNumber(value?.all_course_class_duration)} Minutes
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div style={webStylesLocal.addToCart}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    columnGap: "10px"
                  }}
                >
                  <Typography
                    style={{
                      display: "inline-flex",
                      columnGap: "10px",
                      alignItems: "center",
                      fontWeight: "bold",
                      fontSize: value?.discounted_price && value?.discounted_price.toString().length > 2 ? '24px' : ''
                    }}
                    className="course-price grid-course-price"
                    variant="h5" component="h3"
                  >
                    <MdCurrencyRupee color="white" fontSize="inherit" />
                    {value?.discounted_price}/-
                  </Typography>
                  {value?.first_year_price && (
                    <Typography
                      style={{
                        display: "inline-flex",
                        columnGap: "10px",
                        alignItems: "center",
                        color: "lightgrey"
                      }}
                      className="course-price-discount grid-price-first"
                      variant="body1"
                    >
                      <MdCurrencyRupee color="white" fontSize="inherit" />
                      {value.first_year_price}/-
                    </Typography>
                  )}
                </div>
                <CartButton variant="contained"
            style={{borderRadius:"10px"}}
            data-test-id="addToCartTestId"
            onClick={(e) =>
              {
                e.stopPropagation();
                e.preventDefault();
                !isPurchasedOrCart && this.handleOpenCourseValidityPopup(isPurchasedOrCart, item, "trendingCourses", index);
              }
            }
             className={
              isPurchasedOrCart ?
                "added-cart-btn added-cart-text" :
                "add-cart-button add-cart-text"
            }>
              <Typography variant="body2" style={{ display: "inline-flex", alignItems: "center", gap: 3 }} component="strong">
                {isPurchasedOrCart ? <FaCircleCheck fontSize="inherit" color="inherit" /> : <AddIcon color="inherit" fontSize="inherit" className="MuiTypography-caption" />}
                <Typography variant="body2" component="strong">
                  {isPurchased ? "Purchased" : isPurchasedOrCart ?
                    "Added" :
                    "Add to Cart"
                  }
                </Typography>
              </Typography>
            </CartButton>
              </div>
            </div>
          </div>
        </div>
      </Link>
      }
      {item?.attributes?.bundle_course && 
      <div 
      data-test-id="BundleCoursecard"
      className="card" style={{ maxHeight: "34em"}}>
          <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <CardContent style={{ padding: '0' }}>
              <div
                style={{
                  display: "inline-flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  boxSizing: "border-box",
                  padding: "16px 16px 0px 16px"
                }}
              >
                <Typography component="span" style={{display:"flex",flexWrap:"wrap",gap:"2px"}}>
                  ADDED ON <strong>{this.dateToText(attributes.added_on)}</strong>
                </Typography>
                <IconButton
                  style={{
                    background: "rgb(107, 79, 79)",
                    padding: 5,
                    fontSize: 16,
                    borderRadius: "5px",
                    color: "white"
                  }}
                  onClick={(e) =>
                    {
                      e.stopPropagation();
                      e.preventDefault();
                      this.removeFromWishlist(
                      item.attributes.bundle_course.data.id,
                      index
                    )
                  }}
                >
                  <FaRegTrashAlt />
                </IconButton>
              </div>
              <Link to={`/BundleCourseDetails/${item?.attributes?.bundle_course?.data?.id}`} className="card wishlist-card">
                  <div style={{ display: "grid", width: "100%", padding: "16px 16px 0 16px", boxSizing: "border-box" }}>
                      <img src={value.bundle_course_thumbnail || value.course_thumbnail} className="bundle-course-image" alt="Course" style={{ height: '206px', borderRadius: 12, width: "100%" }} />
                  </div>
                  <div
                      style={{ width: "100%", background: "#4C8491" }}
                  >
                      <div style={webStyle.aboutBundleCourse}>
                          <div style={{
                              display: "flex",
                              textAlign: "center",
                              columnGap: "10.21px",
                              alignItems: "center",
                          }}>
                              <img
                                  alt="rate"
                                  src={star}
                                  height={14}
                                  width={14}
                              />
                              <Typography component="span" className="student-count-rating" style={{ color: "white" }}>
                                  {value.rating} | {getStudentCount(value.total_student)} Students
                              </Typography>
                          </div>
                          <div style={webStyle.chapterLectureTimeHead}>
                              <img src={coursesIcon} width={18} height={18} />
                              <Typography component="span" style={webStyle.chapterLectureTimeText}>
                                  {value.course_count} Courses
                              </Typography>
                          </div>
                          <div>
                              <HeartIcon
                                  className="wishlistIconWrapperList wishlistIconWrapperList2"
                                  data-test-id="wishlistLaunchCourseTestId"
                                  style={{ width: 30, height: 30, padding: 6 }}
                                  onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                  }}
                              >
                                   <FaHeart className="heartIconList heartIconList2" style={{ fontSize: 24 }} data-test-id="heartFilledTestId" color="red" />
                              </HeartIcon>
                          </div>
                      </div>
                  </div>
                  <Typography variant="h5" component="h3" style={{
                    padding: "0 16px 16 16px", width: '90%',
                    overflow: 'hidden',
                    textOverflow: "ellipsis",
                    whiteSpace: 'nowrap',
                    color: "#212121",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "-0.3px",
                    fontWeight: 600,
                    marginTop: "9px",
                  }}>
                      {value.bundle_name}
                  </Typography>
                  </Link>
              </CardContent>
              <div style={webStyle.addToCartBundle}>
                  {this.showCoursePrice(value)}
                  <CartButton
                      variant="contained"
                      style={isPurchasedOrCart ? { cursor: "default", height: 44,borderRadius:'8px' } : { height: 44 ,borderRadius:'8px'}}
                      onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          this.handleOpenCourseValidityPopup(isPurchasedOrCart, item, "bundleCourses", index)
                      }}
                      className={
                          isPurchasedOrCart ?
                              "added-cart-btn added-cart-text" :
                              "add-cart-button add-cart-text"
                      }
                      data-test-id="addCartBundleCourseTestId"
                  >
                      <Typography variant="body2" style={{ display: "inline-flex", alignItems: "center", gap: 3 }} component="strong">
                          {isPurchasedOrCart ? <FaCircleCheck color="inherit" fontSize="inherit" /> : <AddIcon color="inherit" fontSize="inherit" className="MuiTypography-caption" />}
                          <Typography variant="body2" component="strong">
                              {this.cartText(isPurchased, isPurchasedOrCart)}
                          </Typography>
                      </Typography>
                  </CartButton>
              </div>
          </div>
      </div>
      }
      </>
    );
    }
  }
  showCoursePrice(value: LaunchedCoursesAttributes | BundleCoursesAttributes) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          columnGap: "10px"
        }}
      >
        <Typography
          style={{
            display: "inline-flex",
            columnGap: "10px",
            alignItems: "center",
            fontWeight: "bold"
          }}
          variant="h5"
          component="h3"
          className="course-price"
        >
          <MdCurrencyRupee color="inherit" fontSize="inherit" />
          {Math.round(value?.discounted_price || 0)}/-
        </Typography>
        {value?.first_year_price ? (
          <Typography
            style={{
              display: "inline-flex",
              columnGap: "10px",
              alignItems: "center"
            }}
            className="course-price-discount"
            variant="body1"
          >
            <MdCurrencyRupee color="inherit" fontSize="inherit" />
            {Math.round(value.first_year_price)}/-
          </Typography>
        ) : null}
      </div>
    );
  }
   cartText=(isPurchased: boolean, isPurchasedOrCart: boolean) =>{
    if (isPurchased) return "Purchased";
    if (isPurchasedOrCart) return "Added";
    return "Add to Cart";
}
    renderItemValidityDialog = () => {
        const {courseValidityDetails, selectedValidity, CourseValidityDialog} = this.state;
        if (!Object.keys(courseValidityDetails).length) return <></>;
        const details = courseValidityDetails?.attributes?.courses?.data || courseValidityDetails?.attributes?.bundle_course?.data ;
       const courseDescription = details?.attributes?.short_description || details?.attributes?.overview;
       const course_name = details?.attributes?.course_name || details?.attributes?.bundle_name
        return (<CustomDialog 
        className={'dialogContainer'} 
        open={CourseValidityDialog} 
        onClose={this.onClose} 
        style={{ padding: 24 }}>
        <DialogTitle disableTypography className={'dialogTitleRoot'}>
          <Typography data-test-id="courseValidityTitle" className={'dialogTitle'}>
            {configJSON.courseValidityTitle}
          </Typography>
          <IconButton className={'closeButton'} onClick={this.onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={'dialogContent'}>
          <Typography variant="subtitle1" className={'subTitleCourseValidity'}>
            {course_name}: {courseDescription}
          </Typography>
          <div className={'buttonGroup'}>
            <Button
              data-test-id="1_year"
              variant="outlined"
              color="primary"
              className={`${'button'} ${selectedValidity === 1 ? 'selected' : ''}`}
              onClick={() => this.handleSelectValidity(1)}
            >
              <span className={'buttonLabel'}>
                {selectedValidity === 1 && <CheckIcon className={'checkIcon'} />}
                {configJSON.validity1Year}
                <div style={{
                     position: 'absolute' as const, 
                     right: 0 , 
                     display: 'flex',
                     alignItems: 'center',
                     fontWeight: 600,
                      fontFamily: 'Inter',
                      fontSize: 24
                }}><LiaRupeeSignSolid className="rupeeIcon" /> {details?.attributes?.first_year_price}</div>
              </span>
            </Button>
            {details?.attributes?.second_year_price ?
              <Button
                data-test-id="2_year"
                variant="outlined"
                color="primary"
                className={`${'button'} ${selectedValidity === 2 ? 'selected' : ''}`}
                onClick={() => this.handleSelectValidity(2)}
              >
                {console.log("courseValidityDetails", courseValidityDetails)}
                <span className={'buttonLabel'}>
                  {configJSON.validity2Year}
                  {selectedValidity === 2 && <CheckIcon className={'checkIcon'} />}
                  <div style={{
                     position: 'absolute' as const, 
                     right: 0 , 
                     display: 'flex',
                     alignItems: 'center',
                     fontWeight: 600,
                      fontFamily: 'Inter',
                      fontSize: 24
                }}><LiaRupeeSignSolid className="rupeeIcon" /> {details?.attributes?.second_year_price}</div>
                </span>
              </Button>
              : null}
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between',padding: "16px 24px" }}>
          <Button className={'cancelButton'} onClick={this.onClose}>Cancel</Button>
          <Button 
          data-test-id="applyButton" 
          variant="contained" 
          className={'applyButton'} 
          onClick={() => 
          this.handleAddCart(
            false, 
            details.id, 
            details.type,
            "",
            selectedValidity
            )}>
            Apply
          </Button>
        </DialogActions>
      </CustomDialog>)
    }


  renderList() {
    const { classes } = this.props;
    const {search ,isSearchItem, filteredItems,wishlistList,currentPage} = this.state;
    let listData :any =[];
    if (search !== "" && filteredItems && isSearchItem) {
      listData = filteredItems
    }else {
      listData = wishlistList
    }
    
    return (
      <div style={webStylesLocal.bundleCourseMainLayout}>
        <WishlistHeaderBox data-test-id="wishlistHeaderBox" sx={webStylesLocal.adminLayout}>
          <Box className="headerContainer" style={webStylesLocal.headerContainer}>
            <div style={{ maxWidth: "875px", marginRight: "auto" }}>
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 20
                }}
              >
                <h1 className={"headingTitle"}>{configJSON.wishlistHeaderTitle}</h1>
                <Chip
                  style={{ color: "white", background: "#FCB910" }}
                  label={`${listData.length} ITEMS`}
                  data-test-id="itemsCounter"
                  className="wishlistCountItem"
                />
              </div>
              <Typography className={"headerSubTitle"}>
               {configJSON.wishlistHeaderSubtitle}
              </Typography>
            </div>
            <div style={webStylesLocal.headerImage}>
              <img
                src={study}
                alt=""
                width={300}
                height={80}
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </Box>
          <div
            style={{
              display: "inline-flex",
              width: "100%",
              justifyContent: "space-between",
              margin: '40px 0px 0px 0px',
              gap: "10px"
            }}
            className="headerContainer"
          >
            <SearchBox display={'flex'} justifyContent={'space-between'}>
              <TextField
                variant="outlined"
                data-test-id="searchInput"
                placeholder="Search with keywords..."
                className={'searchInput'}
                value={this.state.search}
                onChange={this.handleSearchValue}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    this.getWishlist()
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start" >
                      <IconButton className={"searchIconBtn"} onClick={()=>this.getWishlist()}>
                        <SearchIcon className={"searchIcon"} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </SearchBox>
           <div style={{display:"flex",gap: "10px"}}>
           <IconButton
              style={{
                backgroundColor:
                  this.state.listView === "card" ? "#00BE10" : "EEEFEF"
              }}
              data-test-id="tabView"
              onClick={() => this.changeDisplayListing("card")}
            >
              <MdOutlineWindow
                fontSize={"1.5rem"}
                color={this.state.listView === "card" ? "#FFF" : "#000"}
              />
            </IconButton>
            <IconButton
              style={{
                backgroundColor:
                  this.state.listView === "list" ? "#00BE10" : "EEEFEF"
              }}
              data-test-id="listIcon"
              onClick={() => this.changeDisplayListing("list")}
            >
              <TbLayoutList
                fontSize={"1.5rem"}
                color={this.state.listView === "list" ? "#FFF" : "#000"}
              />
            </IconButton>
          </div>
            
          </div>

          <div
            data-test-id="backgroundTestID"
            style={{
              width: "100%",
              background: `url(${bg})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              padding: "1.5rem 0",
              minHeight: 780,
            }}
            className="headerContainer"
          >
            <div
              style={
                this.state.listView === "card"
                  ? webStylesLocal.grid_card
                  : webStylesLocal.list_card
              }
            >
              {listData && listData.length > 0 && listData.slice(0,currentPage).map((item :any, index:any) => (
                <React.Fragment key={item.id}>
                  {this.state.listView === "card"
                    ? this.courseCard(item, index)
                    : this.courseList(item, index)}
                </React.Fragment>
              ))}
            </div>

            {!this.state.isLoading && wishlistList && wishlistList.length === 0 &&
             <div style={webStylesLocal.emptyWishlist}>
                <p>{configJSON.noItemInWishlistMsg}</p>
              </div>
            }
           {!this.state.isLoading && isSearchItem && filteredItems && filteredItems.length === 0 &&
             <div style={webStylesLocal.emptyWishlist}>
                <p>{configJSON.searchResultNotFoundMsg}</p>
              </div>
            }
           <Box display={'flex'} justifyContent={'center'} style={{ marginTop: 24 }}>
           <div style={{ padding: "1rem", paddingBottom: 0, marginLeft:"35%" ,marginRight:"35%", display:"flex",gap:"10px"}}>
              {this.state.isLoading ? (
                <CircularProgress
                  style={{ margin: "auto", color: "#2a9e6d" }}
                />
              ) : (
                <>
                  <Button
                    data-test-id="loadMoreButtonTestId"
                    variant="contained"
                    className="clear_all_btn"
                    style={{
                      background: "white",
                      width: 202,
                      color: "blue",
                      display: (this.state.loadMoreDisabled || listData.length <= 4)
                        ? "none"
                        : "inline-flex",
                      }}
                    startIcon={<IoChevronDown color="black" />}
                    disabled={
                      this.state.loadMoreDisabled || this.state.isLoading
                    }
                    onClick={() => this.loadMore()}
                  >
                    {configJSON.showMoreText}
                  </Button>

                  <Button
                    data-test-id="clearAllButtonTestId"
                    variant="contained"
                    className="clear_all_btn"
                    style={{
                      display: (this.state.wishlistList.length === 0 || listData.length === 0)
                        ? "none"
                        : "inline-flex"
                    }}
                    startIcon={<FaRegTrashAlt color="black" />}
                    disabled={
                      this.state.isLoading
                    }
                    onClick={() => this.removeAllFromWishlist()}
                  >
                    {configJSON.clearAll}
                  </Button>
                </>
              )}
           </div>
           </Box>
          </div>
        </WishlistHeaderBox>
      </div>
    );
  }

  render() {
    
    return (
      <ThemeProvider theme={theme}>
        {this.renderItemValidityDialog()}
        <NotificationAlertMessage
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          isOpen={this.state.isAlert}
        />
        <HeaderWeb id={""} navigation={this.props.navigation} />
        <main
          style={{
            overflowX: "scroll"
          }}
        >
          <div
            style={{
              background:
                "linear-gradient(180deg, rgba(60,116,129,1) 200px, rgba(255,255,255,1) 200px)",
              padding: "25px 0"
            }}
          >
            {this.renderList()}
          </div>
          <FooterWeb id="footer" navigation={this.props.navigation} />
        </main>
      </ThemeProvider>
    );
  }
}

const PurchasedText = ({ isPurchased, isPurchasedOrCart }: { isPurchased: boolean, isPurchasedOrCart: boolean }) => {
  return <Typography variant="body2" style={{ display: "inline-flex", alignItems: "center", gap: 3 }} component="strong">
      {isPurchased ? 
        <FaCircleCheck color="inherit" fontSize="inherit" /> : <AddIcon color="inherit" fontSize="inherit" className="MuiTypography-caption" />}
      <Typography variant="body2" component="strong" className="addcartText">
          {cartText(isPurchased, isPurchasedOrCart)}
      </Typography>
  </Typography>
}

function cartText(isPurchased: boolean, isPurchasedOrCart: boolean) {
  if (isPurchased) return "Purchased";
  if (isPurchasedOrCart) return "Added";
  return "Add to Cart";
}

export const webStyle = {
  studentReviews: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 50
    },
  studentImg: { 
      width: "92px", 
      height: "92px",
      marginTop: 30
   },
  studentName: {
      color: "#212121",
      fontSize: "14.724px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "75.826px",
      letterSpacing: "-0.028px",
  },
  studentReviewTop: {
      display: "flex",
      alignItems: "center",
      columnGap: "5px",
      height: 54
  },
  firstSecondMain: {
  },
  demoCourseMain: {
      display: "flex",
      marginBottom: "139px",
      columnGap: "5%",
      marginTop: "100px",
      rowGao: "30px"
  },
  sixthLayout: {
      width: "1358px",
      borderRadius: "27px",
      background: "#042D6E"
  },
  demoMidText: {
      color: "#212121",
      fontFamily: 'Inter',
      fontSize: " 56.724px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "75.826px",
      letterSpacing: "-0.07px",
  },
  studentReview: {
      // width: "27%",
      border: "1px solid rgba(0, 0, 0, 0.18)",
      borderRadius: "18.72px",
      background: "#FFF",
      boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.18)",
      display: "flex",
      columnGap: "15px",
      padding: "20px",
      margin: '0px 20px',
      gap: 20,
      alignItems: "center",
      minHeight: '150px'
  },
  demoTextTop: {
      color: "#212121",
      fontFamily: 'Inter',
      fontSize: "40.724px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "75.826px",
      letterSpacing: "-0.05px",
      marginTop: "44px"
  },
  studentReviewLayout: {
      display: "flex",
      columnGap: "20px",

  },
  aboutBundleCourse: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "10px",
      marginBottom: "10px",
      padding: "10px"
  },
  viewDemoText: {
      color: "#212121",
      fontFamily: 'Inter',
      fontSize: "22.633px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      letterSpacing: "-0.028px",
      marginTop: "19px",
      marginBottom: "20px"
  },
  heartRed: {
      borderRadius: "26.81px", width: "25.1px", height: "25.1px", backgroundColor: "#FFF", display: "flex", alignItems: "center", justifyContent: "center"
  },
  referNow: {
      width: "282px",
      height: "65px",
      flexShrink: 0,
      borderRadius: "32.582px",
      background: "#3784F8",
  },
  studentReviewText: {
      color: "#212121",
      fontFamily: 'Inter',
      fontSize: "15px",
      fontStyle: "normal",
      lineHeight: "normal",
      marginTop: "8px",
      fontWeight: 400,
      letterSpacing: "-0.018px",
  },
  avatarPagination: {
      display: "flex",
      justifyContent: "center"
  },
  favStudyImg: {
      display: "flex",
      justifyContent: "flex-end",
      right: "138px",
      paddingTop: "31px",
      zIndex: 2
  },
  favStudyImgBundleMain: {
      display: "flex",
      justifyContent: "space-between",
      position: "relative"
  } as Record<string, string>,
  referText: {
      color: "#02C5C8",
      fontFamily: 'Inter',
      fontSize: "13.757px",
      fontStyle: "normal",
  },
  earnText: {
      color: "#3784F8",
      fontFamily: 'Inter',
      fontSize: "13.757px",
      fontStyle: "normal",
      lineHeight: "normal",
  },
  referNowText: {
      color: "#FFF",
      fontFamily: 'Inter',
      fontSize: "20.997px",
      fontStyle: "normal",
      lineHeight: "normal",
  },
  selectedItem: {
      color: "#212121",
      fontFamily: 'Inter',
      fontSize: "20.941px",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.087px",
      diplay: "flex",
      justifyContent: "center"
  },
  bundleCourseMain: {
      display: "flex",
      background: "linear-gradient(180deg, rgba(255,232,246,1) 25%, rgba(255,255,255,1) 100%)",
      marginTop: "50px",
      paddingTop: "62px",
      position: "relative"
  } as Record<string, string>,
  sixth: {},
  fifthLayout: {
      margin: '80px 24px 60px 24px'
  },
  fifth: {
      display: "flex"
  },
  fifthLeft: {
      width: "50%",
      background: "linear-gradient(135deg, #5040FF 0%, #00222A 100%)",
      borderTopRightRadius: "58px",
      borderBottomRightRadius: "58px",
      paddingTop: "32px",
      zIndex: 7
  },
  fifthThirdMain: {
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
      rowGap: "33px",
      paddingTop: "55px",
      paddingBottom: "69px"
  },
  iconDiv: {
      width: "83px",
      height: "83px",
      flexShrink: 0,
      borderRadius: "16px",
      background: "#00165F",
  },
  followersMain: {
      display: "flex",
      columnGap: "16px",
      marginLeft: "20px"
  },
  countFollowTextMain: {},
  count: {
      color: "#FFF",
      fontFamily: 'Inter',
      fontSize: "38.633px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "61px",
      letterSpacing: "-0.047px",

  },
  countText: {
      color: "#FFF",
      fontFamily: 'Inter',
      fontSize: " 20.633px",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.025px",
      fontWeight: 400
  },
  fifthRight: {
      width: "50%",
      background: "#98F2FF",
      paddingLeft: "100px",
      marginLeft: "-90px",
      paddingTop: "32px"
  },
  fifthTopText: {
      color: "#FFF",
      fontFamily: 'Inter',
      fontSize: "49.633px",
      fontStyle: "normal",
      lineHeight: "61px",
      letterSpacing: "-0.061px",
      fontWeight: 700,
  },
  fifthTopTextRight: {
      color: "#00096F",
      fontFamily: 'Inter',
      fontSize: "49.633px",
      fontStyle: "normal",
      lineHeight: "61px",
      letterSpacing: "-0.061px",
      display: "flex",
      justifyContent: "center",
      marginBottom: "20px",
      fontWeight: 700
  },
  fifthThirdTextRight: {
      color: "#212121",
      fontFamily: 'Inter',
      fontSize: "28.633px",
      fontStyle: "normal",
      lineHeight: "61px",
      letterSpacing: "-0.035px",
      display: "flex",
      justifyContent: "center",

      marginTop: "29px",
      fontWeight: 400
  },
  fifthTopMidRight: {
      color: "#000",
      fontFamily: 'Inter',
      fontSize: "33.633px",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.041px",
      fontWeight: 400
  },
  fifthMidText: {
      color: "#FFF",
      fontFamily: 'Inter',
      fontSize: "22.633px",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.028px",
      fontWeight: 400
  },
  thirdLayout: {
      padding: '36px',
      boxShadow: '0px 4px 7px 0px #0000001A',
      position: 'sticky' as const,
      zIndex: 7
  },
  third: {

  },
  thirdMidText: {
      color: "#5A5A5A",
      fontFamily: 'Inter',
      fontSize: "24.633px",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.103px",
      margin: "15px 6%",
      fontWeight: 400,
      '@media (min-width:1440px) and (max-width:1920px)': {
      margin: "15px 100px",
      }
  },
  thirdTopText: {
      color: "#3A0997",
      fontFamily: 'Inter',
      fontSize: "47.633px",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.198px",
      marginBottom: "23px",
      fontWeight: 600,
  },
  courseLayout: {
      marginTop: "67px",
      marginBottom: "28px",
  },
  viewAllText: {
      color: "#1220C1",
      fontFamily: 'Inter',
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.3px",

  },
  instructorChildCatName: {
      color: "#212121",
      fontFamily: 'Inter',
      fontSize: "20.633px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      letterSpacing: "-0.025px",
      marginBottom: "20px",
      display: "flex",
      justifyContent: "center"
  },
  courseTitle: {
      color: "#212121",
      fontSize: "32px",
      fontStyle: "normal",
      letterSpacing: "-0.049px",
      fontWeight: 600,
      lineHeight: "61px"
  },
  studentHead: {
      color: "#212121",
      fontSize: "32px",
      fontStyle: "normal",
      letterSpacing: "-0.049px",
      marginLeft: "61px",
      fontWeight: 600,
      lineHeight: "61px"
  },
  courseShortDescHover: {
      color: "#FFF",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.3px",
      fontWeight: 600,
      marginTop: "9px",
      marginBottom: "9px",
      display: "-webkit-box",
      webkitBoxOrient: "vertical",
      textOverflow: "ellipsis",
      WebkitLineClamp: 2,
      overflow: 'hidden',
      wordBreak: "break-word"
  },
  instructorNameMain: {
      width: "100%",
      padding: 7,
      background: "#0E599D",
      marginBottom: "6px",
      display: "flex",
      justifyContent: "flex-start"
  },
  followerImg: {
      filter: "invert(100%)",
      padding: "13px",
  },
  addFriendTop: {
      color: "#FFF",
      fontFamily: 'Inter',
      fontSize: "39.633px",
      fontStyle: "normal",
      lineHeight: "61px",
      letterSpacing: "-0.049px",
      marginTop: "33px",
      fontWeight: 700
  },
  secondLayout: {
      background: "#448797",
      borderBottomLeftRadius: "95px",
      paddingTop: "100px",
      marginTop: "-90px",
      zIndex: -1


  },
  instructorLang: {
      color: "#FFF",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.011px",
      fontSize: '8.96px'
  },
  instructorLangName: {
    color: "#FFF",
    fontStyle: "normal",
    letterSpacing: "-0.017px",
    fontSize: '14.11px',
    lineHeight: '17.07px',
    textWrap: 'nowrap',
    width: 112,
    overflow: 'hidden',
    textOverflow: "ellipsis"
  },
  chapterLectureTimeHead: {
      display: "flex",
      columnGap: "7.5px",
      alignItems: 'center'
  },
  chapterLectureTimeText: {
      color: "#FFF",
      fontStyle: "normal",
      lineHeight: "normal",
      marginLeft: 3,
      fontSize: '12.56px'
  },
  second: {
      display: "flex",
      columnGap: "20px",
      padding: "30px",
      alignIem: "center"

  },
  addFriendMain: {

  },
  addFriendDesc: {
      color: "#FFF",
      fontFamily: 'Inter',
      fontSize: "22.633px",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.028px",
      fontWeight: 400
  },
  addFriendText: {
      color: "#FFF",
      fontFamily: 'Inter',
      fontSize: "32.633px",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.04px,",
      marginTop: "14px",
      fontWeight: 600
      // textAlign:"center"
  },
  firstLayout: {
      background: "linear-gradient(135deg, #3C7481 0%, #154955 100%)",
      borderBottomRightRadius: "95px",
  },
  firstText: {
      color: "#FFF",
      // fontFamily: "ProximaNova",
      fontSize: "65.633px",
      // fontStyle: "normal",
      // lineHeight: "69px",
      fontWeight: 700,
      // letterSpacing: "-0.08px",
      marginBottom: "13px"
  },
  first: {
      display: "flex",
      columnGap: "5px",

  },
  productContainer: {
  },
  aboutCourseLeft: {
      gap: '12px',
      display: 'flex',
      flexDirection: 'column' as const
  },
  launcedCourseHead: {
      display: "flex",
      columnGap: "43px",
      marginLeft: "61px",
      marginTop: "96px",
  },
  courseViewAll: {
      display: "flex",
      columnGap: "10px",
      borderRadius: "31.826px",
      border: "1.8px solid #1721CD",
      background: "#FFF",
  },
  studentCountRatingBundle: {
      color: "#FFF",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
  },
  leftRightLable: {
      display: "flex",
      flexDirection: "column",
      gap: 2,
      marginBottom: "10px"
  } as CSSProperties,
  leftRightLables: {
      marginBottom: "10px"
  },
  aboutCourseRight: {
      gap: '8px',
      display: 'flex',
      flexDirection: 'column' as const,
  },
  aboutCourseLeftRight: {
      display: "flex",
      gap: "10px",
      color: "white",
      padding: "0 16px 16px 16px",
      justifyContent: "space-between"
  },
  shortDesc: {
      color: "white",
      marginTop: "10px",
      marginBottom: "10px"
  },
  fullDesc: {
      color: " #FFF",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.151px",
      display: "-webkit-box",
      webkitBoxOrient: "vertical",
      textOverflow: "ellipsis",
      WebkitLineClamp: 3,
      overflow: 'hidden',
  },
  aboutCourse2: {
      marginTop: "10px"
  },
  courseNameTop: {
      display: "flex",
      justifyContent: "space-between"
  },
  addToCart: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      padding: "0px 16px",
      boxSizing: "border-box",
      margin: "12px 0px 24px 0px"
  } as Record<string, string | number>,
  addToCartBundle: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      padding: "0px 16px",
      boxSizing: "border-box",
      margin: 'auto', 
      marginBottom: 12
  } as Record<string, string | number>,
  withHover: {
      borderRadius: "14.055px",
      opacity: 0.15,
      boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.27)",
      background: "linear-gradient(180deg, rgba(0, 33, 41, 0.98) 0%, rgba(32, 104, 120, 0.95) 100%)",
  },
  root: {
      flexGrow: 1,
  },

};
// Customizable Area End
