import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { elementsPerPage } from "../../../components/src/MainLayout.web";
export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

export interface ValidResponseType {
  message: string;
  data: object;
  scheduled: object;
}

interface BaseResponseType {
  meta: Meta;
  errors: ErrorsType[];
  error: string;
}

interface ErrorsType {
  token: string;
}

export interface BannerListResponseType extends BaseResponseType {
  data: BannerListData[];
}

export interface BannerResponseType extends BaseResponseType {
  data: BannerListData;
}

export interface BannerListData {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  banner_title: string;
  status: boolean;
  created_at: Date;
  updated_at: Date;
  banner_image: string;
}

export interface Meta {
  message: string;
  total_banners: number;
  page: string;
}

interface ResponseErrors {
  errors: {
    token: string;
  }[]
}
export interface InvalidResponseType {
  errors: Array<string>;
}
const bannerNameRegex = /^(?!\s+$)(?=.*[A-Za-z])[A-Za-z0-9\s!@#$%^&*(),.?":{}|<>]{1,20}$/;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  bannerList: BannerListData[];
  currentPageCount: number;
  pageCount: number;
  userToken: string;
  isLoading: boolean;
  openDeleteModal: boolean;
  deleteId: string | number;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  isUpdate: boolean;
  bannerId: string | number;
  perPage: number;
  bannerName: string;
  bannerNameError: string;
  bannerIcon: File | string;
  bannerIconName: string;
  bannerIconError: string;
  bannerIconPreview: string;
  bannerType: string;
  screenTitle: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class HeaderBannerController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getBannersListApiCallId: string = "";
  submitBannerApiCallId: string = "";
  deleteBannerApiCallId: string = "";
  showBannerApiCallId: string = "";
  toggleStatusApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      bannerList: [],
      currentPageCount: 1,
      pageCount: 0,
      bannerName: "",
      bannerNameError: "",
      bannerIcon: "",
      bannerIconName: "",
      bannerIconError: "",
      bannerIconPreview: "",
      userToken: "",
      isLoading: false,
      isAlert: false,
      openDeleteModal: false,
      deleteId: "",
      alertMsg: "",
      alertType: "success",
      isUpdate: false,
      bannerId: "",
      perPage: elementsPerPage,
      bannerType: "mobile_top_banner",
      screenTitle: "Mobile Top Banners"
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      // Customizable Area Start
      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      }
      // Customizable Area End
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let data = message.getData(getName(MessageEnum.NavigationIdMessage));
      if (data) {
        await setStorageData("headerbannerId",data.ScreenTest)
        this.handleShowBanner(data.ScreenTest);
      }
    }
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getUserToken();
    const headerbannerId = await getStorageData("headerbannerId");
    const isAddBanner = window.location.pathname.includes("AddHeaderBanner") || window.location.pathname.includes("AddWebHeaderBanner") || window.location.pathname.includes("AddMobileFooterBanner");
    const WebHeaderBanner = window.location.pathname.includes("WebHeaderBanner") || window.location.pathname.includes("AddWebHeaderBanner")
    if(WebHeaderBanner) {
      this.setState({
        bannerType: "top_banner",
        screenTitle: "Web Top Banners"
      })
    }
    const MobileFooterBanner = window.location.pathname.includes("MobileFooterBanner") || window.location.pathname.includes("AddMobileFooterBanner");
    if(MobileFooterBanner) {
      this.setState({
        bannerType: "mobile_footer_banner",
        screenTitle: "Mobile Footer Banners"
      })
    }
    if(headerbannerId) {
        this.handleShowBanner(headerbannerId);
    }
    if (!isAddBanner) {
      this.getHeaderBannersList()
    }
  }

  async componentWillUnmount() {
      await removeStorageData("headerbannerId")
  }
  getUserToken = async () => {
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
  };

  bannerApiCall = (data: APIPayloadType) => {
    let { contentType, method, endPoint, body, type } = data;
    const header = {
      "Content-Type": contentType,
      token: this.state.userToken
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type === "" ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson;
  };

  apiSuccessCallBacks = async (
    apiRequestCallId: string,
    responseJson: BannerListResponseType | BannerResponseType
  ) => {
    if(!responseJson.errors) {
    switch (apiRequestCallId) {
      case this.getBannersListApiCallId:
        this.handleGetAdminBannerListResponse(
          responseJson as BannerListResponseType
        );
        break;
      case this.deleteBannerApiCallId:
        this.handleDeleteBannerResponse();
        break;
      case this.toggleStatusApiCallId:
        this.handleToggleStatusResponse();
        break;
      case this.showBannerApiCallId:
        this.handleShowBannerResponse(responseJson as BannerResponseType);
        break;
      case this.submitBannerApiCallId:
        this.handleSubmitBannerResponse(responseJson as BannerResponseType);
        break;
    }
    } else {
      this.parseErrors(responseJson)
    }
  };

  parseErrors = async (responseJson: ResponseErrors) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };
  handleGetAdminBannerListResponse = async (
    response: BannerListResponseType
  ) => {
    const { meta } = response;
    if (response.error) {
      this.setState({
        bannerList: [],
        pageCount: 0,
        isLoading: false,
        isAlert: true,
        alertMsg: response.error,
        alertType: "warning"
      });
    } else if (
      response &&
      response.errors &&
      response.errors.length > 0 &&
      response.errors[0].token === configJSON.tokenExpiredMessage
    ) {
      this.navigationLoginScreen();
    } else {
      this.setState({
        bannerList: response.data,
        pageCount: Math.ceil(meta.total_banners / this.state.perPage),
        isLoading: false
      });
    }
  };

  handleDeleteBannerResponse = () => {
    this.setState(
      {
        openDeleteModal: false,
        deleteId: "",
        isLoading: false,
        isAlert: true,
        alertMsg: "Banner Deleted Successfully",
        alertType: "success"
      },
      () => {
        this.getHeaderBannersList();
      }
    );
  };

  checkCategoryFormValidations(updatedValues: any): boolean {
    const validationFields = [
      {
        field: "bannerName",
        errorKey: "bannerNameError",
        errorMessage: "Banner title is Required",
        regex: bannerNameRegex
      },
      {
        field: "bannerIconName",
        errorKey: "bannerIconError",
        errorMessage: "Image is Required"
      }
    ];

    let hasError = false;

    validationFields.forEach(({ field, errorKey, errorMessage, regex }) => {
      if (
        !updatedValues[field] ||
        (updatedValues[field] && updatedValues[field].length === 0) ||
        (regex && !regex.test(updatedValues[field].trim()))
      ) {
        this.setState(prevState => ({
          ...prevState,
          [errorKey]: errorMessage,
          isLoading: false
        }));
        hasError = true;
      } else {
        this.setState(prevState => ({
          ...prevState,
          [errorKey]: ""
        }));
      }
    });

    return hasError;
  }

  handleSubmitBanner = async () => {
    this.setState({ isLoading: true })
    const {
      bannerName,
      bannerIconName,
      bannerIcon,
      isUpdate,
      bannerId
    } = this.state;
    const formValues = { bannerName, bannerIconName };
    if (!this.checkCategoryFormValidations(formValues)) {
      const endPoint = `${configJSON.getBannersListApiEndpoint}${
        isUpdate ? `/${bannerId}` : ""
      }`;
      const formdata = new FormData();
      formdata.append("banner[banner_title]", bannerName);
      formdata.append("banner_type", this.state.bannerType);
      if (bannerIcon) {
        formdata.append(
          "banner[banner_image]",
          bannerIcon as any,
          bannerIconName
        );
      }
      this.submitBannerApiCallId = this.bannerApiCall({
        method: isUpdate
          ? configJSON.apiUpdateUserType
          : configJSON.callTypeApiValidateMobileNo,
        endPoint: endPoint,
        body: formdata,
        type: "formdata"
      });
    }
  };

  handleSubmitBannerResponse = (response: BannerResponseType) => {
    if (
      response.errors &&
      response.errors[0].token === configJSON.tokenExpiredMessage
    ) {
      this.navigationLoginScreen();
    } else if (
      response.meta &&
      response.meta.message
    ) {
      this.handleAddReset();
      this.bannerNavigationCall();
    } else if (response.error) {
      this.setState({
        alertMsg: response.error,
        alertType: "error",
        isAlert: true,
        isLoading: false
      });
    }
  };

  handleToggleStatusResponse = () => {
      this.setState({
        isAlert: true,
        alertMsg: "Banner updated successfully",
        alertType: "success"
      });
      this.getHeaderBannersList();
  };

  oncloseAlert = () => {
    this.setState({
      isAlert: false
    });
  };

  getHeaderBannersList = async () => {
    this.setState({ isLoading: true });
    this.getBannersListApiCallId = this.bannerApiCall({
      contentType: configJSON.jsonContentType,
      method: configJSON.httpGetType,
      endPoint: `${
        configJSON.getBannersListApiEndpoint
      }?banner_type=${this.state.bannerType}`
    });
  };

  handleToggleStatus(id: string, status: boolean, index: number) {
    if (!this.state.bannerList || !this.state.bannerList.length) {
      return;
    }
    let clone = [...this.state.bannerList];
    clone[index].attributes.status = !status;

    this.setState({
      bannerList: clone
    });

    const formdata = new FormData();
    formdata.append("banner[status]", `${!status}`);
    formdata.append("banner_type", this.state.bannerType)

    this.toggleStatusApiCallId = this.bannerApiCall({
      method: configJSON.apiUpdateUserType,
      endPoint: `${configJSON.getBannersListApiEndpoint}/${id}`,
      body: formdata,
      type: "formdata"
    });
  }

  handleAddReset = () => {
    this.setState({
      bannerIcon: "",
      bannerIconError: "",
      bannerIconName: "",
      bannerIconPreview: "",
      bannerName: "",
      bannerNameError: ""
    });
  };

  bannerNavigationCall = () => {
    const bannerType = this.state.bannerType;
    let messageType;
    if (bannerType === "top_banner") {
      messageType = MessageEnum.NavigationWebHeaderBannerMessage;
    } else if (bannerType === "mobile_footer_banner") {
      messageType = MessageEnum.NavigationMobileHeaderBannerMessage;
    } else {
      messageType = MessageEnum.NavigationHeaderBannerMessage;
    }
    
    const msg: Message = new Message(getName(messageType));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  handleDeleteBanner = async (id: string | number) => {
    this.setState({ isLoading: true });
    this.deleteBannerApiCallId = this.bannerApiCall({
      contentType: configJSON.jsonContentType,
      method: configJSON.httpDeleteType,
      endPoint: `${
        configJSON.getBannersListApiEndpoint
      }/${id}?banner_type=${this.state.bannerType}`
    });
  };

  handleShowBanner = async (id: string | number) => {
    if (this.state.isLoading) return;
    this.setState({ isLoading: true });
    this.showBannerApiCallId = this.bannerApiCall({
      contentType: configJSON.jsonContentType,
      method: configJSON.httpGetType,
      endPoint: `${configJSON.getBannersListApiEndpoint}/${id}`
    });
  };

  handleShowBannerResponse = (response: BannerResponseType) => {
    const { data } = response;
    const { attributes } = data;

    this.setState({
      isUpdate: true,
      bannerId: data.id,
      bannerName: attributes.banner_title,
      bannerIconName: attributes.banner_image,
      bannerIconPreview: attributes.banner_image
    }, () => this.setState({ isLoading: false }));
  };

  handleDeleteIcon = () => {
    this.setState({
      bannerIcon: "",
      bannerIconName: "",
      bannerIconError: "",
      bannerIconPreview: ""
    });
  };

  handleIconChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target && event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const fileName = file.name;
      const fileSize = file.size;
  
      const allowedFormats = ["image/png", "image/jpeg", "image/jpg"];
      if (!allowedFormats.includes(file.type)) {
        this.setState({
          bannerIconError:
            "Invalid file format. Please upload a PNG, JPG, or JPEG file.",
          bannerIcon: "",
          bannerIconName: "",
          bannerIconPreview: ""
        });
      } else if (fileSize > 3 * 1024 * 1024) {
        this.setState({
          bannerIconError:
            "File size exceeds 3MB. Please choose a smaller file.",
          bannerIconPreview: "",
          bannerIcon: "",
          bannerIconName: ""
        });
      } else {
        const imageUrl = global.URL.createObjectURL(file);
        const img = new Image();
        img.src = imageUrl;
  
        img.onload = () => {
            this.setState({
              bannerIcon: file,
              bannerIconName: fileName,
              bannerIconError: "",
              bannerIconPreview: imageUrl
            });
        };
  
        img.onerror = () => {
          this.setState({
            bannerIconError: "Invalid image file.",
            bannerIcon: "",
            bannerIconName: "",
            bannerIconPreview: ""
          });
          global.URL.revokeObjectURL(img.src);
        };
      }
    } else {
      this.setState({
        bannerIcon: "",
        bannerIconName: "",
        bannerIconError: "No file is selected",
        bannerIconPreview: ""
      });
    }
  };

  handleBannerName = (event: { target: { value: string } }) => {
    const value = event.target.value.trimStart();

    if (!bannerNameRegex.test(value)) {
      this.setState({
        bannerName: value,
        bannerNameError: "Please enter a valid Banner title"
      });
    } else {
      this.setState({
        bannerName: value,
        bannerNameError: ""
      });
    }
  };

  handleCloseDeleteModal = () => {
    this.setState({
      openDeleteModal: false,
      deleteId: ""
    });
  };

  handleOpenModal = (id: number) => {
    this.setState({
      openDeleteModal: true,
      deleteId: id
    });
  };

  handleAddBanner = () => {
    const bannerType1 = this.state.bannerType;
    const msg: Message = new Message(getName(this.getMessageType(bannerType1)));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  getMessageType(bannerType: string): MessageEnum {
    if (bannerType === "top_banner") {
      return MessageEnum.NavigationAddWebHeaderBannerMessage;
    } else if (bannerType === "mobile_footer_banner") {
      return MessageEnum.NavigationAddMobileHeaderBannerMessage;
    } else {
      return MessageEnum.NavigationAddHeaderBannerMessage;
    }
  }
  
  handleEditBanner = (id: string | number) => {
    const bannerType1 = this.state.bannerType;
    const msg: Message = new Message(getName(this.getMessageType(bannerType1)));

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationIdMessage), { ScreenTest: id });
    this.send(msg);
  };

  navigationLoginScreen = () => {
    alert(configJSON.sessionExpireMsg)
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };
  // Customizable Area End
}
