// Customizable Area Start
import React, { CSSProperties } from "react";
import {
  Box,
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
  Grid,
  Chip,
  Divider,
  withStyles,
  createStyles,
  styled,
  Theme,
  Button,
  AccordionDetails,
  GridSize,
  TextField,
  InputAdornment,
  Avatar
} from "@material-ui/core";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from "@material-ui/core/CircularProgress";
import Skeleton from "@material-ui/lab/Skeleton";
import MyCoursesDetailController, {
  Props,
  TestimonialResponse,
} from "./MyCourseDetailController.web";
import LinearProgress from "@material-ui/core/LinearProgress";
import { PiVideoLight } from "react-icons/pi";
import { MdOutlinePlayCircleFilled } from "react-icons/md";
import { FaCheckCircle, FaStar, FaRegCircle } from "react-icons/fa";
import { GoShareAndroid } from "react-icons/go";
import { Link } from "react-router-dom";
import { BsDot } from "react-icons/bs";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from '@material-ui/icons/Close';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import { webStylesLocal } from "./MyCourse.web";
import FooterWeb from "../../../components/src/Footer.web";
import HeaderWeb from "../../../components/src/Header.web";
import {
  ClassesDatum,
  CourseDetailsAttributes,
  SpecfifcContentDuration
} from "./CourseDetailController.web";
import { setStorageData } from "framework/src/Utilities";
import Slider,{ CustomArrowProps } from "react-slick";
import { Rating } from '@material-ui/lab';
import { Accordion, AccordionSummary } from "./CourseDetail.web";
import {downloadPDF, forwardArrowPage, backArrowPage} from './assets'
let theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "'Inter', monospace"
    },
    body1: {
      fontFamily: "'Inter', monospace"
    }
  }
});
theme = responsiveFontSizes(theme);

export default class MyCourseDetail extends MyCoursesDetailController {
  constructor(props: Props) {
    super(props);
    this.closemodal = this.closemodal.bind(this);
  }

  closemodal() {
    this.oncloseAlert();
  }

  toggleDrawer() {
    const value = !this.state.isDrawerOpen;
    this.setState(
      { isDrawerOpen: value },
      async () => await setStorageData("isCourseDrawerOpen", `${value}`)
    );
  }

  accordionHeader(isExpanded: boolean, v: SpecfifcContentDuration) {
    return (
      <AccordionSummary
        expandIcon={
          isExpanded ? (
            <CloseIcon htmlColor="white" />
          ) : (
            <AddIcon htmlColor="white" />
          )
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
        key={v.id}
      >
        <Typography
          component="h6"
          style={{
            fontWeight: "bold"
          }}
          className="accordionHeading"
        >
          {v.name}
        </Typography>
        <Typography
          component="span"
          style={{ marginLeft: "auto", marginRight: 30 }}
          className="accordionHeadingClasses"
        >
          {v.class_count} Classes
        </Typography>
        <Typography component="span" className="accordionHeadingClasses">
          {this.convertMinutesToTimeFormat(`${v.duration_count}`)} Mins
        </Typography>
      </AccordionSummary>
    );
  }

  renderAccordion(content: SpecfifcContentDuration[]) {
    return (
      <>
        {content.map(v => {
          const isExpanded = !!this.state.openedAccordions.find(
            x => x === v.id
          );

          if (v.class_count === 0) {
            return;
          }
          return (
            <Accordion
              expanded={isExpanded}
              data-test-id="accordionTestId"
              onChange={() => this.handleAccordionChange(v.id)}
            >
              {this.accordionHeader(isExpanded, v)}
              <AccordionDetails
                style={{ display: "flex", flexDirection: "column", gap: 5 }}
              >
                {(this.sortArray(v.classes_data) as ClassesDatum[]).map((val, i) => (
                  <Link
                    to={`/MyCoursesDetail/${this.state.courseId}/${
                      this.state.currentCourse
                    }/${v.id}/${val.id}`}
                    className="content-list-item"
                    style={{
                      borderBottom:
                        v.classes_data.length - 1 === i ? "" : "1px solid #C6C6C6"
                    }}
                  >
                    <Typography
                      component="h6"
                      variant="h6"
                      className="accordion-text"
                    >
                      <MdOutlinePlayCircleFilled
                        color="inherit"
                        style={{
                          width: 26,
                          height: 26
                        }}
                      />
                      {val.title}
                    </Typography>
                    <Typography component="h6" variant="h6" className="accordion-min-text">
                      {this.convertMinutesToTimeFormat(val.duration)} MINS
                    </Typography>
                  </Link>
                ))}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </>
    );
  }

  renderOverview(value: CourseDetailsAttributes) {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Typography
          variant="h4"
          component="h5"
          className="classDetailsTitle"
          style={{ margin: "30px 0px 0px 0px" }}
        >
          {value.course_name}
        </Typography>
        <div dangerouslySetInnerHTML={{ __html: value.short_description }} />
        <Typography
          variant="h5"
          component="h6"
          style={{
            display: "inline-flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Chip
            style={{ color: "white", background: "#FB7303", fontSize: "21px", fontWeight: 600, padding: "10px", height: "auto", borderRadius: "50px" }}
            label={this.getCourseValue(value.tag, value.average_rating.tag || value.course_child_category.name)}
            data-test-id="itemsCounter"
          />
          <Typography
            variant="h4"
            component="h6"
            style={{
              marginRight: "auto",
              display: "inline-flex",
              alignItems: "center",
              gap: 3,
              marginLeft: 15
            }}
          >
            {!!value.average_rating.Average_rating && 
              <Typography
                variant="h5"
                component="strong"
                style={{
                  color: "#2E5E69",
                  display: "inline-flex",
                  alignItems: "center",
                  gap: 5,
                  fontSize: "24.88px",
                  fontFamily: "Inter"
                }}
              >
                <FaStar color="ffc107" /> {value.average_rating.Average_rating}
                <span style={{ color: "#979797" }}>|</span>
              </Typography>
            }
            <Typography
              variant="h5"
              component="strong"
              style={{ color: "#979797", fontSize: "24.88px" }}
            >
              {value.student_count}
            </Typography>
          </Typography>
        </Typography>
        {!!value.what_learn.length &&
        <div>
          <Typography
            variant="h6"
            component={"h4"}
            style={{ marginBottom: "0.5rem" }}
          >
            WHAT YOU WILL LEARN
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              marginBottom: 20
            }}
          >
            {value.what_learn.map(item => (
              <Typography
                component="span"
                style={{
                  width: "100%",
                  display: "inline-flex",
                  alignItems: "center",
                  gap: 6,
                  color: "#676767"
                }}
              >
                <FaCheckCircle
                  fontSize="inherit"
                  style={{ background: "white", borderRadius: "100px" }}
                  color="#32ba7c"
                />
                {item}
              </Typography>
            ))}
          </div>
        </div>}
        <Divider light />
        <div>
          <Typography
            variant="h6"
            component={"h4"}
            style={{ marginBottom: "0.5rem" }}
          >
            Description
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: value.full_description }} />
        </div>
        <Divider light />
      </div>
    );
  }

  renderCourse(value: CourseDetailsAttributes) {
    return (
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "12px 3px"
          }}
        >
          <Typography component="span" style={{ display: "flex", alignItems: "center" }}>
            {value.total_content} Chapters <BsDot /> {value.course_class_count}{" "}
            Lectures
            <BsDot />{" "}
            {this.convertToHoursAndMinutes(
              value.all_course_class_duration,
            )}{" "}
            Total Duration
          </Typography>
          <Button
            endIcon={this.state.openedAccordions.length === value.specfifc_content_duration.map(e => e.id).length ? <ArrowDownwardIcon style={{ height: "1.2rem" }} htmlColor="#1721CD" /> :<ArrowForwardIcon htmlColor="#1721CD" />}
            variant="outlined"
            data-test-id="expandAllAccordions"
            style={styles.courseViewAll}
            onClick={() =>
              this.handleExpandAllAccordions(
                value.specfifc_content_duration.map(e => e.id)
              )
            }
          >
            <Typography variant="h6" style={styles.viewAllText}>
              {this.state.openedAccordions.length === value.specfifc_content_duration.map(e => e.id).length ? "COLLAPSE ALL" : "EXPAND ALL"}
            </Typography>
          </Button>
        </div>
        <div style={{ marginTop: 10 }}>
          {this.renderAccordion(value.specfifc_content_duration)}
        </div>
      </div>
    );
  }

  renderAssigment() {
    if (!this.state.assigmentList.length) {
      return this.renderRatings()
    }
    return (
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div
          style={{
            flex:1,
            alignItems: "center",
            justifyContent: "space-between",
            margin: "12px 3px"
          }}
        >
          <div style={{ fontFamily: 'Inter', color: '#111612', fontWeight: 500, fontSize: 18 }}>
            Created By <span style={{ fontFamily: 'Inter', color: '#979797', fontWeight: 500, fontSize: 18 }}>CHSE Express</span>
          </div>
          <div style={{display:'flex',flexDirection:'row', margin: '10px 0'}}>
          </div>
          <div>
            {this.state.assigmentList.map((item) => {
              return (
                <div key={item.id} style={{ flex:1, backgroundColor: '#F6F6F6', borderRadius: 12, marginTop:'10px' }}>
                  <div style={{display:'flex', flex:1, flexDirection: 'row', padding:'10px' }}>
                    <Typography style={{flex:1, color:'#1F6575',  fontFamily: 'Inter', fontWeight: 500, fontSize: 22, alignSelf:'center', marginLeft:'5px'}}>{item.title}</Typography>
                    <IconButton data-test-id="downloadPdf" onClick={() => this.downloadPdf(item.downloadUrl)} style={{ padding: "0px" }}>
                      <img
                        src={downloadPDF}
                        width={'190px'}
                        height={"50px"}
                      />
                    </IconButton>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    );
  }

  CustomSWNextArrow = (props: CustomArrowProps) => {
      const { onClick } = props;
      return (
          <div data-test-id="SWNextArrow" style={styles.forwardBackIconStd} onClick={onClick}>
          <img src={forwardArrowPage} width={48.57} height={48.57}/>
      </div>
      )
  }

  CustomSWPrevArrow = (props: CustomArrowProps) => {
      const { onClick } = props;
      return (
          <div data-test-id="SWPrevArrow" style={styles.forwardBackIconStd} onClick={onClick} >
              <img src={backArrowPage} width={48.57} height={48.57} />
          </div>
      )
  }

  SWsettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <this.CustomSWNextArrow />,
    prevArrow: <this.CustomSWPrevArrow />,
    style: styles.studentReviews,
    className: 'custom-slider-class' ,
  }

  renderRatings() {
    let averageRatingList: number[] = []
    if (!!this.state.testimonialsAverageRating){
      averageRatingList = [
        this.state.testimonialsAverageRating.five_rating,
        this.state.testimonialsAverageRating.four_rating,
        this.state.testimonialsAverageRating.three_rating,
        this.state.testimonialsAverageRating.two_rating,
        this.state.testimonialsAverageRating.one_rating,
      ]
    }
    return (
      <>
        <div className="averageRatingDiv" data-test-id="ratingMainDiv">
          {!!this.state.testimonialsAverageRating 
            ? <>
              <Typography className="RatingsAndReviewsText">
                Ratings and Reviews
              </Typography>
              <div className="averageBox">
                <div style={{ width: "100%", maxWidth: "220px" }}>
                  <Typography className="RatingsAndReviewsNumber">
                    {this.state.testimonialsAverageRating.total_average_rating}/5
                  </Typography>
                  <Rating value={this.state.testimonialsAverageRating.total_average_rating} style={{ fontSize: '1.5rem', color: '#FFD735' }} readOnly />
                  <Typography className="RatingsAndReviewsBasedText">
                    Based on {this.state.testimonialsAverageRating.rated_user_count} Ratings & Reviews
                  </Typography>
                </div>
                <div className="starDiv">
                  {[...Array(5)].map((_, mainIndex) => {
                    return (
                      <div className="ratingsAndProgressDiv">
                        <Rating value={5 - mainIndex} style={{ fontSize: '0.8rem', color: '#FFD735' }} readOnly />
                        <CustomProgressReview
                          data-test-id="customProgressReview"
                          variant="determinate"
                          value={averageRatingList[mainIndex]/100 * 100}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </>
            : <Typography className="noReviewsText">
                No Reviews
              </Typography>
          }
        </div>
        <div className="mainRatingsDiv">
          {this.state.userTestimonial && this.state.userTestimonial.id
            ? <div className="grayDiv">
              <Typography data-test-id="userTestimonialName" className="userTestimonialName">
                {this.state.userTestimonial.attributes.user_name.full_name}
              </Typography>
              <div>
                <Rating value={this.state.userTestimonial.attributes.rating} max={this.state.userTestimonial.attributes.rating} style={{ fontSize: '1.5rem', color: '#FFD735' }} readOnly />
              </div>
              <Typography className="userTestimonialComment">
                {this.state.userTestimonial.attributes.comment}
              </Typography>
            </div>
            : <>
              <div className="grayDiv">
                <Typography className="rateThisCourseTitle">
                  RATE THIS COURSE
                  <Rating className="ratingComponent" data-test-id="handleRating" value={this.state.courseRating} onChange={(_, newValue) => this.handleRating(newValue)} />
                </Typography>
              </div>
              <div className="grayDiv">
                <Typography className="writeAReviewTitle">
                  WRITE A REVIEW
                </Typography>
                <textarea className="textareaInput" data-test-id="handleCourseReviewText" rows={6} value={this.state.courseReviewText} onChange={(event) => this.handleCourseReviewText(event)} />
                <span data-test-id="reviewAndRatingError" className="errorText">
                  {this.state.ReviewAndRatingError}
                </span>
                <div className="submitButtonDiv">
                  <button className="submitReviewButton" data-test-id="handleOnReviewSubmit" onClick={this.handleOnReviewSubmit}>SUBMIT REVIEW</button>
                </div>
              </div>
            </> 
          }
        </div>
        {!!this.state.testimonialsList.data.length &&
          <StudentReviewSlider {...this.SWsettings} data-test-id="studentReviewSlider">
            {this.state.testimonialsList.data.map((review: TestimonialResponse, index: number) => (
              <div data-test-id="studentReviewData">
                <div className="studentReview">
                  <div style={{ display: "flex" , gap:"24px", justifyContent: 'center', alignItems: "center"}}>
                    <div>
                        <Avatar alt="Start" src={review.attributes.photo} className="reviewStudentImg" />
                    </div>
                    <div style={{ overflow: 'auto' }}>
                        <div className={'studentReviewTop'}>
                            <div className={'studentName'}>{review.attributes.name}</div>
                            <div className="studentLabel">STUDENT</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <Rating value={review.attributes.rating} max={review.attributes.rating} style={{ fontSize: '1rem', color: '#FFD735' }} readOnly />
                        </div>
                        <div className={'studentReviewText'}>
                            {review.attributes.comment}
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </StudentReviewSlider>
        }
      </>
    )
  }

  renderTab(value: CourseDetailsAttributes) {
    switch (this.state.currentTab) {
      case 0: // Overview
        return this.renderOverview(value);
      case 1: // COURSE CONTENT
        return this.renderCourse(value);
      case 2: // ASSIGNMENT
        return this.renderAssigment();
      case 3: // RATINGS
        return this.renderRatings();
    }
  }

  renderLeftColumn() {
    return (
      <Grid
        item
        data-test-id="drawer"
        xs={this.conditionalIfElse(this.state.isDrawerOpen, 4 , 1) as 4 | 1}
        style={{
          position: "relative",
          padding: 0,
          ...(this.state.isDrawerOpen ? {maxWidth: "30%"} : { maxWidth: 15 })
        }}
      >
        <div
          style={{
            ...styles.drawerContainer,
            display: this.conditionalIfElse(this.state.isDrawerOpen, "flex" , "none") as string
          }}
        >
          <div className="paddingX28px">
            <Typography
              variant="h5"
              component="h2"
              className="myCoursesText"
            >
              My Courses
            </Typography>
          </div>
          <div className="paddingX28px">
            <Box className="searchBox" display={'flex'} justifyContent={'space-between'}>
              <TextField
                variant="outlined"
                data-test-id="searchInput"
                placeholder="Search for courses"
                className={'searchInput'}
                value={this.state.searchText}
                onChange={this.handleSearchValue}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start" >
                      <IconButton data-test-id="searchIconBtn" className={"searchIconBtn"} onClick={()=>{this.getCourses()}}
                      >
                        <SearchIcon className={"searchIcon"} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
          </div>
          <div
            className="paddingX28px"
            style={{
              display: "inline-flex",
              width: "100%",
              justifyContent: "flex-end",
              boxSizing: "border-box"
            }}
          >
            <div>
              <Typography component="span" style={{ color: "#37F8C4" }}>
                {this.state.coursesList.length}{" "}
                COURSES
              </Typography>
            </div>
          </div>
          <List className="paddingX28px" style={{ maxHeight: 500, overflow: "auto" }}>
            {this.state.coursesList ? (
              this.state.coursesList.map((course, index) => {
                    const isCurrent =
                      this.state.currentCourse === `${course.course_id}`;
                    return (
                      <React.Fragment key={course.id}>
                      <Link
                        to={`/MyCoursesDetail/${course.order_id}/${
                          course.course_id
                        }`}
                        aria-current={isCurrent}
                        aria-disabled={isCurrent}
                        key={course.id}
                        style={{ fontWeight: this.conditionalIfElse(isCurrent, "bold" , "normal") as "bold" | "normal" }}
                        className="courses-list-detail"
                        onClick={(event) => this.disableLink(event, isCurrent, course.is_active_course)}
                        data-test-id="coursesListDetail"
                      >
                        <ListItem button className="list-item" disabled={!course.is_active_course}>
                          <img
                            src={
                              course.course_data
                                .course_thumbnail
                            }
                            width={80}
                            height={"auto"}
                            style={styles.courseThumbnail}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 5,
                              width: "calc(100% - 80px)"
                            }}
                          >
                            <Typography
                              variant={this.conditionalIfElse(isCurrent, "h6", undefined) as "h6" | undefined }
                              component={"span"}
                              className="courseNameText"
                            >
                              {course.course_data.course_name}
                            </Typography>
                            <CustomProgress
                              data-test-id="customProgress"
                              variant="determinate"
                              value={(course.total_watched_chapter / course.total_course_chapter) * 100}
                            />
                            <Typography
                              variant={this.conditionalIfElse(isCurrent, "subtitle2", undefined) as "subtitle2" | undefined }
                              component="span"
                              className="courseNumbersText"
                            >
                              {course.total_course_chapter}{" "}
                              CHAPTERS •{" "}
                              {
                                course.course_class_count
                              }{" "}
                              LESSONS •{" "}
                              {
                                this.convertToHoursAndMinutes(
                                  course.all_course_class_duration,
                                ).toUpperCase()
                              }
                            </Typography>
                          </div>
                        </ListItem>
                      </Link>
                      </React.Fragment>
                    );
              })
            ) : (
              <CircularProgress />
            )}
          </List>
        </div>
        <IconButton
          style={{ ...styles.closeDrawerButton }}
          data-test-id="toggleDrawer"
          onClick={() => this.toggleDrawer()}
        >
          <ArrowBackIosIcon fontSize="inherit" />
        </IconButton>
      </Grid>
    );
  }

  renderView() {
    const value = this.state.courseDetail as CourseDetailsAttributes,
      chapterDetail = this.state.chapterDetails as SpecfifcContentDuration;
    return (
      <div style={webStylesLocal.bundleCourseMainLayout}>
        <Box sx={styles.adminLayout}>
          <Grid container spacing={2} style={{ gap: "10px" }} justifyContent="space-around">
            {this.renderLeftColumn()}
            <Grid
              item
              xs={this.conditionalIfElse(this.state.isDrawerOpen, 8, 11) as GridSize}
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: 20,
              }}
            >
              <div>
                <Typography
                  variant="h4"
                  noWrap
                  className="chapterDetailsNameText"
                >
                  {chapterDetail ? (
                    chapterDetail.name
                  ) : (
                    <Skeleton variant="text" />
                  )}
                </Typography>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <div className="videoplayer" style={{ paddingTop: "0"}}>
                    {!this.state.classDetails ? (
                      <img
                        src="https://via.placeholder.com/1920x1080/eee?text=16:9"
                        style={{
                          width: "100%",
                          height: "349px",
                          borderRadius: 12,
                          background: "black"
                        }}
                      />
                    ) : (
                      <iframe
                        ref={this.iframeRef}
                        width="560"
                        height="315"
                        style={{
                          width: "100%",
                          height: "349px",
                          background: "black",
                          borderRadius: 12,
                          display: "initial"
                        }}
                        className="imageContainer"
                        src={this.state.videoCipharUrlData.meta.video_url}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                      />
                    )}
                  </div>
                  <div style={styles.courseTitle}>
                    <Typography className="classDetailsTitle" variant="h4" component="h1">
                      {this.state.classDetails ? (
                        this.state.classDetails.title
                      ) : (
                        <Skeleton variant="text" />
                      )}
                    </Typography>
                    <GoShareAndroid style={{ minWidth: "36px", width: "36px", height: "36px" }} data-test-id="goShareAndroid" onClick={this.copyTextToClipboard} />
                  </div>
                  <div>
                    <StyledTabs
                      value={this.state.currentTab}
                      onChange={(e, value) =>
                        this.setState({
                          currentTab: value
                        })
                      }
                      data-test-id="tabsTestId"
                      aria-label="styled tabs example"
                    >
                      <StyledTab label="OVERVIEW" />
                      <StyledTab label="COURSE CONTENT" />
                      {!!this.state.assigmentList.length && 
                        <StyledTab label="PDFs" />
                      }
                      <StyledTab label="RATINGS" />
                    </StyledTabs>
                    {value ? this.renderTab(value) : <CircularProgress />}
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div style={styles.playlistColumn}>
                    <Typography
                      variant="h6"
                      component="span"
                      style={{ marginLeft: 30 }}
                    >
                      PLAYLIST
                    </Typography>
                    <Divider
                      style={{ margin: "0px 30px", background: "#6DF4BE" }}
                    />
                    <List style={{ padding: 8 }}>
                      {this.state.playlist ? (
                        (this.sortArray(this.state.playlist) as ClassesDatum[]).map((item, index) => {
                          const isCurrent = this.state.classId === `${item.id}`;
                          return (
                            <Link
                              to={`/MyCoursesDetail/${this.state.courseId}/${
                                this.state.currentCourse
                              }/${this.state.chapterId}/${item.id}`}
                              aria-current={isCurrent}
                              aria-disabled={isCurrent}
                              key={item.id}
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                                fontWeight: this.conditionalIfElse(isCurrent, "bold", "normal") as "normal" | "bold"
                              }}
                            >
                              <ListItem
                                button
                                key={item.id}
                                style={{ alignItems: "start", gap: 5 }}
                              >
                                {!item.course_class_progress ? 
                                <FaRegCircle style={{
                                    background: "white",
                                    borderRadius: "100px",
                                    minWidth: 18,
                                    width: 18,
                                    height: 18,
                                    paddingTop: "3px"
                                  }}
                                  color="#236AD7"
                                /> :
                                <FaCheckCircle
                                  style={{
                                    background: "white",
                                    borderRadius: "100px",
                                    minWidth: 18,
                                    width: 18,
                                    height: 18,
                                    paddingTop: "3px"
                                  }}
                                  color="#236AD7"
                                />
                                }
                                <PiVideoLight
                                  style={{ minWidth: 22, width: 22, height: 22 }}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    overflow: "hidden",
                                    minWidth: 76,
                                    gap: "3px"
                                  }}
                                >
                                  <Typography
                                    variant={this.conditionalIfElse(isCurrent, "subtitle1", "body1") as "subtitle1" | "body1"}
                                    component="span"
                                    style={{
                                      fontWeight: this.conditionalIfElse(isCurrent, "bold", 400) as 400 | "bold"
                                    }}
                                    className="playListTitle"
                                  >
                                    {item.title}
                                  </Typography>
                                  <Typography
                                    variant={this.conditionalIfElse(isCurrent, "subtitle2", "body2") as "subtitle2" | "body2"}
                                    component="span"
                                    className="playListDuration"
                                  >
                                    (
                                    {this.convertMinutesToTimeFormat(
                                      item.duration
                                    )}
                                    )
                                  </Typography>
                                </div>
                              </ListItem>
                            </Link>
                          );
                        })
                      ) : (
                        <CircularProgress />
                      )}
                    </List>
                  </div>
                  {this.state.relatedCourses &&
                  this.state.relatedCourses.length ? (
                    <div style={styles.rightColumnCard}>
                      <Typography
                        variant="h5"
                        component="h3"
                        style={{ margin: 0 }}
                      >
                        Related Courses
                      </Typography>
                      {this.state.relatedCourses.map(item => (
                        <Link
                          to={`/course/${item.id}`}
                          style={{
                            textDecoration: "none",
                            color: "black",
                            boxShadow: "0px 2px 6px 0px #00000046",
                            display: "flex",
                            flexDirection: "column",
                            gap: 10,
                            borderRadius: 10.61,
                            boxSizing: "border-box",
                            padding: 8
                          }}
                        >
                          <img
                            src={item.attributes.course_thumbnail}
                            style={{
                              borderRadius: "10.61px",
                              width: "100%",
                              aspectRatio: "16/9"
                            }}
                            alt="course thumbnail"
                            height={"auto"}
                            width={"auto"}
                          />
                          <Typography
                            variant="h5"
                            component="h5"
                            style={{
                              margin: 0,
                              fontSize: 20,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis"
                            }}
                          >
                            {item.attributes.course_name}
                          </Typography>
                          <Typography component="span">
                            {item.attributes.course_class_count} chapters{" "}
                            <BsDot /> {item.attributes.total_content} lectures
                          </Typography>
                        </Link>
                      ))}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }

  returnMainView() {
    return (
      <main
        style={{
          overflowX: "scroll"
        }}
      >
        <MainDiv data-test-id="mainDiv">
          {this.renderView()}
        </MainDiv>
        <FooterWeb id="footer" navigation={this.props.navigation} /> 
      </main>
    );
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <NotificationAlertMessage
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          isOpen={this.state.isAlert}
        />
        <HeaderWeb id={""} navigation={this.props.navigation} />
        {this.returnMainView()}
        <button data-test-id="onVideoTimeChange" style={{ display: "none" }} onClick={() => this.onVideoTimeChange(1)}></button>
      </ThemeProvider>
    );
  }
}

const styles = {
  titleText: {
    color: "#858585",
    fontWeight: 700,
    fontSize: 24
  },
  adminLayout: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    alignItems: "center",
    padding: "0 12px",
    boxSizing: "border-box",
    maxWidth: 1440,
    margin: "auto"
  },
  drawerContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: -4,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    background: "#111612",
    color: "white",
    paddingBottom: 10,
    width: "calc(100% - 16px)",
    minHeight: "460px"
  } as CSSProperties,
  closeDrawerButton: {
    background: "white",
    position: "absolute",
    height: 55,
    width: 15,
    top: 70,
    right: 0,
    fontSize: 10,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 12,
    borderTopRightRadius: 12,
    padding: 10,
    color: "black"
  } as CSSProperties,
  courseThumbnail: {
    borderRadius: 12,
    background: "white",
    minWidth: 80,
    maxWidth: 80,
    maxHeight: 51.6,
    minHeight: 51.6,
    height: "auto",
    objectFit: "contain"
  } as CSSProperties,
  courseTitle: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 20,
    width: "100%",
    margin: "1rem 0"
  } as CSSProperties,
  viewAllText: {
    color: "#1220C1",
    fontFamily: "Inter",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.3px"
  },
  courseViewAll: {
    display: "flex",
    columnGap: "10px",
    borderRadius: "31.826px",
    border: "1.8px solid #1721CD",
    background: "#FFF"
  },
  rightColumnCard: {
    marginTop: 20,
    boxSizing: "border-box",
    padding: 15,
    borderRadius: 12,
    background: "white",
    boxShadow: "0px 1px 8px 0px #00000025",
    width: "100%",
    gap: "10px",
    display: "flex",
    flexDirection: "column"
  } as CSSProperties,
  playlistColumn: {
    padding: "8px 0",
    background: "#ECFFFD",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderRadius: 12,
    border: "1.8px solid #6DF4BE",
    boxSizing: "border-box",
    minHeight: "400px"
  } as CSSProperties,
  input: {
    flex: 1,
    marginRight: 4,
    marginLeft: 16,
    color: "black",
    width: "90%"
  },
  iconButton: {
    padding: 10,
    background: "linear-gradient(180deg,#fff54b,#f4c952)",
    fontSize: "0.55rem",
    margin: 3
  },
  studentReviews: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 50
  },
  forwardBackIconStd: {
    cursor: "pointer",
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderRadius: "30px",
    margin: "auto",
    border: "1px solid #FFA235",
    background: "white",
    boxSizing: "border-box",
    boxShadow: "unset",
    height: 44,
    flexDirection: "row-reverse"
  } as CSSProperties
};

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const StyledTabs = withStyles({
  root: {
    borderBottom: "1px solid #C2C2C2"
  },
  fixed: {
    height: "48px",
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-between"
    }
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      borderRadius: "12px",
      width: "100%",
      backgroundColor: "#FD7101"
    }
  }
})((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
));

interface StyledTabProps {
  label: string;
}

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      background: "transparent",
      color: "#9F9F9F",
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      minWidth: 115,
      padding: 0,
      "&.Mui-selected": {
        color: "#FD7101"
      },
      "&:focus": {
        opacity: 1
      }
    }
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

const CustomProgress = styled(LinearProgress)(() => ({
  borderRadius: 20,
  "&.MuiLinearProgress-colorPrimary": {
    backgroundColor: "white"
  },
  "& > div": {
    backgroundColor: "#3784F8"
  },
  "&.MuiLinearProgress-barColorPrimary": {
    backgroundColor: "#3784F8"
  }
}));

const CustomProgressReview = styled(LinearProgress)(() => ({
  borderRadius: 20,
  maxWidth: "196px", 
  width: "100%", 
  marginLeft: "16px",
  height: "7px",
  "&.MuiLinearProgress-colorPrimary": {
    backgroundColor: "white"
  },
  "& > div": {
    backgroundColor: "#FF8256",
    borderRadius: "20px"
  },
  "&.MuiLinearProgress-barColorPrimary": {
    backgroundColor: "#FF8256"
  }
}));

const StudentReviewSlider = styled(Slider)(() => ({
  '& .slick-slide':{
      display: 'flex !important',
      justifyContent: 'space-evenly'
  },
  '& .slick-list': {
      width: "100% !important",
      padding: "5px 0px"
  },
  '& .reviewStudentImg': {
      width: "80px",
      height: "80px",
      '& .MuiAvatar-img' :{
          width: "80px",
          height: "80px",
          objectFit: 'fill',
          borderRadius: '50%',
      },
  },
  '& .studentReview': {
      display: "flex", 
      flexDirection: "column", 
      alignItems:"flex-start",
      background: "#FFF",
      gap: "4px",
      padding: "30px 60px 10px",
      minHeight: '130px',
      '@media (max-width: 768px)': {
          flexDirection: 'column',
          textAlign: 'center',
          padding: '10px',
      },
  },
  '& .studentReviewTop': {
      marginBottom: "8px",
      display: "flex",
      alignItems: "center",
      gap: "10px"
  },
  '& .studentName': {
      color: "#212121",
      fontSize: "22.6px",
      fontWeight: 600,
      lineHeight: "29.75px",
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '@media (max-width: 768px)': {
          fontSize: "12px",
          lineHeight: "normal",
      },
  },
  "& .studentLabel": {
    background: "#FD7101",
    borderRadius: "24.34px",
    height: "22.6px",
    width: "65.2px",
    color: "#ffffff",
    fontSize: "9.7px",
    fontWeight: 400,
    lineHeight: "11.74px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  '& .courseName': {
      border: "1px solid white",
      background: "white",
      color: "black",
      width: "max-content",
      borderRadius: "26.85px",
      padding: "5px",
      height: "min-content",
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 16,
      '@media (max-width: 1336px)': {
          fontSize: 14
      },
      [theme.breakpoints.down('md')]: {
          fontSize: 12
      }
  },
  '& .studentReviewText': {
      color: "#212121",
      fontFamily: 'Inter',
      fontSize: "15px",
      fontStyle: "normal",
      lineHeight: "normal",
      marginTop: "8px",
      fontWeight: 400,
      letterSpacing: "-0.018px",
      display: '-webkit-box',
      '-webkit-line-clamp': 4,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordBreak: "break-word",
      '@media (max-width: 1280px)': {
          fontSize: "13px",
      },
      '@media (max-width: 768px)': {
          fontSize: "12px",
          marginTop: "5px",
      },
  },
}));

const MainDiv = styled("div")(() => ({
  background:
    "linear-gradient(180deg, rgba(60,116,129,1) 200px, rgba(255,255,255,1) 200px)",
  padding: "25px 0",
  "& .paddingX28px": {
    padding: "0 28px"
  },
  "& .myCoursesText": {
    fontWeight: 700,
    fontSize: "37.63px",
    lineHeight: "45.54px",
    padding: "28px 0"
  },
  "& .courses-list-detail": {
    textDecoration: "none",
    color: "inherit",
    width: "100%"
  },
  "& .courses-list-detail > .list-item:hover": {
    backgroundColor: "rgb(34, 36, 34) !important",
  },
  "& .courses-list-detail > .list-item": {
    display: "inline-flex",
    gap: "10px",
    width: "100%",
    padding: "10px 0"
  },
  "& .courseNameText": {
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "18px",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    WebkitLineClamp: 3,
    overflow: "hidden",
    wordBreak: "break-word"
  },
  "& .courseNumbersText": {
    fontWeight: 600,
    fontSize: "10px",
    lineHeight: "12px",
  },
  "& .chapterDetailsNameText": {
    color: "white",
    fontWeight: 700,
    fontSize: "37.63px",
    lineHeight: "42px",
  },
  "& .playListTitle": {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: "#000000",
    fontSize: "17px",
    lineHeight: "20.57px",
  },
  "& .playListDuration": {
    color: "#000000",
    fontWeight: 400,
    fontSize: "13.73px",
    lineHeight: "16.62px",
  },
  "& .accordion-text": {
    display: "inline-flex",
    gap: "10px",
    alignItems: "center",
    color: "#3784F8",
    fontWeight: 600,
    fontSize: "18.43px",
    lineHeight: "22.3px",
  },
  "& .accordion-min-text": {
    fontWeight: 400,
    fontSize: "18.43px",
    lineHeight: "22.3px",
  },
  "& .accordionHeading": {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "29.05px",
  }, 
  "& .accordionHeadingClasses": {
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "24.2px",
  },
  "& .searchBox": {
    width: 'auto',
    height: "auto",
    marginTop: "0px !important",
    padding: "0px",
    marginBottom: "20px",
    "& .searchIconBtn": {
      width: 40,
      height: '40px',
      background: "linear-gradient(180deg, #FFF54B 0%, #F4C952 100%)",
      boxShadow: '0px 1px 3px 0px #0000001E inset',
      borderRadius: 27,
      color: '#212121',
    },
    "& .searchIcon": {
      fontSize: '1rem'
    },
    '& .searchInput': {
      width: '100%',
      color: "#000",
      '& input::placeholder': {
        color: "#FFFFFF !important",
        fontSize: "18px",
        fontFamily: 'Inter',
        lineHeight: "21.78px",
        fontWeight: 400,
        opacity: 1,
        marginRight: 8
      },
      '& .MuiInputBase-input': {
        color: "#FFFFFF !important",
        fontSize: "18px",
        fontFamily: 'Inter',
        lineHeight: "21.78px",
        fontWeight: 400,
        opacity: 1,
        marginRight: 8
      },
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderRadius: 32,
        border: "none",
        ':hover': {
          borderRadius: 32,
          border: "none",
        }
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: 32,
        border: "none"
      },
      '& .MuiOutlinedInput-adornedEnd': {
        padding: 0,
        paddingLeft: 8
      },
      "& .MuiOutlinedInput-input": {
        padding: "18px 6px 18px 18px"
      }
    },
  },
  "& .classDetailsTitle": {
    fontWeight: 700,
    fontSize: "29.63px",
    lineHeight: "42px",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    WebkitLineClamp: 3,
    overflow: "hidden",
    wordBreak: "break-word"
  },
  "& .mainRatingsDiv": {
    marginTop: "12px",
    display: "flex",
    flexDirection: "column",
    gap: "12px"
  },
  "& .grayDiv": {
    background: "#EDECEC",
    padding: "22px",
    borderRadius: "17px",
    display: "flex",
    flexDirection: "column",
    gap: "12px"
  },
  "& .rateThisCourseTitle": {
    fontFamily: 'Inter',
    color: "#000000",
    fontSize: "27.63px",
    lineHeight: "33.44px",
    fontWeight: 700,
    display: "flex",
    alignItems: "center"
  },
  "& .ratingComponent": {
    fontSize: '1.5rem', 
    color: '#FFD735',
  },
  "& .writeAReviewTitle": {
    fontFamily: 'Inter',
    color: "#000000",
    fontSize: "23.63px",
    lineHeight: "28.6px",
    fontWeight: 600,
  },
  "& .textareaInput": {
    width: "100%",
    maxWidth: "100%",
    minWidth: "100%",
    border: "none",
    outline: 0,
    borderRadius: "11px",
    padding: "12px"
  },
  "& .submitButtonDiv": {
    textAlign: "center"
  },
  "& .errorText": {
    color: "red",
    fontSize: "14px",
    marginTop: "8px"
  },
  "& .submitReviewButton": {
    background: "linear-gradient(180deg, #FFF54B 0%, #F4C952 100%)",
    boxShadow: "0px 1px 3px 0px #0000001E inset",
    borderRadius: "31.43px",
    height: "59px",
    width: "219px",
    border: "none",
    fontFamily: 'Inter',
    color: "#212121",
    fontSize: "20.94px",
    lineHeight: "25.34px",
    fontWeight: 600,
    cursor: 'pointer',
    "&:disabled": {
      cursor: "auto"
    }
  },
  "& .userTestimonialName": {
    fontFamily: 'Inter',
    color: "#212121",
    fontSize: "20.08px",
    lineHeight: "25.78px",
    fontWeight: 700,
  },
  "& .userTestimonialComment": {
    fontFamily: 'Inter',
    color: "#585858",
    fontSize: "20.06px",
    lineHeight: "25.12px",
    fontWeight: 400,
  },
  "& .averageRatingDiv": {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    padding: "30px 0px"
  },
  "& .noReviewsText": {
    fontFamily: 'Inter',
    color: "#64748B",
    fontSize: "16px",
    lineHeight: "19.36px",
    fontWeight: 400,
    textAlign: "center"
  },
  "& .RatingsAndReviewsText": {
    fontFamily: 'Inter',
    color: "#000000",
    fontSize: "27.63px",
    lineHeight: "33.44px",
    fontWeight: 700,
  },
  "& .RatingsAndReviewsNumber": {
    fontFamily: 'Inter',
    color: "#212121",
    fontSize: "24.39px",
    lineHeight: "29.52px",
    fontWeight: 700,
    margin: "0 40px 40px 60px"
  },
  "& .RatingsAndReviewsBasedText": {
    fontFamily: 'Inter',
    color: "#585858",
    fontSize: "10.01px",
    lineHeight: "12.12px",
    fontWeight: 400,
    marginTop: "23px"
  },
  "& .averageBox": {
    display: "flex",
    alignItems: "center",
    gap: "47px"
  },
  "& .starDiv": {
    display: "flex",
    flexDirection: "column",
    gap: "23px",
    width: "100%"
  },
  "& .ratingsAndProgressDiv": {
    display: "flex",
    alignItems: "center",
  }
}))

// Customizable Area End
