import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  styled,
  Modal,
  Snackbar,
  Button
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { IoIosArrowDown } from "react-icons/io";
import { Pagination } from "@material-ui/lab";
import MainLayout from "../../../components/src/MainLayout.web";
import {
  TableBody,
  TableStyle
} from "../../educational-user-profile/src/EducationalUserProfile.web";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { PiPen } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";
// Customizable Area End

import AdminDistrictController, {
  Props,
  configJSON,
  DistrictData
} from "./AdminDistrictController.web";

export default class AdminDistrict extends AdminDistrictController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDistrictTableBody = (item: DistrictData) => {
    return (
      <>
        <tr>
          <td style={{ borderRadius: "10px 0px 0px 10px" }}>
            <Box className="desc_checkbox">
              <input type="checkbox" style={webStyles.input} />
            </Box>
          </td>
          <td className="tr_desc" style={{ textAlign: "left" }}>
            {item.id}
          </td>
          <td style={{ textAlign: "left" }}>
            {item.attributes.name || "----"}
          </td>
          <td style={{ textAlign: "left" }}>
            {item.attributes.state.name || "----"}
          </td>
          <td style={{ textAlign: "left" }}>
            {item.attributes.created_on || "----"}
          </td>
          <td style={{ textAlign: "left" }}>
            {item.attributes.updated_on || "----"}
          </td>
          <td
            style={{ borderRadius: "0px 10px 10px 0px", textAlign: "center" }}
          >
            <Box className="desc_edit_icon_box">
              <PiPen
                data-test-id="editButtonId"
                className="desc_edit_icon"
                onClick={() => this.navigationEditDistrictScreen(item.id)}
                size={20}
                color="black"
              />
              <FaRegTrashAlt
                className="desc_edit_icon"
                onClick={() => this.handleOpenModal(item.id)}
                size={20}
                color="black"
              />
            </Box>
          </td>
        </tr>
        <Box className="table_divider" style={webStyles.tableDivider} />
      </>
    );
  };

  renderDistrictTable = () => {
    const isCurrentSort = (name: string) =>
      this.state.currentSortState.field === name &&
      this.state.currentSortState.order === "asc";
    const getSortName = (param: number) =>
      param === 0 ? "sort_name" : "sort_district_name";
    return (
      <Box>
        <TableStyle>
          <thead>
            <tr>
              <th>
                <Box
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center"
                  }}
                >
                  <input type="checkbox" style={webStyles.input} />
                </Box>
              </th>
              <th>
                <Box className="th_box" style={{ justifyContent: "left" }}>
                  <Typography className="title_th">
                    {configJSON.sNoText}
                  </Typography>
                  <IoIosArrowDown
                    className={`filter_icon ${isCurrentSort("id") ? "active" : ""}`}
                    data-test-id="sort_id"
                    onClick={() => this.handleSort("id")}
                    style={{
                      transform: isCurrentSort("id") ? "none" : "rotate(180deg)"
                    }}
                  />
                </Box>
              </th>
              {[
                configJSON.districtNameTextWithName,
                configJSON.stateText,
                configJSON.createdAtText,
                configJSON.updatedAtText
              ].map((header, index) => {
                let renderIcon = false;
                if (
                  [
                    configJSON.districtNameTextWithName,
                    configJSON.stateText
                  ].includes(header)
                ) {
                  renderIcon = true;
                }
                return renderTableRecord(
                  () => isCurrentSort(header),
                  header,
                  renderIcon,
                  index,
                  () => this.handleSort(header),
                  getSortName(index)
                );
              })}
              <th>
                <Box className="th_box" style={{ justifyContent: "center" }}>
                  <Typography className="title_th">
                    {configJSON.actionText}
                  </Typography>
                </Box>
              </th>
            </tr>
          </thead>
          <span style={webStyles.dividerLine} />
          <TableBody data-test-id="districtListId">
            {this.state.adminDistrictListData.data.map(item =>
              this.renderDistrictTableBody(item)
            )}
          </TableBody>
        </TableStyle>
        <PaginationBox
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "20px"
          }}
        >
          <Pagination
            data-test-id="paginationListId"
            count={this.state.pageCount}
            shape="rounded"
            boundaryCount={1}
            page={this.state.currentPageCount}
            siblingCount={0}
            onChange={this.onDistrictsPageChange}
          />
        </PaginationBox>
      </Box>
    );
  };

  renderDeleteDistrictModal = () => {
    return (
      <>
        <DeleteModalStyle
          style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
          open={this.state.openDeleteModal}
          onClose={this.handleCloseDeleteModal}
        >
          <Box className="modalComponent">
            <Box className="deleteTitleText" component={"p"}>
              {configJSON.deleteDistrictModalText}
            </Box>
            <Box className="buttonBox">
              <Button
                data-test-id="deleteButtonId"
                className="delButton"
                onClick={this.handleDeleteDistrict}
              >
                {configJSON.deleteText}
              </Button>
              <Button
                className="canButton"
                onClick={this.handleCloseDeleteModal}
              >
                {configJSON.cancelDistrictText}
              </Button>
            </Box>
          </Box>
        </DeleteModalStyle>
      </>
    );
  };

  notificationAlertDistrict = () => {
    return (
      <Snackbar
        open={this.state.isAlert}
        autoHideDuration={3000}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        onClose={this.onCloseAlert}
      >
        <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
      </Snackbar>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainLayout
        data-test-id="addButtonId"
        isLoading={this.state.loader}
        title="District"
        disableSearch
        actionButtons={[
          {
            icon: <AddCircleOutlineIcon />,
            iconType: "jsxElement",
            onClick: () => this.navigationAddDistrictScreen(),
            text: "Add District",
            testId: "backButtonId"
          }
        ]}
      >
        {this.notificationAlertDistrict()}
        {this.renderDeleteDistrictModal()}
        <main
          className="table_data"
          style={{ background: "rgb(252, 252, 252)" }}
        >
          {this.renderDistrictTable()}
        </main>
      </MainLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyles = {
  dividerLine: {
    height: "20px",
    display: "inline-block"
  },
  tableDivider: {
    height: "8px"
  },
  input: {
    width: "20px",
    height: "20px",
    border: "3px solid black",
    background: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  currentPage: {
    backgroundColor: "#176876",
    color: "white",
    borderRadius: "5px",
    padding: "0px 5px"
  },
  pagination: {
    width: "70%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }
};

export const PaginationBox = styled(Box)({
  "& .viewall_txt": {
    color: "#206575",
    fontSize: "14px",
    cursor: "pointer"
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    backgroundColor: "#206575",
    color: "#fff"
  }
});

export const DeleteModalStyle = styled(Modal)({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& .modalComponent": {
    width: "340px",
    height: "120px",
    padding: "10px 50px",
    borderRadius: "5px",
    backgroundColor: "#ffffff",
    outline: "none",
    margin: "20px",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 0px 3px px transparent"
  },
  "& .deleteTitleText": {
    width: "100%",
    height: "37px",
    fontWeight: 600,
    fontSize: "16px"
  },
  "& .buttonBox": {
    display: "flex",
    justifyContent: "space-between"
  },
  "& .delButton": {
    width: "145px",
    height: "40px",
    backgroundColor: "#FD7101",
    border: "0",
    color: "white",
    fontSize: "16px",
    fontWeight: 500
  },
  "& .canButton": {
    width: "145px",
    height: "40px",
    border: "1px solid #206575",
    color: "#206575",
    backgroundColor: "white",
    fontSize: "16px"
  }
});

export const renderTableRecord = (
  isCurrentSort: () => boolean,
  header: string,
  renderIcon: boolean,
  index: number,
  handleSort: () => void,
  dataTestId: string,
  colAlignment?: "left" | "center"
) => {
  return (
    <th key={index.toString()}>
      <Box
        className="th_box"
        style={{ justifyContent: !colAlignment ? "left" : colAlignment }}
      >
        <Typography className="title_th">{header}</Typography>
        {renderIcon && (
          <IoIosArrowDown
            className={`filter_icon ${isCurrentSort() ? "active" : ""}`}
            onClick={() => handleSort()}
            data-test-id={dataTestId}
            style={{
              transform: isCurrentSort() ? "none" : "rotate(180deg)"
            }}
          />
        )}
      </Box>
    </th>
  );
};
// Customizable Area End
