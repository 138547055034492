
import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    styled,
    TextField,
    Modal,
    Snackbar
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Alert from "@material-ui/lab/Alert";
import { Pagination } from "@material-ui/lab";
import { FaRegTrashAlt } from "react-icons/fa";
import { PiPen } from "react-icons/pi";
const drawerWidth = 250;
const closeDrawer = 0;
import {
  CheckBtn,
  TableBody,
  TableStyle
} from "../../educational-user-profile/src/EducationalUserProfile.web";
import MainLayout from "../../../components/src/MainLayout.web";
import { renderTableRecord } from "../../user-profile-basic/src/AdminDistrictList.web";

// Customizable Area End
import AdminLanguageController, {
    LangDataType,
    Props,
} from "./AdminLanguageController.web";
import { RxCross2 } from "react-icons/rx";

export default class AdminLanguage extends AdminLanguageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    handleDeleteIconClick = (itemId: any) => {
        this.handleOpenDeleteModal(itemId);
    }
    handleEditIconClick = (itemId: any) => {
        this.handleOpenEditModal(itemId);
    }

    handleCreateIconClick = () => {
        this.handleCreateModal();
    }
    renderTableBody = (item: LangDataType, index: number) => {
        return (
            <>
                <tr>
                    <td>
                        <Box className="desc_checkbox">
                            <input
                                data-test-id={"rowCheckbox"}
                                type="checkbox"
                                checked={this.state.isChecked[index]}
                                onChange={() => this.handleRowCheckboxChange(index)}
                                style={webStyles.input}
                            />
                        </Box>
                    </td>
                    <td>{item.id}</td>
                    <td>{item.attributes.language_name?.length > 20 ? `${item.attributes.language_name.slice(0, 20)}...` : item.attributes.language_name || "----"}</td>
                    <td>{item.attributes.sample_text?.length > 40 ? `${item.attributes.sample_text.slice(0, 40)}...` : item.attributes.sample_text || "----"}</td>
                    <td>
                        {<CheckBtn className="switchs">
                            <input data-test-id={'statusCheckTestId'} type="checkbox" onChange={() => this.handleStatusApiCall(item.id)} checked={item.attributes.status} />
                            <span className="slider round"></span>
                        </CheckBtn>}
                    </td>
                    <td>
                        <Box className="desc_edit_icon_box">
                            <PiPen
                                data-test-id={"editIconTestId"}
                                className="desc_edit_icon"
                                color={"black"}
                                size={20}
                                onClick={() => this.handleEditIconClick(item)}
                            />
                            <FaRegTrashAlt
                               data-test-id={"deleteIconeTestId"}
                               className="desc_edit_icon"
                               color={"black"}
                               size={17}
                               onClick={() => this.handleDeleteIconClick(item.id)}
                            />
                        </Box>
                    </td>
                </tr>
                <Box className="table_divider" style={webStyles.tableDivider1} />
            </>
        )
    }

    getSortTestId = (header: string) => {
        const headerMap: { [key: string]: string } = {
        "Language": "sort_name",
        "S.No": "sort_id",
        Status: "sort_status",
        };

        return headerMap[header] || "sort";
    };

    renderTable1 = () => {
        const isCurrentSort = (name: string) =>
            this.state.currentSortState.field === name &&
            this.state.currentSortState.order === "asc";
        return (
            <Box>
                <TableStyle>
                    <thead>
                        <tr>
                            <th>
                                <Box
                                    style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                    }}
                                >
                                    <input
                                        type="checkbox"
                                        data-test-id="headerCheckbox"
                                        style={webStyles.input}
                                        checked={this.state.isHeaderChecked}
                                        onChange={this.handleCheckboxChange}
                                    />
                                </Box>
                            </th>
                            {[                               
                                "S.No",
                                "Language",
                                "Sample Text",
                                "Status",
                                "Action"
                            ].map((header, index) => {
                                let renderIcon = false;
                                if (["S.No", "Language", "Status"].includes(header)) {
                                    renderIcon = true;
                                }
                                return renderTableRecord(
                                    () => isCurrentSort(header),
                                    header,
                                    renderIcon,
                                    index,
                                    () => this.handleSort(header),
                                    this.getSortTestId(header),
                                    "center"
                                );
                            })}
                        </tr>
                    </thead>
                    <span style={webStyles.dividerLine}></span>
                    <TableBody>
                        {this.state.adminLangData?.data?.map((item, index) => this.renderTableBody(item, index))}
                    </TableBody>
                </TableStyle>
                <PaginationBoxLang
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginTop: "20px",
                    }}
                >
                    <Pagination
                        data-test-id="pageChangebutton"
                        count={this.state.pageCount}
                        shape="rounded"
                        boundaryCount={1}
                        page={this.state.currentPageCount}
                        siblingCount={0}
                        onChange={this.onPageChange}
                    />
                </PaginationBoxLang>
            </Box>
        )
    }

    renderDeleteLanguageModal = () => {
        return (
            <LanguageDeleteModal
                style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
                data-test-id="LanguageDeleteModalId"
                open={this.state.openDeleteModal}
                onClose={this.handleCloseDeleteModal}
            >
                <ModalView>
                    <DeleteText component={"p"}>
                        Do you want to delete this language?
                    </DeleteText>
                    <ModalButton>
                        <DeleteButton data-test-id="deleteBtnTestId" onClick={() => this.handleDeleteApiCall(this.state.deleteId)}>Delete</DeleteButton>
                        <CancelButton onClick={() => this.handleCloseDeleteModal()} >Cancel</CancelButton>
                    </ModalButton>
                </ModalView>
            </LanguageDeleteModal>
        )
    }


    renderEditLanguageModal = () => {

        return (

            <LanguageCreateModal
                style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
                data-test-id="AddInstituteModalId"
                open={this.state.openEditModal}
                onClose={this.handleCloseDeleteModal}
            >
                <AddCourseModalView>

                    <AddCourseModalBox>
                        <ModalHeadingText >Update Language</ModalHeadingText>
                        <RxCross2 onClick={() => this.handleCloseEditCourseModal()} style={{ height: "25px", width: "25px" }} />
                    </AddCourseModalBox>

                    {this.languageInput()}
                    {this.sampleText()}


                    {this.statusText()}

                                        <ButtonBox>
                        <CancelEditButton onClick={() => this.handleCloseEditModal()} >Cancel</CancelEditButton>
                        <div style={{ width: "15px" }}></div>
                        <UpdateButton data-test-id="updateBtnTestId" onClick={() => this.handleUpdateApiCall(this.state.editId)}>Update</UpdateButton>

                    </ButtonBox>
                </AddCourseModalView>
            </LanguageCreateModal>

        )
    }

    languageInput = () => {
        return (
            <CourseFieldBox>
                <CourseFieldControlBox>
                    <Typography className="input_txt">Language Name*</Typography>
                    <CourseInputBox
                        variant="outlined" fullWidth
                        data-test-id="languageId"
                        onChange={this.handleLanguageName}
                        value={this.state.language_name}
                    />
                </CourseFieldControlBox>
            </CourseFieldBox>
        )
    }
    sampleText = () => {
        return (
            <CourseFieldBox>
                <CourseFieldControlBox>
                    <Typography className="input_txt">Type sample text for conversion*</Typography>

                    <CourseInputBox
                        variant="outlined" fullWidth
                        data-test-id="sampleTextId"
                        onChange={this.handleSampleText}
                        value={this.state.sample_text}
                    />
                </CourseFieldControlBox>
            </CourseFieldBox>
        )
    }
    statusText = () => {
        return (
            <CourseFieldBox>
                <CourseFieldControlBox>
                    <Typography className="input_txt">status</Typography>
                    <CourseCheckBoxButton className="switch">
                        <input type="checkbox"
                            data-test-id="checkboxId"
                            onChange={this.handleCourseStatusCheck} checked={this.state.status}
                        />
                        <span className="slider round"></span>
                    </CourseCheckBoxButton>
                </CourseFieldControlBox>
            </CourseFieldBox>
        )
    }

    renderCreateLanguageModal = () => {

        return (

            <LanguageCreateModal
                style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
                data-test-id="AddInstituteModalId"
                open={this.state.openCreateModal}
                onClose={this.handleCloseDeleteModal}
            >
                <AddCourseModalView>
                {this.notificationAlert1()}

                    <AddCourseModalBox>
                        <ModalHeadingText >Add Language</ModalHeadingText>
                        <RxCross2 onClick={() => this.handleCloseCreateCourseModal()} style={{ height: "25px", width: "25px" }} />
                    </AddCourseModalBox>

                    {this.languageInput()}
                    {this.sampleText()}


                    {this.statusText()}
                    <ButtonBox>
                        <CancelEditButton onClick={() => this.handleFeildReset()} >Reset</CancelEditButton>
                        <div style={{ width: "15px" }}></div>
                        <CreateButton data-test-id="createBtnTestId" onClick={() => this.handleCreateApiCall()}>Add Language</CreateButton>

                    </ButtonBox>
                </AddCourseModalView>
            </LanguageCreateModal>

        )
    }

    notificationAlert1 = () => {
        return (
            <Snackbar
                open={this.state.isAlert}
                autoHideDuration={3000}
                anchorOrigin={{ horizontal: 'center', vertical: "top" }}
                onClose={this.oncloseAlert}
                data-test-id="oncloseAlert"
            >
                <Alert
                    severity={this.state.alertType}>{this.state.alertMsg}</Alert>
            </Snackbar>
        )
    }
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <MainLayout
                data-test-id="mainLayoutEvent"
                handleSearch={(e: any) => this.handleLanguageSearch(e)}
                isLoading={this.state.loader}
                title={"Languages"}
                actionButtons={[
                    {
                        icon: <AddCircleOutlineIcon />,
                        iconType: "jsxElement",
                        onClick: () => this.handleCreateIconClick(),
                        text: "Add Language",
                        testId: "addLanguageTestId"
                    }
                ]}
                isStudent={false}
            >
                {this.notificationAlert1()}
                {this.renderDeleteLanguageModal()}
                {this.renderEditLanguageModal()}
                {this.renderCreateLanguageModal()}
                <main className="table_data">
                    {this.renderTable1()}
                </main>
            </MainLayout>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
const LanguageDeleteModal = styled(Modal)({
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "100%",

    justifyContent: "center"
});

const LanguageCreateModal = styled(Modal)({
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
});
const AddCourseModalView = styled(Box)({
    height: "313px",
    width: "651px",
    borderRadius: '18px',
    padding: "10px 30px",
    outline: "none",
    backgroundColor: '#ffffff',
    display: "flex",
    margin: "20px",
    boxShadow: "0px 0px 3px px transparent",
    flexDirection: "column"
});
const CourseFieldBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    "@media (max-width:1330px)": {
        flexDirection: "column",
        alignItems: "start",
    },
    justifyContent: "flex-start"
});

const CourseFieldControlBox = styled(Box)({
    display: "flex",
    margin: "10px 0",
    justifyContent: "flex-start",
    marginRight: '93px',
    alignItems: "center",

    "& .input_txt": {
        textTransform: "capitalize",
        fontFamily: "Inter",
        width: "160px",
        color: "#192252",
        fontSize: "16px",
        fontStyle: "normal",
        lineHeight: "24px",
        fontWeight: 400

    },
    "& .file_upload_box": {
        color: "#fff",
        border: "1px solid black",
        width: "490px",
        height: "44px",
        display: "flex",
        borderRadius: "4px",
        justifyContent: "space-between",
        borderColor: "#D4D4D4",
        alignItems: "center",

        "& .lable_txt": {
            height: "90%",
            alignItems: "center",
            backgroundColor: "#3F6474",
            display: "flex",
            marginRight: "2px",
            borderRadius: "4px",
            justifyContent: "center",
            padding: "0px 30px",
            fontSize: "12px",
        },
        "& .app_icon_txt": {
            marginLeft: "10px",
            wordSpacing: "-2px",
            color: "#A9A9A9",
            fontSize: "13px",

        },
    },
    "@media (max-width:875px)": {
        alignItems: "start",
        flexDirection: "column",

        "& .input_txt": {
            width: "100%",
            marginBottom: "8px",

        }
    },
});
const CourseInputBox = styled(TextField)({
    "& .MuiOutlinedInput-input": { padding: "0px 14px" },
    width: "490px",
    "& .MuiInputBase-input": { height: "44px" },
    "& .MuiOutlinedInput-root": { "&.Mui-focused fieldset": { borderColor: "#3F6474" } },
});
const CourseCheckBoxButton = styled("label")({
    "& .slider": {
        bottom: "-1px",
        top: 0,
        transition: ".4s",
        WebkitTransition: ".4s",
        position: "absolute",
        backgroundColor: "#ccc",
        cursor: "pointer",
        right: 0,
        left: 0,
        borderRadius: "20px",
    },
    "& input:checked + .slider:before": { left: "-10px" },
    "&.switch": {
        position: "relative",
        marginTop: "5px",
        width: "36px",
        "& input": {
            height: 0,
            opacity: 0,
            width: 0,
        },
        height: "19px",
        display: "inline-block",

    },
    "& input:checked + .slider": { backgroundColor: "#708328" },
    "& .slider:before": {
        height: "18px",
        content: '""',
        left: "1px",
        position: "absolute",
        width: "18px",
        WebkitTransition: ".4s",
        backgroundColor: "white",
        transition: ".4s",
        borderRadius: "50%",
        bottom: "1px",

    },
});
const ModalHeadingText = styled(Typography)({
    fontWeight: 700,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "18px",
    color: "#000",
    lineHeight: "26px",
    letterSpacing: "-0.12px"
});
const AddCourseModalBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    paddingTop: 10,
    paddingBottom: 22
});

const ModalView = styled(Box)({
    padding: "10px 50px",
    outline: "none",
    backgroundColor: '#ffffff',
    height: "120px",
    width: "340px",
    borderRadius: '5px',
    boxShadow: "0px 0px 3px px transparent",
    flexDirection: "column",
    display: "flex",
    margin: "20px",

});
const webStyles = {

    hideDrawer: {
        width: `calc(100% - ${closeDrawer}px)`,
    },
    showDrawer: {
        width: `calc(100% - ${drawerWidth}px)`,
    },
    tableDivider1: {
        height: "8px",
    },
    dividerLine: {
        height: "20px",
        display: "inline-block",
    },
    tableAction: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    modalContent: {
        width: "90%",
        maxWidth: "700px",
        backgroundColor: "#fff",
        borderRadius: "5px",
    },
    input: {
        width: "20px",
        height: "20px",
        border: "3px solid black",
        background: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    currentPage: {
        backgroundColor: "#176876",
        color: "white",
        borderRadius: "5px",
        padding: "0px 5px",
    },
    pagination: {
        width: "70%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },

    pageWrapper: {
        width: "70%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    tableBodyMsg: {
        display: "inline-flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "40vh",
        width: "100%",
        marginTop: "50px",
        border: "1px solid red",
    },
    tableFooter: {
        display: "flex",
        alignSelf: "flex-end",
        alignItems: "center",
        justifyContent: "space-between",
        width: "320px",
        marginRight: "40px",
    },
};
const PaginationBoxLang = styled(Box)({

    "& .MuiPaginationItem-page.Mui-selected": {
        color: "#fff",
        backgroundColor: "#206575",
    },
    "& .viewall_txt": {
        fontSize: "14px",
        color: "#206575",
        cursor: "pointer",
    },
});
const DeleteText = styled(Box)({
    fontSize: "16px",
    height: "37px",
    fontWeight: 600,
    width: "100%"
});
const ModalButton = styled(Box)({
    display: "flex",
    justifyContent: "space-between",

});
const ButtonBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px"
});
const DeleteButton = styled("button")({
    height: "40px",
    width: "145px",
    border: "0",
    backgroundColor: "#FD7101",
    fontSize: "16px",
    color: "white",
    fontWeight: 500
});
const UpdateButton = styled("button")({
    height: "45px",
    width: "145px",
    border: "0",
    backgroundColor: "#1F6575",
    fontSize: "16px",
    color: "white",
    fontWeight: 500
});
const CreateButton = styled("button")({
    border: "0",
    backgroundColor: "#1F6575",
    color: "white",
    height: "45px",
    width: "145px",
    fontSize: "16px",
    fontWeight: 500
});
const CancelButton = styled("button")({
    height: "40px",
    width: "145px",
    color: "#206575",
    border: "1px solid #206575",
    fontSize: "16px",
    backgroundColor: "white",
});
const CancelEditButton = styled("button")({
    height: "45px",
    width: "108px",
    color: "#206575",
    border: "1px solid #1F6575",
    fontSize: "16px",
    backgroundColor: "white",
    marginLeft: "10px"
});
// Customizable Area End

