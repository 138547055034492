// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.userName = "User name"
exports.location = "Location"
exports.url = "Location"
exports.educationList = "/profile/educational_qualifications"
exports.projectList = "/profile/projects"
exports.awardList = "/profile/awards"
exports.patentList = "/profile/publication_patents";



exports.educationalTitle = "Educational Qualification";
exports.projectLabel = "Project";
exports.awardsLabel = "Awards";
exports.publicationPatentLabel = "Publication Patents";
exports.showMore = "Show More";
exports.noProjectText = "No project...";
exports.noQualiText = "No qualifications";
exports.noAwards = "No Award...";
exports.noPublicationPatents = "No Publication Patents...";
exports.closeText = "Close";

exports.getEducationContentType = "application/json";
exports.getEducationApiMethod = "GET";
exports.getEducationApiEndPoint = "profile/educational_qualifications";

exports.getProjectContextType = "application/json";
exports.getProjectApiMethod = "GET";
exports.getProjectApiEndPoint = "profile/projects";

exports.getAwardsContextType = "application/json";
exports.getAwardsApiMethod = "GET";
exports.getAwardsApiEndPoint = "profile/awards";

exports.getPatentsContextType = "application/json";
exports.getPatentsApiMethod = "GET";
exports.getPatentsApiEndPoint = "profile/publication_patents";
exports.postAPiEndPoint = "POST";
exports.getStudentDataApiEndPoint="account_block/accounts"
exports.addStudentContentType = "multipart/form-data";

exports.exportStudentCSVApiEndPoint="account_block/export_csv?role_name=Student"
exports.apiMethodTypeGet="GET"
exports.stateApiEndPoint = `bx_block_profile/states/show_states/1`
exports.CityAPiEndPoint = `bx_block_profile/districts/show_districts/`
exports.addStudentApiEndPoint="account_block/accounts/"
exports.deleteStudentApiEndPoint="account_block/accounts/"
exports.filterDataAPIEndPoint="/account_block/search"
exports.apiMethodTypeDelete="DELETE"
exports.apiMethodTypeUpdate="PUT"
exports.updateStatusApiEndpoint="account_block/accounts/update_status/"
exports.updateStudentApiEndPoint="account_block/accounts"
exports.deleteInstructorAPIEndPoint="/account_block/accounts/"

exports.tokenExpiredMessage = "Token has Expired";
exports.notFoundMessage = "Record not found.";
exports.accountStatusChangedMessage = "Account status changed successfully";
exports.studentAccountStatusUpdatedMessage = "student account successfully updated.";
exports.accountCreatedMessage = "account successfully created.";
exports.instructorAccountDeletedMessage = "Instructor account was successfully destroyed.";
exports.maxFileSize = 2 * 1024 * 1024;
exports.sessionExpireMsg = "Session is expired. Please login again.";
exports.fullNameErrMsg = "Full name must contain only letters and spaces, and must be at least 3 characters long."
exports.instructorQualificationErrMsg = "Instructor qualifications cannot be more than 100 characters";
exports.instructorteachingErrMsg = "Instructor teaching cannot be more than 100 characters";
exports.detailsQuoteErrMsg = "Details/Quote cannot be more than 800 characters";
exports.shortDetailsQuoteErrMsg = "Short Details/Quote cannot be more than 800 characters";
exports.bulkDeleteAdmin = '/account_block/bulk_delete_admin';
// Customizable Area End