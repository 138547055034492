import React, { Component } from 'react';
import { Box, Button, Dialog, styled, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import StarsOutlinedIcon from '@material-ui/icons/StarsOutlined';
import { removeStorageData ,getStorageData} from 'framework/src/Utilities';
import Avatar from '@material-ui/core/Avatar';
import Skeleton from '@material-ui/lab/Skeleton';
import { FiDownload, FiPhone, FiLogOut } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import {  Close, DeleteOutline } from '@material-ui/icons';
const config = require("framework/src/config.js")
interface CustomDrawerProps {
  userPic: string;
  fullName: string;
  pathname: string;
  phoneNumber: string;
}

interface CustomDrawerState {
  isDelete:boolean;
}

const CustomListItemIcon = styled(ListItemIcon)({
  minWidth: '30px',
  color: 'inherit',
  '& .MuiSvgIcon-root': {
    width: '24px !important',
    height: '24px !important'
  },
});
const itemsList = [
  {
    label: 'My Profile',
    icon: <PersonOutlinedIcon />,
    href: '/UserProfile'
  },
  {
    label: 'My Purchases',
    icon: <ShoppingCartOutlinedIcon />,
    href: '/Purchases'
  },
  {
    label: 'My Wishlist',
    icon: <FavoriteBorderIcon />,
    href: '/Wishlist'
  },
  {
    label: 'My Cash & Coupons',
    icon: <StarsOutlinedIcon />,
    href: '/MyCash'
  },
  {
    label: 'Contact us',
    icon: <FiPhone fontSize={24} />,
    href: '/ContactusWeb'
  },
  {
    label: 'Delete Account',
    icon: <DeleteOutline />,
    href: ''
  },
  {
    label: 'Logout',
    icon: <FiLogOut fontSize={24} />,
    href: '/?logout_user=true',
    callback: () => {
      removeStorageData('emailOTPToken');
      removeStorageData('loginEmail');
      removeStorageData('profilePic');
      removeStorageData('profileUsername');
      removeStorageData('userRole');
      removeStorageData('cartCount');
      removeStorageData('wishlistCount');
      removeStorageData('coupon_code');
      removeStorageData('discount_type');
      removeStorageData('profilePhoneNumber');
    },
  }
];

class UserSidebarMenu extends Component<CustomDrawerProps, CustomDrawerState> {
  constructor(props: CustomDrawerProps) {
    super(props);

    this.state = {
      isDelete:false
    };
  }

  cancelBtn=()=>{
    this.setState({isDelete:false})
  }
  deleteAcc=async ()=>{
    const token = await getStorageData('loginToken')
    try {
      const response = await fetch(`${config.baseURL}/bx_block_login/login/delete_account`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'token': token, 
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete account. Please try again.');
      }

    } finally {
      removeStorageData('emailOTPToken');
      removeStorageData('loginEmail');
      removeStorageData('profilePic');
      removeStorageData('profileUsername');
      removeStorageData('userRole');
      removeStorageData('cartCount');
      removeStorageData('wishlistCount');
      removeStorageData('coupon_code');
      removeStorageData('discount_type');
      removeStorageData('profilePhoneNumber');
      this.setState({ isDelete: false });
      window.location.href="/"
    }
}


  render() {
    return (
      <>
      <div
        style={{ width: '100%', background: '#FFEFE7', borderRadius: '8px' }}
      >
        <div
          style={{
            borderBottom: '2px solid #FF8256',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 150,
            background: '#FFDBC9',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            fontSize: 16,
            gap: 3,
          }}
        >
          <Avatar
            style={{ width: 64, height: 64 }}
            src={this.props.userPic}
            alt=""
          />
          {this.props.fullName ? (
            <strong style={{ margin: '2px auto',
              width: "70%",
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              wordBreak: 'break-word' as const,
              WebkitLineClamp: 2,
              display: "-webkit-inline-box",
              WebkitBoxOrient: "vertical" as const,
              textTransform: "capitalize" as const,
              hyphens: 'auto' as const,
              textAlign: 'center'
            }}>{this.props.fullName}</strong>
          ) : (
            <Skeleton variant="text" style={{ width: '60%' }} />
          )}
          {this.props.phoneNumber !== '+91 ' ? (
            <span style={{ fontSize: 14 }}>{this.props.phoneNumber}</span>
          ) : (
            <Skeleton variant="text" style={{ width: '70%' }} />
          )}
        </div>
        <List>
          {itemsList.map(item => {
            const isSelected = item.href === this.props.pathname;
            return (
              <ListItem
                button
                component={item.href ? Link : 'div'}
                onClick={() => {
                  if (item.label === "Delete Account") {
                      this.setState({ isDelete: true });
                  }
                  if (item.callback) {
                      item.callback();
                  }
              }}
                to={item.href || undefined}
                key={item.label}
                data-test-id={item.label}
                style={{
                  paddingLeft: 25,
                  color: isSelected ? '#206575' : 'black',
                  ...(isSelected
                    ? {
                        fontWeight: 'bold',
                        background: 'transparent'
                      }
                    : {}),
                }}
              >
                <CustomListItemIcon>{item.icon}</CustomListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            );
          })}
        </List>
      </div>
      <CustomDialog maxWidth="lg" open={this.state.isDelete} >
          <Close style={{width:'18px',height:'18px',cursor:'pointer',position:'absolute',top:12,right:20}} onClick={this.cancelBtn}/>
          <Box style={{display:'flex',justifyContent:'center'}}>
            <Box style={webStyle.deletebox}>
              <DeleteOutline style={{color:'#DC2626'}}/>
            </Box>
          </Box>
          <Typography style={webStyle.text}>
            <b>Account Deletion Policy</b> <br />
            You are about to permanently delete your account on CHSE EXPRESS. This action is irreversible and will result in the loss of all your progress, data, and access to the app. <br /> <br />

            <b>Understanding the Consequences:</b> <br />
            Deleting your account is a serious decision that cannot be undone. By proceeding, you are permanently removing all information associated with your profile, including: <br />

            <ul>
                <li>Personal Data: Your name, email address, profile picture, and any other information provided during registration.</li>
                <li>Progress and Achievements: All completed courses, quizzes, and earned badges or awards will be lost.</li>
                <li>Purchased Items and Subscriptions: Access to any paid courses or subscriptions will be terminated.</li>
                <li>Communication History: Any messages or interactions with other users or the app support team will be deleted.</li>
            </ul>

            Please note that while we strive to respect your privacy, certain unidentified data may be retained for statistical and analytical purposes. This information will not be personally identifiable. <br /><br />

            <b>Data Retention Policy</b> <br />
            We understand the importance of data privacy. The unidentified data that may be retained includes: <br />

              <ul>
                <li><b>Usage Patterns: </b>Time spent on different features.</li>
                <li><b>Learning Outcomes: </b>Quiz performance.</li>
                <li><b>Technical Information: </b>Device type, app version.</li>
              </ul>
            This data is used to improve the app's functionality and user experience for all users. It does not contain any personal information that can be traced back to you.<br /><br />

            <b>Confirmation:</b> <br />
            We want to ensure you fully understand the implications of deleting your account. Please carefully consider the information provided before making your decision. If you have any questions or concerns, our support team is available to assist you.<br /><br />
            
            If you are sure, tap "Delete Account" to confirm. Otherwise, tap "Cancel" to keep your account active.
          </Typography>
          <Box display="flex" justifyContent="center" gridGap={150}>
            <Button onClick={this.cancelBtn} style={webStyle.cancelBtn}>Cancel</Button>
            <Button onClick={this.deleteAcc} style={webStyle.deleteButton}>Delete account</Button>
          </Box>
      </CustomDialog>
      </>
    );
  }
}

export default UserSidebarMenu;

const CustomDialog = styled(Dialog)({
  borderRadius: '16px',
  padding: '40px 38px',
  position:'relative',
  '& .MuiDialog-paperWidthLg': {
    minWidth: '363px',
    borderRadius: '16px',
    padding: '40px 38px',
    position: 'relative',
    paddingBottom: '27px',
    gap: "15px",
    "@media only screen and (max-width: 816px)": {
      minWidth: '200px'
    },
    "@media only screen and (max-width: 553px)": {
      minWidth: '200px'
    }
  }
})
const webStyle={
  text:{
    color:'#000000',
    fontFamily: 'Inter',
    fontWeight: 400,
    lineHeight: '40px',
    fontSize: '15px',
  },
  deleteButton:{
    color:'#FFFFFF',
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: '28.19px',
    background:'#FF8256',
    borderRadius:'20px',
    cursor:'pointer',
    width: "200px",
    '@media (min-width: 1366px) and (max-width: 1920px)': {
      fontSize: '22.29px',
    },
    '@media (min-width: 128px) and (max-width: 1366px)': {
      fontSize: '18.29px',
    },
    '@media (min-width: 600px) and (max-width: 1280px)': {
      fontSize: '18.29px',
    },
  },
  cancelBtn:{
    color:'#FF8256',
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: '28.19px',
    cursor:'pointer',
    width: "200px",
    border: "1px solid #FF8256",
    borderRadius:'20px',
    '@media (min-width: 1366px) and (max-width: 1920px)': {
      fontSize: '23.29px',
    },
    '@media (min-width: 128px) and (max-width: 1366px)': {
      fontSize: '20.29px',
    },
    '@media (min-width: 600px) and (max-width: 1280px)': {
      fontSize: '20.29px',
    },
  },
  center:{display:'flex',justifyContent:'end'},
  deletebox:{padding:'5px 10px 11px 8px',background:'pink',
    borderRadius:'50%',width:'30px',height:'30px',display:'flex',justifyContent:'center'}
}