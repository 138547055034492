import React from "react";

// Customizable Area Start
import { Box, Typography, styled, Modal, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import MainLayout from "../../../components/src/MainLayout.web";
import {
  TableBody,
  TableStyle
} from "../../educational-user-profile/src/EducationalUserProfile.web";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { PiPen } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";
const drawerWidth = 250;
const closeDrawer = 0;

import BannerController, {
  BannerListData,
  Props
} from "./BannerController.web";
import { DelButton } from "./HeaderBanner.web";

// Customizable Area End

export default class Banner extends BannerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getUserToken();
    await this.getBannersList();
  }

  renderTableBody = (item: BannerListData, index: number) => {
    return (
      <>
        <tr>
          <td style={{ textAlign: "left" }}>{item.attributes.banner_title}</td>
          <td style={{ textAlign: "center" }}>
            <CheckBtn className="switch">
              <input
                type="checkbox"
                data-test-id="statusTestId"
                onClick={() =>
                  this.handleToggleStatus(
                    item.id,
                    item.attributes.status,
                    index
                  )
                }
                checked={item.attributes.status}
              />
              <span className="slider round" />
            </CheckBtn>
          </td>
          <td
            style={{ borderRadius: "0px 10px 10px 0px", textAlign: "center" }}
          >
            <Box className="desc_edit_icon_box">
              <PiPen
                data-test-id={"editIconTestId"}
                className="desc_edit_icon"
                size={20}
                onClick={() => this.handleEditBanner(item.id)}
                color="black"
              />
              <FaRegTrashAlt
                data-test-id={"deleteModalTest"}
                className="desc_edit_icon"
                size={20}
                onClick={() => this.handleOpenModal(parseInt(item.id, 10))}
                color="black"
              />
            </Box>
          </td>
        </tr>
        <Box className="table_divider" style={webStyles.tableDivider} />
      </>
    );
  };

  renderTable = () => {
    return (
      <Box>
        <TableStyle>
          <thead>
            <tr>
              <th>
                <Box className="th_box">
                  <Typography className="title_th">Name</Typography>
                </Box>
              </th>
              <th style={{ width: 50 }}>
                <Box className="th_box" style={{ justifyContent: "center" }}>
                  <Typography className="title_th">Status</Typography>
                </Box>
              </th>
              <th style={{ width: 100 }}>
                <Box className="th_box" style={{ justifyContent: "center" }}>
                  <Typography className="title_th">Action</Typography>
                </Box>
              </th>
            </tr>
          </thead>
          <span style={webStyles.dividerLine} />
          <TableBody>
            {this.state.bannerList.map((item, index) =>
              this.renderTableBody(item, index)
            )}
          </TableBody>
        </TableStyle>
      </Box>
    );
  };

  renderDeleteModal = () => {
    return (
      <>
        <DeleteModal
          style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
          data-test-id="deleteModalId"
          open={this.state.openDeleteModal}
          onClose={this.handleCloseDeleteModal}
        >
          <ModalComponent>
            <DeleteTitleText component={"p"}>
              Do you want to delete this Banner?
            </DeleteTitleText>
            <ButtonBox>
              <DelButton
                data-test-id="closemodalid"
                onClick={() => this.handleDeleteBanner(this.state.deleteId)}
              >
                Delete
              </DelButton>
              <CanButton onClick={() => this.handleCloseDeleteModal()}>
                Cancel
              </CanButton>
            </ButtonBox>
          </ModalComponent>
        </DeleteModal>
      </>
    );
  };

  notificationAlert = () => {
    return (
      <Snackbar
        open={this.state.isAlert}
        autoHideDuration={3000}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        onClose={this.oncloseAlert}
        data-test-id="alertTestId"
      >
        <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
      </Snackbar>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainLayout
        isLoading={this.state.isLoading}
        disableSearch
        title="Web Footer Banners"
        data-test-id="mainLayoutEvent"
        actionButtons={[
          {
            icon: <AddCircleOutlineIcon />,
            iconType: "jsxElement",
            onClick: () => this.handleAddBanner(),
            text: "Add Banner",
            testId: "addButtonId"
          }
        ]}
      >
        {this.notificationAlert()}
        {this.renderDeleteModal()}
        <main
          className="table_data"
          style={{ background: "rgb(252, 252, 252)" }}
        >
          {this.renderTable()}
        </main>
      </MainLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  showDrawer: {
    width: `calc(100% - ${drawerWidth}px)`
  },
  hideDrawer: {
    width: `calc(100% - ${closeDrawer}px)`
  },
  dividerLine: {
    height: "20px",
    display: "inline-block"
  },
  tableDivider: {
    height: "8px"
  },
  input: {
    width: "20px",
    height: "20px",
    border: "3px solid black",
    background: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  tableActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  currentPage: {
    backgroundColor: "#176876",
    color: "white",
    borderRadius: "5px",
    padding: "0px 5px"
  },
  modalContent: {
    width: "90%",
    maxWidth: "700px",
    backgroundColor: "#fff",
    borderRadius: "5px"
  },
  tableFooter: {
    display: "flex",
    alignSelf: "flex-end",
    alignItems: "center",
    justifyContent: "space-between",
    width: "320px",
    marginRight: "40px"
  },
  pagination: {
    width: "70%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  pageWrapper: {
    width: "70%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  tableBodyMsg: {
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "40vh",
    width: "100%",
    marginTop: "50px",
    border: "1px solid red"
  }
};

const CheckBtn = styled("label")({
  "&.switch": {
    position: "relative",
    display: "inline-block",
    width: "36px",
    height: "19px",
    marginTop: "5px",
    "& input": {
      opacity: 0,
      width: 0,
      height: 0
    }
  },
  "& .slider": {
    position: "absolute",
    cursor: "pointer",
    top: 0,
    left: 0,
    right: 0,
    bottom: "-1px",
    backgroundColor: "#ccc",
    WebkitTransition: ".4s",
    transition: ".4s",
    borderRadius: "20px"
  },
  "& .slider:before": {
    position: "absolute",
    content: '""',
    height: "18px",
    width: "18px",
    left: "1px",
    bottom: "1px",
    backgroundColor: "white",
    WebkitTransition: ".4s",
    transition: ".4s",
    borderRadius: "50%"
  },
  "& input:checked + .slider": {
    backgroundColor: "#708328"
  },
  "& input:checked + .slider:before": {
    left: "-10px"
  }
});

const DeleteModal = styled(Modal)({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

const ModalComponent = styled(Box)({
  width: "340px",
  height: "120px",
  padding: "10px 50px",
  borderRadius: "5px",
  backgroundColor: "#ffffff",
  outline: "none",
  margin: "20px",
  display: "flex",
  flexDirection: "column",
  boxShadow: "0px 0px 3px px transparent"
});

const ButtonBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between"
});

const CanButton = styled("button")({
  width: "145px",
  height: "40px",
  border: "1px solid #206575",
  color: "#206575",
  backgroundColor: "white",
  fontSize: "16px"
});

const DeleteTitleText = styled(Box)({
  width: "100%",
  height: "37px",
  fontWeight: 600,
  fontSize: "16px"
});
// Customizable Area End
