import React from "react";

// Customizable Area Start
import { Box, FormControl, Button } from "@material-ui/core";

import MainLayout from "../../../components/src/MainLayout.web";
import ReferralController, { Props } from "./ReferralController";
import { webStyles } from "./AddCouponcodegenerator.web";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import {
  InputBox,
  OneColBox,
  TwoColBox
} from "../../../components/src/GenericInputs";
import { CheckBtn } from "../../educational-user-profile/src/EducationalUserProfile.web";

// Customizable Area End

export default class AddFlatDiscount extends ReferralController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.closemodal = this.closemodal.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  renderReferralForm() {
    const formData = this.state.formData;
    return (
      <div style={webStyles.bundleCourseMainLayout}>
        <Box sx={webStyles.adminLayout}>
          <TwoColBox>
            <Box className="child__box">
              <Box className="box__label">Referral Limit*</Box>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  data-test-id="referralLimitTestId"
                  id="outlined-basic"
                  variant="outlined"
                  name="referral_limit"
                  value={formData.referral_limit}
                  onChange={e => {
                    this.handleInputChange(e);
                  }}
                  fullWidth
                />
              </FormControl>
            </Box>
            <Box className="child__box">
              <Box className="box__label">Referral validity Day*</Box>
              <FormControl variant="outlined" fullWidth className="box__input">
                <InputBox
                  data-test-id="referralValidityDayTestId"
                  id="outlined-basic"
                  variant="outlined"
                  value={formData.referral_validity_day}
                  name="referral_validity_day"
                  onChange={e => {
                    this.handleInputChange(e);
                  }}
                  fullWidth
                />
              </FormControl>
            </Box>
          </TwoColBox>
          <TwoColBox>
            <Box className="child__box">
              <Box className="box__label">You get Rs.*</Box>
              <FormControl
                variant="outlined"
                fullWidth
                className="box__input rs__inputs"
              >
                <InputBox
                  data-test-id="referrerAmountTestID"
                  id="outlined-basic"
                  variant="outlined"
                  value={formData.referrer_amount}
                  name="referrer_amount"
                  onChange={e => {
                    this.handleInputChange(e, true);
                  }}
                  fullWidth
                />
              </FormControl>
            </Box>
            <Box className="child__box">
              <Box className="box__label">Your friend gets Rs.*</Box>
              <FormControl
                variant="outlined"
                fullWidth
                className="box__input rs__inputs"
              >
                <InputBox
                  data-test-id="referrerFriendAmountTestId"
                  id="outlined-basic"
                  variant="outlined"
                  value={formData.referrer_friend_amount}
                  name="referrer_friend_amount"
                  onChange={e => {
                    this.handleInputChange(e, true);
                  }}
                  fullWidth
                />
              </FormControl>
            </Box>
          </TwoColBox>
          <OneColBox>
            <Box display="flex" gridGap={15}>
              <Box className="box__label">Status</Box>
              <CheckBtn className="switch">
                <input
                  type="checkbox"
                  data-test-id="toggleStatus"
                  onClick={this.handleStatusClick}
                  checked={this.state.formData.status}
                />
                <span className="slider round" />
              </CheckBtn>
            </Box>
          </OneColBox>
          <div
            style={{
              display: "flex",
              columnGap: "10px",
              marginBottom: "20px"
            }}
          >
            <Button
              data-test-id="updateButtonTestId"
              style={webStyles.createBtn}
              onClick={() => this.updateReferral()}
            >
              Update
            </Button>
          </div>
        </Box>
      </div>
    );
  }

  closemodal() {
    this.oncloseAlert();
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainLayout
        data-test-id="mainLayoutEvent"
        isLoading={this.state.isLoading}
        title={"Referral"}
        isAdd={true}
        isEdit={true}
      >
        <NotificationAlertMessage
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          isOpen={this.state.isAlert}
        />
        <main>{this.renderReferralForm()}</main>
      </MainLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStylesLocal = {};
// Customizable Area End
